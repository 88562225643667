import { Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/primeng';
import { OrderDetailComponent } from 'src/core/components/order/order-detail/order-detail.component';
import { StorageKey } from 'src/core/shared/enums/storage-key.enum';
import { IApiService } from '../api/api.service.interface';
import { IModalService } from '../modal/modal.service.interface';
import { IStorageService } from '../storage/storage.service.interface';
import { IOrderService } from './order.service.interface';

@Injectable()
export class OrderService implements IOrderService {

    constructor(private apiService: IApiService,
        private modalService: IModalService,
        private storageService: IStorageService) { }

    /**
     * @inheritdoc
     */
    async get(): Promise<any> {
        const companyId: string = await this.storageService.get(StorageKey.CompanyId);

        return await this.apiService.get<any>(`order/get/${companyId}`);
    }

    /**
     * @inheritdoc
     */
    async getByTid(companyId: string, transactionId: string): Promise<any> {
        return await this.apiService.get<any>(
            `order/getByTid?companyId=${companyId}&transactionId=${transactionId}`);
    }

    /**
     * @inheritdoc
     */
    open(order: any): DynamicDialogRef {
        return this.modalService.dialog(OrderDetailComponent,
            {
                data: { order: order },
                styleClass: 'order-modal',
                closable: true,
                contentStyle: {
                    maxHeight: '90vh',
                    maxWidth: '90vw',
                    overflow: 'scroll'
                },
                dismissableMask: true
            });
    }
}
