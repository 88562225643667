import { StorageKey } from "@foodra/core";

export abstract class IStorageService {
    /**
     * Gets the data persisted on the storage.
     *
     * @param key The key to query.
     *
     * @returns Promise with the object.
     */
    abstract get(key: StorageKey): Promise<any>;

    /**
     * Sets the storage with the object.
     *
     * @param key The key to store the object.
     * @param value The object to be stored.
     */
    abstract set(key: StorageKey, value: any): Promise<any>;

    /**
     * Clears the storage.
     */
    abstract clear(): Promise<any>;
}