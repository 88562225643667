import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

interface Locale {
    firstDayOfWeek: number;
    dayNames: string[];
    dayNamesShort: string[];
    dayNamesMin: string[];
    monthNames: string[];
    monthNamesShort: string[];
    today: string;
    clear: string;
    dateFormat: string;
    weekHeader: string;
}

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
    /**
     * The date picker data translation.
     */
    locale: Locale;

    /**
     * Receives a date or set a default one.
     */
    @Input()
    date: Date = new Date();

    /**
     * Emits an event when a date is selected.
     */
    @Output()
    onSelected: EventEmitter<Date> = new EventEmitter();

    constructor() {
        this.locale = <Locale> {
            firstDayOfWeek: 0,
            dayNames: ["Domingo", "Segunda-feira", "Terça", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["D","S","T","Q","Q","S","S"],
            monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
            monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
            today: 'Hoje',
            clear: 'Limpar',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Semana'
        };
    }

    /**
     * Gets the selected date from date picker.
     *
     * @param date The selected date.
     */
    onSelect(date: Date) {
        this.onSelected.emit(date);
    }
}
