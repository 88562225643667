import { Injectable } from '@angular/core';
import { IWhatsAppService } from './whatsapp.service.interface';

@Injectable()
export class WhatsappService implements IWhatsAppService {

    constructor() { }

    /**
     * @inheritdoc
     */
    send(message: string, phone?: string): void {
        const params = phone
            ? `phone=${phone}&text=${encodeURIComponent(message)}`
            : `text=${encodeURIComponent(message)}`;

        window.open(`https://api.whatsapp.com/send?${params}`, "_blank");
    }
}
