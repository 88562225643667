import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule, FileUploadModule, SidebarModule, TooltipModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ProductComponent } from './product.component';
import { ImageUploaderModule } from 'src/ui-components/image-uploader/image-uploader.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        FileUploadModule,
        NgbModule,
        CurrencyMaskModule,
        SidebarModule,
        ImageUploaderModule,
        TooltipModule
    ],
    exports: [ProductComponent],
    declarations: [ProductComponent],
    providers: [],
})
export class ProductModule { }
