import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { PayableReport } from 'src/core/shared/models/reports/payable/payable-report';
import { IPayableService } from './service/payable.service.interface';

@Component({
    selector: 'app-payable',
    templateUrl: './payable.component.html',
    styleUrls: ['./payable.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PayableComponent implements OnInit {
    cols: {
        field: string;
        header: string;
    }[];

    subcols: {
        field: string;
        header: string;
    }[];

    payables: PayableReport[];
    showLoader: boolean = true;

    months: string[] = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];

    status: string[] = [
        'Pendente',
        'Pago'
    ];

    notes = [
        'Os valores abaixo não contemplam o desconto da tarifa de tranferência bancária de R$3,67.',
        'Caso a data prevista de recebimento seja feriado, a transferência acontecerá no próximo dia útil.'
    ];

    dropdownItems: SelectItem[];
    yearSelected: string;

    constructor(private service: IPayableService) {
        this.dropdownItems = this.getYears();
    }

    /**
     * Whether to show the background or not.
     */
    get showBackground(): boolean {
        return (!this.payables || this.payables.length == 0) && !this.showLoader;
    }

    /**
     * @inheritdoc
     */
    ngOnInit() {
        this.yearSelected = new Date().getFullYear().toString();
        this.service.get()
            .then((payables: PayableReport[]) => {
                this.payables = payables;
                this.showLoader = false;
            });

        this.cols = [
            { field: 'PaymentDate', header: 'Prev. Recebimento' },
            { field: 'Description', header: 'Descrição' },
            { field: 'Total', header: 'Total Líquido' },
            { field: 'Status', header: 'Status' },
        ];

        this.subcols = [
            { field: 'Tid', header: 'Pedido' },
            { field: 'TransactionDate', header: 'Dt. do Pedido' },
            { field: 'GrossAmount', header: 'Vl. Bruto' },
            { field: 'NetAmount', header: 'Vl. Líquido' }
        ];
    }

    /**
     * Toggle the payable panel.
     *
     * @param payable The payable to be toggled.
     * @param collapsed Whether panel is collapsed or not.
     */
    toggle(payable: PayableReport, collapsed: boolean): void {
        payable.collapsed = collapsed;
    }

    /**
     * Opens an order based on a transaction id.
     *
     * @param transactionId The order transaction id.
     */
    open(transactionId: string): void {
        this.service.open(transactionId);
    }

    /**
     * Event to trigger when an year is selected.
     *
     * @param year The year to query.
     */
    onYearSelected(year: number): void {
        this.yearSelected = year.toString()
        this.showLoader = true;
        this.service.get(null, year)
            .then((payables: PayableReport[]) => {
                this.payables = payables;
                this.showLoader = false;
            });
    }

    private getYears(): SelectItem[] {
        let startYear = 2020;
        const currentYear = new Date();
        const items: SelectItem[] = [];

        while (startYear <= currentYear.getFullYear()) {
            const year = startYear.toString();
            items.push({ label: year, value: year})
            startYear = startYear + 1;
        }

        return items;
    }
}
