<div class="address-search">
    <input
        type="text"
        class="form-control"
        placeholder="Endereço, CEP ou local"
        [(ngModel)]="autocomplete.input"
        (keyup)="updateSearchResults()">

    <div class="address-results"
        [hidden]="autocompleteItems.length == 0">
        <div
            *ngFor="let item of autocompleteItems">
            <div class="col address-results-item" tabindex="0" role="button" (click)="selectSearchResult(item)">
                <label>
                    {{ item.description }}
                </label>
            </div>
            <hr />
        </div>
    </div>
</div>