<div class="date-picker">
    <p-calendar
        [(ngModel)]="date"
        view="month"
        dateFormat="mm/yy"
        [yearNavigator]="true"
        yearRange="2021:2030"
        [locale]="locale"
        showIcon="true"
        readonlyInput="true"
        (onSelect)="onSelect($event)">
    </p-calendar>
</div>