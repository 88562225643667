export class Country {
    /// <summary>
    /// Get or set Country Code.
    /// </summary>
    public Code: string;

    /// <summary>
    /// Get or set Country Name.
    /// </summary>
    public Name: string;
}