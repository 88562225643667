<div class="card">
    <div
        class="card-header text-center cursor-pointer"
        [class.border-primary]="color === 'primary'"
        [class.border-success]="color === 'success'"
        [class.border-warning]="color === 'warning'"
        [class.border-info]="color === 'info'"
        (click)="collapse = !collapse">
        <div class="row">
            <div class="col">
                {{ title }}
                <i *ngIf="collapse" class="fa fa-plus-square-o " pTooltip="Abrir" tooltipPosition="top"></i>
                <i *ngIf="!collapse" class="fa fa-minus-square-o " pTooltip="Fechar" tooltipPosition="top"></i>
            </div>
            <div
                *ngIf="status == statusEnum.Completed"
                class="col-2">
                <i
                    class="fa fa-trash text-danger cursor-pointer"
                    pTooltip="Limpar pedidos concluídos"
                    tooltipPosition="top"
                    (click)="cleanFinishedOrders()">
                </i>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="!collapse">
        <blockquote class="card-blockquote">
            <div *ngFor="let order of orders | filterStatus: status; let i = index">
                <div
                    class="row order-cell mb-1 cursor-pointer"
                    [class.order-new]="status == statusEnum.Pending"
                    [class.order-confirmed]="status == statusEnum.Preparing"
                    [class.order-deliver]="status == statusEnum.Delivering"
                    [class.order-completed]="status == statusEnum.Completed"
                    [class.order-paid-border]="order?.Transaction?.Status == 'Paid'"
                    [class.order-not-paid-border]="!order?.Transaction?.Status"
                    [class.text-danger]="isDelayed(order)"
                    [ngClass]="{ 'animate__animated animate__headShake animate__infinite infinite': isDelayed(order)}"
                    pTooltip="Clique para ver o pedido"
                    tooltipPosition="top"
                    (click)="showOrder(order)">
                    <div
                        class="col-9">
                        {{ order?.Customer?.Name }}
                    </div>
                    <div class="col-3 text-right"
                        [class.text-success]="status == statusEnum.Preparing ||
                                            (status == statusEnum.Pending
                                            && order?.Payment?.Online
                                            && order?.Transaction?.Status == 'Paid')"
                        [class.text-warning]="status == statusEnum.Delivering ||
                                            (status == statusEnum.Pending && !order?.Payment?.Online)"
                        [class.text-info]="status == statusEnum.Completed">
                        <i tooltipPosition="right"
                            [pTooltip]="getTooltipDescription(order)"
                            class="fa fa-lg"
                            [class.fa-check-circle]="status == statusEnum.Pending
                                                        && order?.Payment?.Online
                                                        && order?.Transaction?.Status == 'Paid'"
                            [class.fa-clock-o]="status == statusEnum.Pending && !order?.Payment?.Online"
                            [class.fa-check-square-o]="status == statusEnum.Preparing"
                            [class.fa-motorcycle]="status == statusEnum.Delivering"
                            [class.fa-flag-checkered]="status == statusEnum.Completed">
                        </i>
                    </div>
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-6">
                                <small class="text-muted">{{ order?.DeliveryPlace?.Local | uppercase }} {{ order?.DeliveryPlace?.Locator }}</small>
                            </div>
                            <div class="col-6 text-right">
                                <small class="text-muted">{{ order?.OrderDate | timeago }}</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <small class="text-muted">#{{ order?.Transaction?.Tid }}</small>
                            </div>
                            <div
                                *ngIf="isDelayed(order)"
                                class="col-4 text-right">
                                <i
                                    class="fa fa-exclamation-triangle animate__animated animate__flash animate__infinite"
                                    pTooltip="Atrasado"
                                    tooltipPosition="top">
                                </i>
                            </div>
                        </div>
                        <div
                            *ngIf="status != statusEnum.Completed"
                            class="row mt-1">
                            <div class="col-10">
                                <button
                                    ngbDropdown
                                    id="dropdownConfig"
                                    type="button"
                                    [class]="orderAction.cssClass"
                                    (click)="changeStatus($event, order)">
                                    {{ orderAction.Name }}
                                </button>
                            </div>
                            <div class="col-2 pl-0">
                                <div
                                    class="btn-group"
                                    ngbDropdown
                                    container="body"
                                    role="group">
                                    <button
                                        class="btn btn-sm btn-outline-secondary dropdown-toggle-split"
                                        ngbDropdownToggle
                                        (click)="$event.stopPropagation()">
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                                        <button
                                            class="dropdown-item"
                                            (click)="showOrder(order)">
                                            <i class="fa fa-eye"></i> Ver pedido
                                        </button>
                                        <button
                                            class="dropdown-item"
                                            (click)="print(order)">
                                            <i class="fa fa-print"></i>
                                            Imprimir
                                        </button>
                                        <button
                                            *ngIf="status == statusEnum.Pending"
                                            class="dropdown-item"
                                            (click)="changeStatus($event, order, true)">
                                            <i class="fa fa-ban text-danger"></i>
                                            Recusar
                                        </button>
                                        <button
                                            class="dropdown-item"
                                            (click)="changeStatus($event, order, false, true)">
                                            <i class="fa fa-ban"></i>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </blockquote>
    </div>
</div>