import { NgModule } from '@angular/core';
import { OrderHistoryComponent } from './order-history.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TableModule } from 'primeng/table';
import { MultiSelectModule, DialogModule } from 'primeng/primeng';

@NgModule({
    imports: [BrowserModule, CommonModule, TableModule, MultiSelectModule, DialogModule],
    exports: [OrderHistoryComponent],
    declarations: [OrderHistoryComponent],
    providers: [],
})
export class OrderHistoryModule { }
