import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IModalService, ModalType } from '@foodra/core';
import { Account } from 'src/models/account/account.model';
import { Company } from 'src/models/company/company.model';
import { CompanyForm } from 'src/models/forms/company-form.model';
import { StoreService } from 'src/providers/store.service';
import {
    SupportTable,
    SupportTablesService,
} from 'src/providers/support-tables.service';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CompaniesComponent implements OnInit {
    public companies: Company[] = [];
    public company: Company = new Company();

    public form = new FormGroup({});
    public columns: any;
    public tabsConfig: any;

    display: boolean = false;
    displayAddCompany: boolean = false;
    errorMessage: string;
    accountList: SupportTable[];
    selectedAccountId: string = '';

    constructor(
        private service: StoreService,
        private supportTablesService: SupportTablesService,
        private clipboard: Clipboard,
        private modalService: IModalService
    ) {
        this.tabsConfig = new CompanyForm(
            supportTablesService,
            this.clipboard,
            modalService
        ).model;

        this.columns = [
            { field: 'TradingName', header: 'Nome' },
            { field: 'IsOpen', header: 'Aberto' },
            { field: 'Status', header: 'Status' },
            { field: 'Visibility', header: 'Visibilidade' },
        ];
    }

    ngOnInit(): void {
        this.getCompanies();
        this.getAccountList();
    }

    async getAccountList() {
        this.accountList = await this.supportTablesService
            .get<Account>(
                'Account/Get',
                'Email',
                'Id',
                (_) => _.Role === 'Company'
            )
            .toPromise();
    }

    open(company: Company) {
        this.display = true;
        this.company = JSON.parse(JSON.stringify(company));
    }

    onSubmitted(company: Company) {
        this.errorMessage = '';

        this.service
            .update(company)
            .then((_: Company) => {
                this.display = false;
                this.getCompanies();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onCancelled(isCancelled: boolean) {
        this.display = !isCancelled;
    }

    getCustomValues(field: string, value: any) {
        if (!field && !value) {
            return value;
        }

        if (field === 'Status') {
            return value ? 'Ativo' : 'Inativo';
        }

        if (field === 'Visibility') {
            return value == 1 ? 'Público' : 'Privado';
        }

        if (field === 'IsOpen') {
            return value ? 'Aberto' : 'Fechado';
        }

        if (field && value) {
            return value;
        }
    }

    changeCompany(company: Company) {
        event.stopPropagation();
        this.service.change(company);
    }

    add() {
        this.displayAddCompany = true;
    }

    confirmNewCompany() {
        const message =
            'Ao criar uma empresa, ela não poderá ser apagada. Tem certeza que deseja criar?';
        this.modalService.confirm(
            message,
            'Nova Empresa',
            this.create.bind(this)
        );
    }

    private async create() {
        const validationResult = await this.service.add(this.selectedAccountId);

        if (validationResult.Success) {
            this.displayAddCompany = false;
            this.companies = null;
            this.getCompanies();
            this.modalService.toast('Empresa criada com sucesso!');
            return;
        }

        this.modalService.toast(
            'Ocorreu um erro ao adicionar a empresa.',
            'Ops!',
            ModalType.Error
        );
    }

    /**
     * Forces all connected companies to check for new orders.
     */
    forceCheckNewOrders(): void {
        const acceptFunction = () => {
            const success: boolean = this.service.forceCheckNewOrders();
            const title: string = success ? 'Sucesso!' : 'Ops!';
            const message = success
                ? 'Realizado com sucesso!'
                : 'Você parece não estar conectado.';

            this.modalService.toast(message, title);
        };

        this.modalService.confirm(
            `Será realizada uma verificação de novos pedidos para todas empresas conectadas.
            Confirma a checagem?`,
            '',
            acceptFunction.bind(this)
        );
    }

    private getCompanies() {
        this.service.get().then((_: Company[]) => {
            this.companies = _;
        });
    }
}
