<div class="order-detail">
    <!-- Header -->
    <div>
        <div class="row">
            <div class="col text-center">
                <b>Pedido #{{ order?.Transaction?.Tid}}</b>
                <div class="text-center">
                    <small class="text-muted">
                        {{ order?.OrderDate | date: 'dd/MM/yy HH:mm:ss' }}
                    </small>
                </div>
            </div>
            <div class="col text-center no-wrap">
                <b>{{ order?.Customer?.Name }}</b>
                <div
                    class="col text-center"
                    *ngIf="order?.Customer?.Phone">
                    <a href="tel:{{order?.Customer?.Phone}}">
                        <i class="fa fa-phone"></i>
                        {{ order?.Customer?.Phone | telefone }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <!-- Status -->
    <div>
        <div class="row justify-content-md-center no-wrap">
            <div class="col">
                <div class="row">
                    <div
                        class="col text-center"
                        *ngIf="order?.Status?.length > 1 && order?.Transaction?.Status == 'Paid'">
                        <span class="text-success">
                            <i
                                class="fa fa-check-circle fa-2x"
                                tooltipPosition="bottom"
                                [pTooltip]="order?.OrderDate | date: 'dd/MM/yy HH:mm:ss'">
                            </i>
                            <div>
                                <small>Pago</small>
                            </div>
                        </span>
                    </div>
                    <div
                        class="col text-center"
                        *ngIf="order?.Status?.length > 1 && !order?.Transaction?.Status">
                        <span class="text-warning">
                            <i
                                class="fa fa-clock-o fa-2x"
                                tooltipPosition="bottom"
                                [pTooltip]="order?.OrderDate | date: 'dd/MM/yy HH:mm:ss'">
                            </i>
                            <div>
                                <small>Aguardando Pagamento</small>
                            </div>
                        </span>
                    </div>
                    <div
                        class="col text-center"
                        *ngFor="let status of order?.Status | slice: 1">
                        <span
                            class=""
                            [class.text-success]="status?.Code == 2"
                            [class.text-warning]="status?.Code == 3"
                            [class.text-info]="status?.Code == 4"
                            [class.text-danger]="status?.Code == 9 || status?.Code == 10">
                            <i
                                class="fa fa-2x"
                                [class.fa-check-square-o]="status?.Code == 2"
                                [class.fa-bicycle]="status?.Code == 3"
                                [class.fa-thumbs-up]="status?.Code == 4"
                                [class.fa-exclamation-circle]="status?.Code == 9 || status?.Code == 10"
                                tooltipPosition="bottom"
                                [pTooltip]="status?.Date | date: 'dd/MM/yy HH:mm:ss'">
                            </i>
                            <div>
                                <small>{{ status?.Description }}</small>
                            </div>
                        </span>
                    </div>
                </div>

                <div
                    class="row"
                    *ngIf="order?.Status?.length <= 1 || !order?.Status">
                    <div
                        class="col text-center"
                        *ngIf="order?.Transaction?.Status == 'Paid'">
                        <span class="text-success">
                            <i class="fa fa-check-circle fa-2x"></i>
                            <div>
                                <small>Pago</small>
                            </div>
                        </span>
                    </div>
                    <div
                        class="col text-center"
                        *ngIf="!order?.Transaction?.Status">
                        <span class="text-warning">
                            <i class="fa fa-clock-o fa-2x"></i>
                            <div>
                                <small>Aguardando Pagamento</small>
                            </div>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span class="no-status">
                            <i class="fa fa-check-square-o fa-2x"></i>
                            <div>
                                <small>Confirmado</small>
                            </div>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span class="no-status">
                            <i class="fa fa-bicycle fa-2x"></i>
                            <div><small>Saiu para Entrega</small></div>
                        </span>
                    </div>
                    <div class="col text-center">
                        <span class="no-status">
                            <i class="fa fa-home fa-2x"></i>
                            <div><small>Entregue</small></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <!-- Delivery Place -->
    <div class="row text-center">
        <div class="col">
            <div class="row no-wrap">
                <div class="col">
                    <h5>Forma de Pagamento</h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>
                        <span
                            class="badge"
                            [class.badge-success]="order?.Payment?.Online"
                            [class.badge-warning]="!order?.Payment?.Online">
                            {{ order?.Payment?.Online ? 'Pelo app' : 'Na entrega' }}
                        </span>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-5 text-right pl-0">
                    <i class="fa fa-2x {{getPaymentTypeIcon()}}"></i>
                </div>
                <div class="col text-left">
                    <span>{{ order?.Payment?.Name }}</span>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row text-center">
        <div class="col">
            <h4>{{ order?.DeliveryPlace?.Local | uppercase }} {{ order?.DeliveryPlace?.Locator }}</h4>
        </div>
    </div>
    <div
        class="row text-center"
        *ngIf="order?.DeliveryPlace?.Location">
        <div
            class="col-lg-8 col-sm-12 mt-2"
            *ngIf="order?.DeliveryPlace?.Location?.Address">
            <div class="row">
                <div class="col">
                    <h5>Endereço de entrega</h5>
                    <div class="row">
                        <div class="col p-0">
                            <div>
                                <a
                                    [href]="mapsUrl"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {{ address }}
                                    <i class="fa fa-external-link-square"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-2">
                            <button
                                class="btn btn-sm btn-warning"
                                tooltipPosition="top"
                                [pTooltip]="'Compartilhar localização'"
                                (click)="shareAddres()">
                                <i class="fa fa-share-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg col-sm-12 mt-2">
            <div class="row no-wrap">
                <div class="col">
                    <h5>Distância</h5>
                    <i class="fa fa-location-arrow"></i>
                    <span class="pl-2">
                        {{ order?.DeliveryPlace?.Location?.Distance }} • {{
                        order?.DeliveryPlace?.Location?.DistanceTime }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <hr />

    <!-- Order -->
    <div>
        <div class="text-center">
            <h5>Produtos</h5>
        </div>
        <div
            class="row"
            *ngFor="let product of order?.Products">
            <div
                class="col-2"
                *ngIf="product?.ImageId">
                <img
                    [src]="product?.Image?.ImageOriginal"
                    class="image-thumbnail"
                    *ngIf="product?.ImageId" />
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <span class="badge badge-secondary">{{ product?.Quantity }}x</span> {{ product?.Name }}
                    </div>
                    <div
                        class="col-3 text-center"
                        *ngIf="product.Price > 0">
                        {{ product?.Price | currency:'BRL' }}
                    </div>
                    <div class="col-12">
                        <div
                            class="row"
                            *ngFor="let subcategory of product.Subcategories">
                            <div class="col-12">
                                <div
                                    class="row item-line"
                                    *ngFor="let item of subcategory.Items">
                                    <div class="col">
                                        <small class="text-muted">{{ item.Quantity }}x {{ item.Name }}</small>
                                    </div>
                                    <div
                                        class="col-2"
                                        *ngIf="item.Price > 0">
                                        <small class="text-muted text-success no-wrap">
                                            + {{ item.Price | currency:'BRL' }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Product Note -->
                    <div
                        class="col-12"
                        *ngIf="product.Note">
                        <div class="row">
                            <div class="col-1"><small class="text-muted"><strong>OBS.: </strong></small></div>
                            <div class="col-11">
                                <small class="text-muted">{{ product.Note | uppercase }}</small>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-12"
                        *ngIf="shouldShowProductSubtotal(product)">
                        <div class="row">
                            <div class="col-3 offset-9 text-center">
                                <small class="text-muted text-success">
                                    <b>{{ product.Subtotal | currency:'BRL' }}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
    </div>

    <!-- Total Order -->
    <div>
        <div class="row">
            <div class="col-8 text-right">
                <label
                    for="Subtotal"
                    class="m-0">
                    Subtotal:
                </label>
            </div>
            <div class="col-4 text-right">
                <span class="price-discount">{{ subtotal | currency:'BRL' }}</span>
            </div>
        </div>
        <div *ngIf="order?.Coupons && order?.Coupons.length > 0">
            <div class="row">
                <div class="col-8 text-right">
                    <label
                        for="Promoção"
                        class="m-0">
                        Promoção:
                    </label>
                </div>
                <div class="col-4 text-right">
                    <div *ngFor="let coupon of order?.Coupons">
                        <i class="fa fa-tag"></i>
                        <span> -{{ coupon.DiscountAmount | currency:'BRL' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="order?.DeliveryPlace?.Price > 0"
            class="row">
            <div class="col-8 text-right">
                <label
                    for="Total"
                    class="m-0">
                    Entrega:
                </label>
            </div>
            <div class="col-4 text-right no-wrap">
                <span>+ {{ order?.DeliveryPlace?.Price | currency:'BRL' }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-8 text-right">
                <label
                    for="Total"
                    class="m-0">
                    Total do Pedido:
                </label>
            </div>
            <div class="col-4 text-right">
                <span class="text-success"><b>{{ order?.TotalPrice | currency:'BRL' }}</b></span>
            </div>
        </div>
    </div>
</div>