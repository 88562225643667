import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IStorageService, StorageKey } from '@foodra/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RefreshTokenService } from 'src/services/refresh-token.service';
import { ServiceConfig } from 'src/_config/services.config';

@Injectable()
export class AuthService {
    // Service URL.
    private readonly url: string = ServiceConfig.LOGIN_ENDPOINT;

    // Flag to check if user is Logged in.
    public isLoggedIn: boolean;

    /**
     * Constructor method of the service.
     * @param http
     * @param router
     */
    constructor(private http: HttpClient,
        private router: Router,
        private refreshTokenService: RefreshTokenService,
        private storageService: IStorageService) { }

    /**
     * Logs the user in.
     *
     * @param username
     * @param password
     * @return The user's data
     */
    login(username: string, password: string, token: string): Observable<any> {
        // Token params.
        const params: any = {
            username: username,
            password: password,
            grant_type: 'password',
            login: 'admin',
            token: token,
            device_id: localStorage.getItem('device_id')
        };

        // Encodes the parameters.
        const body: string = this.encodeParams(params);

        return this.http.post(this.url, body)
        .pipe(
            map(res => {
                const response: any = res;

                // Sign in successful if there's an access token in the response.
                if (typeof response.access_token !== 'undefined') {
                    // Stores access token & refresh token.
                    this.store(response);
                    this.isLoggedIn = true;
                    this.router.navigate(['/']);
                }
            }, (error: any) => {
                // Error on post request.
                return observableThrowError(error);
            }),
        catchError(res => {
            return observableThrowError(res);
        }));
    }

    /**
     * Updates the access token through refresh token.
     */
    public refreshToken(): Observable<any> {
        let params: any;
        let body = '';
        params = this.getAuthRefreshParams(null);
        body = this.encodeParams(params);

        return this.http.post(this.url, body)
        .pipe(
            map((response: any) => {
                // Sign in successful if there's an access token in the response.
                if (typeof response.access_token !== 'undefined') {
                        const login = this.getAuthResponse(response);

                        // Keeps the old refresh token.
                        login.RefreshToken = params.refresh_token;

                        // Publish the new access token to http interceptor.
                        this.refreshTokenService.publish(login.AccessToken);

                        this.store(response);

                        // Keeps the old refresh token.
                        localStorage.setItem('refresh_token', params.refresh_token)
                        this.storageService.set(StorageKey.AuthRefreshToken, response.refresh_token);
                        return login;
                }
            }, error => {
                console.log(error);
            })
        )
    }

    recoverPassword(email: any) {
        const url = ServiceConfig.API_ENDPOINT;

        return this.http.post(`${url}/Account/ForgetAdminPassword?email=${email}`, {}).toPromise();
    }

    /**
     * Logs the user out.
     */
    logout() {
        // Remove Token
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('client_id');
        localStorage.removeItem('expires_in');

        this.storageService.clear();

        this.isLoggedIn = false;
        // Redirects to Login Page.
        this.router.navigate(['/login']);
    }

    /**
     * Stores access token & refresh token.
     *
     * @param response The response of the request to the token endpoint
     */
    private store(response: any): void {
        // Stores access token in local storage to keep user signed in.
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        localStorage.setItem('client_id', response.client_id);
        localStorage.setItem('expires', response['.expires']);

        this.storageService.set(StorageKey.AuthToken, response.access_token);
        this.storageService.set(StorageKey.AuthRefreshToken, response.refresh_token);
        this.storageService.set(StorageKey.AccountId, response.client_id);
        this.storageService.set(StorageKey.AuthExpires, response['.expires']);
    }

    /**
     * Encodes the parameters.
     *
     * @param params The parameters to be encoded
     * @return The encoded parameters
     */
    private encodeParams(params: any): string {
        let body = '';
        for (const key in params) {
            if (body.length) {
                body += '&';
            }
            body += key + '=';
            body += encodeURIComponent(params[key]);
        }

        return body;
    }

    private getAuthRefreshParams(token: string) {
        return {
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refresh_token'),
            client_id: localStorage.getItem('client_id'),
            token: token
        };
    }

    private getAuthResponse(response: any): any {
        return {
            AccessToken: response.access_token,
            RefreshToken: response.refresh_token,
            ExpirationDate: response['.expires'],
            LoggedIn: true,
            LoginId: response.client_id,
        };
    }
}
