import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/primeng';

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

    @Input()
    items: SelectItem[];

    @Input()
    selected: string;

    @Input()
    placeholder: string = 'Selecione';

    @Output()
    onSelected: EventEmitter<string> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onChange(selected) {
        console.log(this.selected);
        this.onSelected.emit(selected);
    }
}
