<form #productForm="ngForm" (ngSubmit)="productForm.value">
    <div class="form-group row tab-content-size">
        <!-- Product Image -->
        <div class="col-lg-2 col-md-2 col-sm-12 padd img">
            <img
                [src]="product?.Image?.ImageOriginal"
                class="imgCircle"
                (click)="openImageUploader()"
                tooltipPosition="bottom"
                pTooltip="Alterar"
                *ngIf="product?.Image?.ImageOriginal" />
            <img
                [src]="product?.Image?.Url"
                class="imgCircle"
                (click)="openImageUploader()"
                tooltipPosition="bottom"
                pTooltip="Alterar"
                *ngIf="product?.Image?.Url" />
            <img
                [src]="imgThumbUrl"
                class="imgCircle"
                (click)="openImageUploader()"
                tooltipPosition="bottom"
                pTooltip="Alterar"
                *ngIf="!product.Image" />

            <div class="row p-2">
                <div class="col">
                    <button
                        class="btn btn-sm btn-danger"
                        type="button"
                        tooltipPosition="bottom"
                        pTooltip="Remover Imagem"
                        (click)="removeImage()">
                            <i class="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-10">
            <div class="row">
                <div class="col-md-8 col-sm-12 padd">
                    <div class="form-group"
                        [ngClass]="{ 'has-success': productForm.controls.Name?.valid, 'has-danger': !productForm.controls.Name?.valid }">
                        <input type="text" class="form-control" placeholder="Nome do produto" [(ngModel)]="product.Name" name="Name" maxlength="30" ngModel required />
                        <div [hidden]="productForm.controls.Name?.valid" class="form-control-feedback">Por favor, digite um nome.</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 padd">
                    <div class="form-group">
                        <!--[ngClass]="{ 'has-success': productForm.controls.Price?.valid, 'has-danger': !productForm.controls.Price?.valid }">-->
                        <input type="text" class="form-control" placeholder="Valor" [(ngModel)]="product.Price" name="Price" maxlength="10" ngModel
                               currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                        <!--<div [hidden]="productForm.controls.Price?.valid" class="form-control-feedback">Por favor, digite um valor.</div>-->
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 padd">
                    <textarea rows="3" class="form-control" placeholder="Descrição do produto" [(ngModel)]="product.Description" name="Description" maxlength="280" ngModel></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-primary pull-right" type="button" (click)="change()" [disabled]="!productForm.valid">
                        Próximo 
                        <i class="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

<image-uploader
    [showSidebar]="showSidebar"
    [outputFormat]="'jpeg'"
    [resizeToWidth]="600"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    (onImageCropped)="croppedImage($event)"
    (onShowSidebarChange)="onShowSidebarChange($event)">
</image-uploader>