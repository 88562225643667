import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceConfig } from 'src/_config/services.config';

@Injectable()
export class ApiService {
    private url: string = ServiceConfig.API_ENDPOINT;

    constructor(private httpClient: HttpClient) { }

    get<T>(endpoint: string, parameters: any = {}, headers?: any): Promise<T> {
        const url = this.formatUrl(endpoint);
        if (url == null) {
            return Promise.reject<T>('Error formating the url.');
        }

        return this.getResponse<T>(this.httpClient.get(url, { headers: headers }).toPromise());
    }

    post<T>(endpoint: string, body: any, headers?: any): Promise<T> {
        const url = this.formatUrl(endpoint);
        if (url == null) {
            return Promise.reject<T>('Error formating the url.');
        }

        return this.getResponse<T>(this.httpClient.post(url, body, { headers: headers }).toPromise());
    }

    put<T>(endpoint: string, body: any, headers?: any): Promise<T> {
        const url = this.formatUrl(endpoint);
        if (url == null) {
            return Promise.reject<T>('Error formating the url.');
        }

        return this.getResponse<T>(this.httpClient.put(url, body, { headers: headers }).toPromise());
    }

    delete<T>(endpoint: string, parameters: any = {}, headers?: any): Promise<T> {
        const url = this.formatUrl(endpoint);
        if (url == null) {
            return Promise.reject<T>('Error formating the url.');
        }

        return this.getResponse<T>(this.httpClient.delete(url, { headers: headers }).toPromise());
    }

    private formatUrl(endpoint: string) {
        endpoint = endpoint.replace(/\/+$/, '');
        const url = this.url.replace(/^\/*(api\/)?/, '');

        return `${url}/${endpoint}`;
    }

    private async getResponse<T>(request: Promise<any | Object>): Promise<T> {
        return await request
            .then((response: any) => {
                if (response && response.data) {
                    return JSON.parse(response.data);
                }
                return response;
            });
    }
}