import { Component, NgZone } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
/// <reference types="@types/googlemaps" />
declare var google: any;

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent extends FieldType {

  googleAutocomplete: any;// = new google.maps.places.AutocompleteService(); // google.maps.places.AutocompleteService;
  geocoder: any;// = new google.maps.Geocoder; //google.maps.Geocoder;

  public autocomplete: { input: string; } = { input: '' };
  public autocompleteItems: any[] = [];

  constructor(private zone: NgZone) {
    super();
  }

  updateSearchResults() {
    this.googleAutocomplete = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder;

    if (this.autocomplete.input == '' || this.autocomplete.input.length <= 3) {
      this.autocompleteItems = [];
      return;
    }

    this.googleAutocomplete.getPlacePredictions({ input: this.autocomplete.input },
      (predictions, status) => {
        if (predictions) {
          this.autocompleteItems = [];
            this.zone.run(() => {
              predictions.forEach((prediction) => {
                this.autocompleteItems.push(prediction);
              });
            });
        }
    });
  }

  selectSearchResult(item: any) {
    this.autocomplete.input = item.description;
    this.autocompleteItems = [];

    this.geocoder.geocode({'placeId': item.place_id}, (results, status) => {

      if(status === 'OK' && results[0]) {
        results[0].address_components.forEach(component => {
          if (component.types.find(type => type == 'postal_code') != null) {
            this.model.Local.Address.ZipCode = parseInt(component.long_name.replace('-',''));
          }
          if (component.types.find(type => type == 'route') != null) {
            this.model.Local.Address.Name = component.long_name;
          }
          if (component.types.find(type => type == 'street_number') != null) {
            this.model.Local.Address.Number = component.long_name;
          }
          if (component.types.find(type => type == 'sublocality') != null) {
            this.model.Local.Address.District = component.long_name;
          }
          if (component.types.find(type => type == 'administrative_area_level_2') != null) {
            this.model.Local.City.Name = component.long_name;
          }
          if (component.types.find(type => type == 'administrative_area_level_1') != null) {
            this.model.Local.State.Name = component.short_name;
          }
          if (component.types.find(type => type == 'country') != null) {
            this.model.Local.Country.Name = component.long_name;
          }
        });

        this.model.Local.Geo.Latitude = results[0].geometry.location.lat();
        this.model.Local.Geo.Longitude = results[0].geometry.location.lng();

        this.options.resetModel(this.model);
      }
    });
  }

}
