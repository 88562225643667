import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-geolocation-form',
  templateUrl: './geolocation-form.component.html',
  styleUrls: ['./geolocation-form.component.scss']
})
export class GeolocationFormComponent extends FieldType {
  public zoom = 17;
}
