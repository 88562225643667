<div class="image">
  <div class="image-thumb">
    <img [src]="getImageSource()" (click)="open()">
  </div>

  <div class="image-uploader">
    <image-uploader
      [showSidebar]="showUploader"
      [outputFormat]="'jpeg'"
      [resizeToWidth]="600"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 2"
      (onImageCropped)="croppedImage($event)"
      (onShowSidebarChange)="onShowSidebarChange($event)">
    </image-uploader>
  </div>
</div>