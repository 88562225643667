<div class="geolocation" *ngIf="model.Local?.Geo">
    <agm-map
        [latitude]="model.Local?.Geo?.Latitude"
        [longitude]="model.Local?.Geo?.Longitude"
        [mapDraggable]="false"
        [scrollwheel]="false"
        [streetViewControl]="false"
        [zoom]="zoom"
        [zoomControl]="false">
        <agm-marker
            [latitude]="model.Local?.Geo?.Latitude"
            [longitude]="model.Local?.Geo?.Longitude">
        </agm-marker>
    </agm-map>
</div>