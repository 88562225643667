import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ImageUploaderModule } from '../image-uploader/image-uploader.module';
import { ImagePickerComponent } from './components/company-form/image-picker.component';
import { GeolocationFormComponent } from './components/geolocation-form/geolocation-form.component';
import { DynamicFormsComponent } from './dynamic-forms.component';
import { RepeatTypeComponent } from './repeat-type.section';
import { SearchFormComponent } from './components/address/search-form/search-form.component';
import { AutoCompleteModule } from 'primeng';

const imports = [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAV1XqZ126ZCsNOIr15YGzS9Echwwjj_n0',
      libraries:['places']
    }),
    FormlyModule.forRoot({
        types: [
          { name: 'repeat', component: RepeatTypeComponent },
          { name: 'image-picker', component: ImagePickerComponent },
          { name: 'geolocation', component: GeolocationFormComponent },
          { name: 'address-search', component: SearchFormComponent },
        ],
      }
    ),
    FormlyBootstrapModule,
    NgbTabsetModule,
    NgbModule,
    ImageUploaderModule,
];

const exports = [
    DynamicFormsComponent,
    RepeatTypeComponent,
    ImagePickerComponent,
    GeolocationFormComponent,
    SearchFormComponent
];

@NgModule({
    imports: [imports],
    exports: [exports],
    declarations: [exports]
})
export class DynamicFormsModule { }
