export class City {
    /// <summary>
    /// Get or set City Code.
    /// </summary>
    public Code: string;

    /// <summary>
    /// Get or set City Name.
    /// </summary>
    public Name: string;
}