/**
 * Modal message positioning.
 */
export enum ModalPosition {
    Top = 'top-center',
    TopRight = 'top-right',
    TopLeft = 'top-left',
    Center = 'middle-left',
    CenterRight = 'middle-right',
    CenterLeft = 'middle-left',
    Bottom = 'bottom-center',
    BottomRight = 'bottom-right',
    BottomLeft = 'bottom-left'
}