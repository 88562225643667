import { FormlyFieldConfig } from '@ngx-formly/core';

export class AccountForm {
  constructor() { }

    public model: FormlyFieldConfig[] =
      [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-md-6',
              key: 'Email',
              type: 'input',
              templateOptions: {
                label: 'E-mail',
                required: true,
              },
            },
            {
              className: 'col-md-6',
              key: 'Role',
              type: 'select',
              templateOptions: {
                label: 'Tipo de conta',
                placeholder: 'Selecione',
                required: true,
                options: [
                  { value: 'Admin', label: 'Administrador' },
                  { value: 'Company', label: 'Empresa' }
                ]
              },
            },
          ]
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-md-6',
              key: 'LoginAttempt',
              type: 'input',
              defaultValue: 0,
              templateOptions: {
                type: 'number',
                label: 'Tentativas de login',
                required: true,
              },
            },
            {
              className: 'col-md-6',
              key: 'Active',
              type: 'checkbox',
              defaultValue: false,
              templateOptions: {
                label: 'Ativo',
                required: true
              },
            }
          ]
        },
      ];
}