import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IOrderService } from '@foodra/core';
import { PopoutService } from 'src/services/pop-out/pop-out.service';
import { PopoutData } from 'src/services/pop-out/pop-out.tokens';
import { ServiceConfig } from 'src/_config/services.config';
import { ReceiptComponent } from '../receipt/receipt.component';

@Component({
    selector: 'order-history',
    templateUrl: 'order-history.component.html',
    styleUrls:['order-history.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class OrderHistoryComponent implements OnInit {

    private static readonly dictionary: { [key: string]: string } = {
        'Pending': 'Aguardando confirmação',
        'Preparing': 'Preparando',
        'Delivering': 'Saiu para entrega',
        'Completed': 'Concluído',
        'Rejected': 'Recusado',
        'Cancelled': 'Cancelado'
    }

    orders: any;
    columns: any[];
    status: any[];
    public width: number = 850;
    public imageUrl = ServiceConfig.STATIC_CONTENT_ENDPOINT;
    public enableFilter = false;

    constructor(private orderService: IOrderService,
        private popoutService: PopoutService) { }

    ngOnInit() {
        this.orderService.get().then((_: []) => {
            if (_) {
                _.map(__ => this.translateStatus(__));
            }
            this.orders = _;
        });

        this.columns = [
            { field: 'OrderId', header: 'OrderId' },
            { field: 'OrderDate', header: 'Data' },
            { field: 'Customer', header: 'Nome' },
            { field: 'DeliveryPlace', header: 'Tipo de entrega' },
            { field: 'Status', header: 'Status' },
            { field: 'TotalPrice', header: 'Total' }
        ];

        this.status = [
            { label: 'Aguardando confirmação', value: 1 },
            { label: 'Preparando', value: 2 },
            { label: 'Saiu para entrega', value: 3 },
            { label: 'Concluído', value: 4 },
            { label: 'Recusado', value: 9 },
            { label: 'Cancelado', value: 10 }
        ];
    }

    public showOrder(order) {
        this.orderService.open(order);
    }

    filterStatus(filters, dataTable) {
        let filteredOrders = [];

        if (filters.length > 0) {
            for(let order of dataTable.value) {
                for (const filter of filters) {
                    if (order.Status[order.Status.length-1].Code == filter)
                        filteredOrders.push(order.OrderId);
                }
            }

            if (filteredOrders.length == 0)
                filteredOrders.push(null);

        }
        dataTable.filter(filteredOrders, 'OrderId', 'in');
    }

    private translateStatus(order) {
        if (!order) {
            return;
        }

        const translations = OrderHistoryComponent.dictionary;
        for (const status of order.Status) {
            for (const key in translations) {
                if (translations.hasOwnProperty(key) && status.Description == key) {
                    status.Description = translations[key];
                }
            }
        }
    }

    print(order: any) {
        event.stopPropagation();
        const popoutData = <PopoutData> {
            id: order.OrderId,
            title: 'Receipt',
            param: order
        };

        this.popoutService.open(ReceiptComponent, popoutData, component => component.name === name, true);
    }
}