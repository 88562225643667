import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ImageUploaderComponent implements OnInit {

    @Input() showSidebar;
    @Input() outputFormat;
    @Input() resizeToWidth;
    @Input() maintainAspectRatio;
    @Input() aspectRatio; // Default = 3 / 4
    @Output() onImageCropped = new EventEmitter();;
    @Output() onShowSidebarChange = new EventEmitter();

    croppedImage
    imageChangedEvent: any = '';
    showStartImagePicker: boolean = true;
    imageUploadError: boolean = false;

    constructor() {
        this.outputFormat        = !this.outputFormat ? "jpg" : this.outputFormat;
        this.resizeToWidth       = !this.resizeToWidth ? "600" : this.resizeToWidth;
        this.maintainAspectRatio = !this.maintainAspectRatio ? true : this.maintainAspectRatio;
        this.aspectRatio         = !this.aspectRatio ? "3 / 4" : this.aspectRatio; // Default = 3 / 4
     }

    ngOnInit() { }

    /**
     * Crop the final image.
     *
     * @param img Image Hash
     */
    crop(): void {
        this.showSidebar = false;
        this.onImageCropped.emit(this.croppedImage);
        this.cancelCrop();
    }

    /**
     * Event triggered when file is changed.
     *
     * @param event
     */
    fileChangeEvent(event: any): void {
        if (event.target.files.length > 0){
            this.imageChangedEvent = event;
            this.showStartImagePicker = false;
            this.imageUploadError = false;
        }
    }

    /**
     * Get the cropped image.
     *
     * @param image Image Hash
     */
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded(): void { }

    /**
     * Display error message for non image files.
     */
    loadImageFailed(): void {
        this.imageUploadError = true;
    }

    /**
     * Cancel Image Cropping.
     */
    cancelCrop(): void {
        this.imageChangedEvent = null;
        this.croppedImage      = null;
        this.showSidebar    = false;
        this.showStartImagePicker = true;
        this.imageUploadError  = false
        this.onShowSidebarChange.emit(this.showSidebar);
    }
}