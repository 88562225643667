import { Subcategory } from "./subcategory.model";
import { Image } from "./image.model";

export class Product {

    public ProductId: string;

    public Name: string;

    public Description: string;

    public Price: number;    

    public IsActive: boolean;

    public Position: number;

    // Get or set the Image Id.
    public ImageId: string;

    public Image: Image;

    public Subcategories: Array<Subcategory>;
}