import { FormlyFieldConfig } from '@ngx-formly/core';
import { SupportTablesService } from 'src/providers/support-tables.service';
import { TabType } from 'src/ui-components/dynamic-forms/dynamic-forms.component';
import { Account } from '../account/account.model';
import { City } from '../local/city.model';
import { Country } from '../local/country.model';
import { State } from '../local/state.model';
import { LocalType } from '../support/local-type.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { IModalService } from '@foodra/core';

export class CompanyForm {
    paymentTypes: any[];

    constructor(
        private supportService: SupportTablesService,
        private clipboard: Clipboard,
        private modalService: IModalService
    ) {}

    public model: TabType[] = [
        {
            label: 'Empresa',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col',
                            key: 'Image',
                            type: 'image-picker',
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'AccountId',
                            type: 'select',
                            templateOptions: {
                                label: 'Conta/E-mail:',
                                required: true,
                                placeholder: 'Selecione a conta/e-mail',
                                options: this.supportService.get<Account>(
                                    'Account/Get',
                                    'Email',
                                    'Id',
                                    (_) => _.Role === 'Company'
                                ),
                                change: (field, $event) => {
                                    const accountId =
                                        field.form.controls.AccountId.value;

                                    field.templateOptions.options.forEach(
                                        (_) => {
                                            const option = _.find(
                                                (__) => __.value == accountId
                                            );

                                            if (option) {
                                                field.form
                                                    .get('Email')
                                                    .setValue(option.label);
                                                this.copyToClipboard(
                                                    option.label
                                                );
                                            }
                                        }
                                    );
                                },
                            },
                        },
                        {
                            className: 'col-md-6 d-none',
                            key: 'Email',
                            type: 'input',
                            templateOptions: {
                                label: 'E-mail:',
                                required: true,
                                placeholder: 'E-mail',
                                disabled: true,
                                hidden: true,
                            },
                        },
                        {
                            className: 'col-md-6',
                            key: 'Cnpj',
                            type: 'input',
                            templateOptions: {
                                type: 'number',
                                label: 'CNPJ',
                                required: true,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'TradingName',
                            type: 'input',
                            templateOptions: {
                                label: 'Nome fantasia',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-6',
                            key: 'CompanyName',
                            type: 'input',
                            templateOptions: {
                                label: 'Razão Social',
                                required: true,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'Alias',
                            type: 'input',
                            templateOptions: {
                                label: 'Alias',
                                required: false,
                            },
                        },
                        {
                            className: 'col-md-6',
                            key: 'Type',
                            type: 'select',
                            templateOptions: {
                                label: 'Tipo:',
                                required: true,
                                placeholder: 'Selecione a tipo',
                                options: [
                                    { label: 'Restaurante', value: 1 },
                                    { label: 'Bar', value: 2 },
                                    { label: 'Praça de Alimentação', value: 3 },
                                    { label: 'Acomodações', value: 4 },
                                    { label: 'Entretenimento', value: 5 },
                                    { label: 'Nômade', value: 6 },
                                    { label: 'Vendedor de rua', value: 7 },
                                    { label: 'Outro', value: 0 },
                                ],
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'Visibility',
                            type: 'select',
                            templateOptions: {
                                label: 'Visibilidade:',
                                required: true,
                                placeholder: 'Selecione',
                                options: [
                                    { label: 'Privado', value: 0 },
                                    { label: 'Público', value: 1 },
                                ],
                            },
                        },
                        {
                            className: 'col-md-2',
                            key: 'Private',
                            type: 'checkbox',
                            templateOptions: {
                                label: 'Privado',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-2',
                            key: 'Status',
                            type: 'checkbox',
                            templateOptions: {
                                label: 'Ativo',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-2',
                            key: 'IsOpen',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                                label: 'Aberto',
                                disabled: true,
                            },
                        },
                    ],
                },
                {
                    className: 'section-label',
                    template: '<hr /><div><strong>CONTATO</strong></div>',
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'Contact.Name',
                            type: 'input',
                            templateOptions: {
                                label: 'Nome',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-2',
                            key: 'Contact.DDD',
                            type: 'input',
                            templateOptions: {
                                type: 'number',
                                label: 'DDD',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-4',
                            key: 'Contact.PhoneNumber',
                            type: 'input',
                            templateOptions: {
                                type: 'number',
                                label: 'Telefone comercial',
                                required: true,
                            },
                        },
                    ],
                },
                {
                    className: 'section-label',
                    template:
                        '<hr /><div><strong>TIPO DE COZINHA</strong></div>',
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'CuisineType',
                            type: 'select',
                            templateOptions: {
                                label: 'Escolha no máx. 3',
                                multiple: true,
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Id === o2.Id,
                                labelProp: 'label',
                                options:
                                    this.supportService.get<State>(
                                        'CuisineType/Get'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
        {
            label: 'Localização',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-12',
                            type: 'address-search',
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-3',
                            key: 'Local.Address.ZipCode',
                            type: 'input',
                            templateOptions: {
                                type: 'number',
                                label: 'CEP',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-6',
                            key: 'Local.Address.Name',
                            type: 'input',
                            templateOptions: {
                                label: 'Endereço',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-3',
                            key: 'Local.Address.Number',
                            type: 'input',
                            templateOptions: {
                                type: 'number',
                                label: 'Núm.',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-4',
                            key: 'Local.Address.District',
                            type: 'input',
                            templateOptions: {
                                label: 'Bairro',
                                required: true,
                            },
                        },
                        {
                            className: 'col-md-4',
                            key: 'Local.Address.Complement',
                            type: 'input',
                            templateOptions: {
                                label: 'Complemento',
                            },
                        },
                        {
                            className: 'col-md-4',
                            key: 'Local.Country',
                            type: 'select',
                            templateOptions: {
                                label: 'País',
                                required: true,
                                placeholder: 'Selecione',
                                labelProp: 'label',
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Code === o2.Code,
                                options:
                                    this.supportService.get<Country>(
                                        'Country/Get'
                                    ),
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-2',
                            key: 'Local.State',
                            type: 'select',
                            templateOptions: {
                                label: 'Estado',
                                required: true,
                                placeholder: 'Selecione',
                                labelProp: 'label',
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Code === o2.Code,
                                options:
                                    this.supportService.get<State>(
                                        'State/GetActives'
                                    ),
                            },
                        },
                        {
                            className: 'col-md-3',
                            key: 'Local.City',
                            type: 'select',
                            templateOptions: {
                                label: 'Cidade',
                                required: true,
                                placeholder: 'Selecione',
                                labelProp: 'label',
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Code === o2.Code,
                                options: [],
                            },
                            lifecycle: {
                                onInit: (form, field) => {
                                    const updateOptions = () => {
                                        this.updateAddressOptions<City>(
                                            form,
                                            field,
                                            'Local.State',
                                            'City/GetByStateCode',
                                            'stateCode'
                                        );
                                    };

                                    updateOptions();
                                    form.valueChanges.subscribe(updateOptions);
                                },
                            },
                        },
                        {
                            className: 'col-md-3',
                            key: 'Local.Type',
                            type: 'select',
                            templateOptions: {
                                label: 'Tipo',
                                required: true,
                                placeholder: 'Selecione',
                                labelProp: 'label',
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Code === o2.Code,
                                options:
                                    this.supportService.get<LocalType>(
                                        'LocalType/Get'
                                    ),
                            },
                        },
                        {
                            className: 'col-md-4',
                            key: 'Local.Spot',
                            type: 'select',
                            templateOptions: {
                                label: 'Local',
                                required: true,
                                placeholder: 'Selecione',
                                labelProp: 'label',
                                valueProp: (o) => o.value,
                                compareWith: (o1, o2) =>
                                    o1 && o2 && o1.Code === o2.Code,
                                options: [],
                            },
                            lifecycle: {
                                onChanges: (form, field) => {
                                    const updateOptions = () => {
                                        const cityControl =
                                            form.get('Local.City');
                                        const typeControl =
                                            form.get('Local.Type');

                                        if (cityControl.value) {
                                            const cityValue =
                                                cityControl.value.Code;
                                            const typeValue =
                                                typeControl.value.Code;

                                            if (cityValue && typeValue) {
                                                field.templateOptions.options =
                                                    this.supportService.get<LocalType>(
                                                        `Spot/Get?&cityCode=${cityValue}&typeCode=${typeValue}`
                                                    );
                                            }
                                        }
                                    };

                                    updateOptions();
                                    form.valueChanges.subscribe(updateOptions);
                                },
                            },
                        },
                        {
                            className: 'col-md-12',
                            key: 'Local.Geo',
                            type: 'geolocation',
                        },
                    ],
                },
            ],
        },
        {
            label: 'Pagamentos',
            fields: [
                {
                    type: 'repeat',
                    key: 'Payment',
                    fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {
                                className: 'col-md-5',
                                type: 'select',
                                key: 'Code',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Selecione o pagamento',
                                    options: this.supportService.get(
                                        'PaymentType/GetAll',
                                        'Name',
                                        'Code'
                                    ),
                                    change: async (field, $event) => {
                                        const paymentCode =
                                            field.form.controls.Code.value;

                                        if (!this.paymentTypes) {
                                            this.paymentTypes =
                                                await this.supportService
                                                    .getObject(
                                                        'PaymentType/GetAll'
                                                    )
                                                    .toPromise();
                                        }

                                        const paymentType =
                                            this.paymentTypes.find(
                                                (_) => _['Code'] == paymentCode
                                            );

                                        field.templateOptions.options.forEach(
                                            (_) => {
                                                const option = _.find(
                                                    (__) =>
                                                        __.value == paymentCode
                                                );

                                                if (option) {
                                                    field.form
                                                        .get('Method')
                                                        .setValue(option.label);
                                                    field.form
                                                        .get('Online')
                                                        .setValue(
                                                            paymentType[
                                                                'Online'
                                                            ]
                                                        );
                                                    field.form
                                                        .get('Type')
                                                        .setValue(
                                                            paymentType.Type
                                                        );
                                                }
                                            }
                                        );
                                    },
                                },
                            },
                            {
                                className: 'col-md-4 d-none',
                                type: 'input',
                                key: 'Method',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Método',
                                },
                            },
                            {
                                className: 'col-md-2',
                                type: 'checkbox',
                                key: 'IsActive',
                                defaultValue: false,
                                templateOptions: {
                                    label: 'Ativo',
                                },
                            },
                            {
                                className: 'col-md-2',
                                type: 'checkbox',
                                key: 'Online',
                                templateOptions: {
                                    label: 'Online',
                                    disabled: true,
                                },
                            },
                            {
                                className: 'col-md-3',
                                type: 'select',
                                key: 'Type',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Cash or Cashless',
                                    disabled: true,
                                    options: [
                                        { label: 'Cash', value: 0 },
                                        { label: 'Cashless', value: 1 },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
        {
            label: 'Formas de entrega',
            fields: [
                {
                    type: 'repeat',
                    key: 'Delivery',
                    fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {
                                className: 'col-md-4',
                                type: 'select',
                                key: 'Code',
                                templateOptions: {
                                    label: 'Forma de entrega/retirada',
                                    required: true,
                                    placeholder: 'Selecione a forma de entrega',
                                    options: this.supportService.get(
                                        'DeliveryType/Get',
                                        'Name',
                                        'Code'
                                    ),
                                    change: async (field, $event) => {
                                        const deliveryCode =
                                            field.form.controls.Code.value;

                                        field.templateOptions.options.forEach(
                                            (_) => {
                                                const option = _.find(
                                                    (__) =>
                                                        __.value == deliveryCode
                                                );

                                                if (option) {
                                                    field.form
                                                        .get('Method')
                                                        .setValue(option.label);
                                                }
                                            }
                                        );
                                    },
                                },
                            },
                            {
                                className: 'col-md-4 d-none',
                                type: 'input',
                                key: 'Method',
                                templateOptions: {
                                    label: 'Método',
                                },
                            },
                            {
                                className: 'col-md-2',
                                type: 'input',
                                key: 'Price',
                                templateOptions: {
                                    label: 'Valor',
                                },
                            },
                            {
                                className: 'col-md-2 d-flex align-self-center',
                                type: 'checkbox',
                                key: 'IsActive',
                                templateOptions: {
                                    label: 'Ativo',
                                },
                            },
                            {
                                className: 'col-md-4',
                                type: 'input',
                                key: 'MinOrderPrice',
                                defaultValue: 1,
                                templateOptions: {
                                    label: 'Preço mínimo do pedido',
                                },
                                hideExpression: (
                                    model: any,
                                    formState: any,
                                    field: FormlyFieldConfig
                                ) => {
                                    return this.hideDeliveryFields(
                                        model,
                                        formState,
                                        field
                                    );
                                },
                            },
                            {
                                className: 'col-md-4',
                                type: 'input',
                                key: 'DistanceLimit',
                                defaultValue: 5,
                                templateOptions: {
                                    label: 'Distância máx. delivery (km)',
                                },
                                hideExpression: (
                                    model: any,
                                    formState: any,
                                    field: FormlyFieldConfig
                                ) => {
                                    return this.hideDeliveryFields(
                                        model,
                                        formState,
                                        field
                                    );
                                },
                            },
                            {
                                className: 'col-md-6',
                                type: 'input',
                                key: 'LocatorComponent.Name',
                                templateOptions: {
                                    label: 'Nome do componente localizador',
                                },
                                hideExpression: (
                                    model: any,
                                    formState: any,
                                    field: FormlyFieldConfig
                                ) => {
                                    return this.hideDeliveryFields(
                                        model,
                                        formState,
                                        field
                                    );
                                },
                            },
                            {
                                className: 'col-md-6',
                                type: 'input',
                                key: 'LocatorComponent.Title',
                                templateOptions: {
                                    label: 'Título',
                                },
                                hideExpression: (
                                    model: any,
                                    formState: any,
                                    field: FormlyFieldConfig
                                ) => {
                                    return this.hideDeliveryFields(
                                        model,
                                        formState,
                                        field
                                    );
                                },
                            },
                        ],
                    },
                },
            ],
        },
        {
            label: 'Horários',
            fields: [
                {
                    type: 'repeat',
                    key: 'WorkingHours',
                    fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {
                                className: 'col-md-2',
                                type: 'checkbox',
                                key: 'Active',
                                templateOptions: {
                                    label: 'Aberto',
                                },
                            },
                            {
                                className: 'col-md-3',
                                type: 'select',
                                key: 'OpenDay',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Selecione o dia',
                                    options: [
                                        { label: 'Domingo', value: 0 },
                                        { label: 'Segunda-feira', value: 1 },
                                        { label: 'Terça-feira', value: 2 },
                                        { label: 'Quarta-feira', value: 3 },
                                        { label: 'Quinta-feira', value: 4 },
                                        { label: 'Sexta-feira', value: 5 },
                                        { label: 'Sábado', value: 6 },
                                    ],
                                },
                            },
                            {
                                className: 'col-md-2',
                                type: 'input',
                                key: 'OpenTime',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Hora abertura',
                                },
                            },
                            {
                                className: 'col-md-3',
                                type: 'select',
                                key: 'CloseDay',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Selecione o dia',
                                    options: [
                                        { label: 'Domingo', value: 0 },
                                        { label: 'Segunda-feira', value: 1 },
                                        { label: 'Terça-feira', value: 2 },
                                        { label: 'Quarta-feira', value: 3 },
                                        { label: 'Quinta-feira', value: 4 },
                                        { label: 'Sexta-feira', value: 5 },
                                        { label: 'Sábado', value: 6 },
                                    ],
                                },
                            },
                            {
                                className: 'col-md-2',
                                type: 'input',
                                key: 'CloseTime',
                                templateOptions: {
                                    required: true,
                                    placeholder: 'Hora fechamento',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        {
            label: 'Parâmetros',
            fields: [
                {
                    type: 'repeat',
                    key: 'Parameters',
                    fieldArray: {
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {
                                className: 'col-md-12',
                                type: 'input',
                                key: 'Name',
                                templateOptions: { placeholder: 'Section' },
                            },
                            {
                                type: 'repeat',
                                key: 'Values',
                                fieldArray: {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-md-6',
                                            type: 'input',
                                            key: 'Name',
                                            templateOptions: {
                                                placeholder: 'Parameter',
                                            },
                                        },
                                        {
                                            className: 'col-md-6',
                                            type: 'input',
                                            key: 'Value',
                                            templateOptions: {
                                                placeholder: 'Value',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
    ];

    hideDeliveryFields(model: any, formState: any, field: FormlyFieldConfig) {
        return model.Code !== '5ea5050707f686a19a705af1';
    }

    updateAddressOptions<T>(
        form: any,
        field: any,
        triggeringModel: string,
        endpoint: string,
        endpointParams: string
    ) {
        const formControl = form.get(triggeringModel);

        if (formControl.value) {
            const optionValue = formControl.value.Code;

            if (optionValue)
                field.templateOptions.options = this.supportService.get<T>(
                    `${endpoint}?&${endpointParams}=${optionValue}`
                );
        }
    }

    private copyToClipboard(text: string) {
        this.clipboard.copy(text);
        this.modalService.toast('Texto copiado para área de transferência!');
    }
}
