import { Image } from 'src/app/menu/shared/image.model';
import { Contact } from './contact.model';
import { CuisineType } from './cuisine-type.model';
import { Delivery } from './delivery.model';
import { Localization } from './localization.model';
import { MenuCatalog } from './menu-catalog.model';
import { WorkingHour } from './working-hour.model';
import { Parameter } from './parameter.model';
import { Payment } from './payment.model';

export class Company {
    public CompanyId: string;

    public AccountId: string;

    public Cnpj: number;

    public CompanyName: string;

    public TradingName: string;

    public CompanyType: string;

    public Alias: string;

    public ImageId: string;

    public Image: Image;

    public Email: string;

    public IsOpen: boolean;

    public Private: boolean;

    public CuisineType: CuisineType[] = [];

    public Status: boolean;

    public Contact: Contact = new Contact();

    public Local: Localization = new Localization();

    public Payment: Payment[] = [];

    public Delivery: Delivery[] = [];

    public WorkingHours: WorkingHour[] = [];

    public Menu: MenuCatalog[] = [];

    public Parameters: Parameter[] = [];
}
