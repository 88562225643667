
import { throwError as observableThrowError, Observable, Subject, Subscription } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders
} from '@angular/common/http';

import { ServiceConfig } from '../_config/services.config';
import { AuthService } from '../app/auth/auth.service';
import { RefreshTokenService } from '../services/refresh-token.service';
import { MessageService } from 'primeng/api';


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    private subscription: Subscription;
    private token: string;

    private refreshTokenInProgress = false;
    private tokenRefreshedSubject = new Subject<boolean>();

    private userLogout = false;
    private userLogoutSubject = new Subject<boolean>();

    constructor(private authService: AuthService, refreshTokenService: RefreshTokenService, private messageService: MessageService) {
        this.subscription = refreshTokenService.refreshTokenSubject.subscribe(token => this.token = token);
        this.tokenRefreshedSubject.subscribe(response => this.refreshTokenInProgress = response);
        this.userLogoutSubject.subscribe(logout => this.userLogout = logout);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log('intercepted request ... ');

        if (request.url.includes(ServiceConfig.API_ENDPOINT)) {
            request = request.clone({ headers: this.getHttpHeaders() });
        }

        if (request.url.includes(ServiceConfig.LOGIN_ENDPOINT)) {
            request = request.clone({ headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) });
        }

        console.log(request.headers);

        console.log('Sending request with new header now ...');

        // Send the newly created request
        return next.handle(request).pipe(
            catchError(error => {
                this.logoutUser(false);
                console.log('userLogout 1: ', this.userLogout);

                // onError
                console.log(error);

                if (!error.ok && error.status != 0 && error.error.error === 'invalid_grant') {
                    console.log('Logout 1');
                    this.logoutUser(true);
                    this.authService.logout();
                    this.messageService.add({
                        key: 'bottom-center',
                        severity: 'info',
                        summary: 'Sessão expirou!',
                        detail: 'Sua sessão expirou! Faça o login novamente!' });
                    console.log('userLogout 2: ', this.userLogout);
                }

                console.log('userLogout 3: ', this.userLogout);
                if (request.url != ServiceConfig.LOGIN_ENDPOINT) {
                    if (!this.userLogout) {
                        return this.refreshToken()
                        .pipe(
                            switchMap(() => {
                                console.log('Token refreshed!');
                                console.log('Retrying ... ', request.url);
                                console.log('tokenSubject: ', this.token);

                                request = request.clone({ headers: this.getHttpHeaders() });
                                return next.handle(request);
                            }),
                            catchError(() => {
                                console.log('Refresh token expired!');
                                this.updateRefreshTokenProgress(false);
                                return observableThrowError(error);
                            })
                        )
                    }
                }

                return observableThrowError(error);
            })) as any;
    }

    private getHttpHeaders() {
        return new HttpHeaders({
            'Cache-Control': 'no-store, no-cache',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        });
    }

    private refreshToken() {
        console.log('refreshTokenInProgress: ', this.refreshTokenInProgress);

        if (!this.refreshTokenInProgress) {
            this.updateRefreshTokenProgress(true);
            console.log('refreshTokenInProgress 2: ', this.refreshTokenInProgress);

            return this.authService.refreshToken().pipe(
               tap(() => this.updateRefreshTokenProgress(false)));
        }
    }

    private logoutUser(logout: boolean) {
        this.userLogoutSubject.next(logout);
    }

    private updateRefreshTokenProgress(refreshTokenInProgress: boolean) {
        this.tokenRefreshedSubject.next(refreshTokenInProgress);
    }
}