import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IModalService, ISocketIOService, IStorageService, ModalPosition, ModalType, SocketIOChannel, StorageKey } from '@foodra/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Company } from 'src/models/company/company.model';
import { AuthService } from '../../app/auth/auth.service';
import { ValidationResponse } from '../../app/validators/validation-response';
import { StoreService } from '../../providers/store.service';

@Component({
    selector: 'top',
    templateUrl: './top.component.html',
    styles: [`
        .navbar-brand { margin-left: 20px; }
        .company-logo { width: 70px; }

        .right-section {
            position: absolute;
            right: 20px;
        }

        .cursor-pointer { cursor: pointer; }
        a { color: #fff !important; cursor: pointer; }
        .fa { color: #fff; }

        .dropdown-submenu {
            position: relative;
          }

          .dropdown-submenu a::after {
            transform: rotate(-90deg);
            position: absolute;
            right: 6px;
            top: .8em;
          }

          .dropdown-submenu .dropdown-menu {
            top: 0;
            left: 100%;
            margin-left: .1rem;
            margin-right: .1rem;
          }

          .dropdown-left { left: -115px; }
          .dropdown-item-custom { padding: .25rem 1.5rem; }
          .dropdown-label { margin: 0; }

          .menu-open { }
          .badge-top {
            top: -12px;
            right: -5px;
            float: right;
            margin-left: -40px;
          }

          @media screen and (max-width: 1024px) {
            .nav-bar-width { width: 100vw; }

            .item-button {
                line-height: 2em;
            }
          }

          @media screen and (max-width: 768px) {
            .menu-open {
                position: initial !important;
                transform: none !important;
              }

            .navbar-brand { margin: 0px; }
            }

            @media screen and (max-width: 320px) {
                .opening-button-size {  max-width: 120px; }
            }

        `]
})

export class TopComponent implements OnInit, OnDestroy {
    private connectionSubscription: Subscription;
    private maxReconnectionReachSubscription: Subscription;
    private reconnectionDelayTimeInSeconds: number = 5;
    private hasMaxReconnectionReach: boolean = false;

    public menuOpen = false;
    private isLoading = true;
    public company: Company;
    public companyName = '';
    public isOpen: boolean;
    public menuList: any[];
    public activeNotification = false;

    /**
     * Gets whether the socket is connected or not.
     */
    isSocketConnected: boolean;

    constructor(private authService: AuthService,
        private storeService: StoreService,
        private router: Router,
        private modalService: IModalService,
        private socketIoService: ISocketIOService,
        private storageService: IStorageService) {
            this.maxReconnectionReachSubscription = this.socketIoService.reconnectionAttemptReach.subscribe((hasReach: boolean) => {
                this.hasMaxReconnectionReach = hasReach;
            });
            this.connectionSubscription = this.socketIoService.isConnected.subscribe((isConnected: boolean) => {
                this.isSocketConnected = isConnected;

                if (!isConnected) {
                    if (this.hasMaxReconnectionReach) {
                        console.log(`You've reached the maximum reconnect attempt.`);
                        return;
                    }

                    setTimeout(() => {
                        this.socketIoService.reconnect();
                    }, this.reconnectionDelayTimeInSeconds * 1000);
                    return;
                }
            });
        }

    /**
     * Page Init.
     */
    ngOnInit() {
        this.connectSocketIO();
        localStorage.removeItem('company');
        this.storageService.set(StorageKey.Company, null);
        this.menuList = this.getMenuList();

        this.storeService.onCompanyChange
            .subscribe((_: Company) => {
                if (_) {
                    this.companyName = _.TradingName;
                    this.getCompany(_.CompanyId);

                    this.menuList = this.getMenuList();
                    this.menuList.push(
                        {
                            id: 5, name: 'Financeiro', icon: 'fa fa-pencil-square-o', route: null, childs: [
                                { id: 1, name: 'Recebimento', icon: null, route: '/payable', childs: null }
                            ]
                        },
                        {
                            id: 3, name: 'Pedidos', icon: 'fa fa-pencil-square-o', route: null, childs: [
                                { id: 1, name: 'Confirmar Pedidos', icon: null, route: '/board', childs: null },
                                { id: 2, name: 'Histórico Pedidos', icon: null, route: '/order-history', childs: null }
                            ]
                        },
                        {
                            id: 4, name: 'Cardápio', icon: 'fa fa-list-alt', route: '/menu', childs: null
                        }
                    );
                } else {
                    localStorage.removeItem('company');
                    this.company = _;
                    this.menuList = this.getMenuList();
                    this.router.navigate(['/']);
                }
            });
    }

    /**
     * Change Event when it has more than one company.
     * @param company
     */
    onCompanyChange(company) {
        this.companyName = company.TradingName;
        this.isOpen = company.IsOpen;
        this.getCompany(company.CompanyId)
        this.updateChannel(company);

        this.router.navigate(['/']);
    }

    /**
     * Get Company Data.
     * @param companyId
     */
    getCompany(companyId) {
        this.storeService.getStore(companyId)
            .subscribe(res => {
                this.isLoading = false;
                this.company = res
                this.isOpen = res.IsOpen;
                localStorage.setItem('company', JSON.stringify(this.company));
                this.storageService.set(StorageKey.Company, JSON.stringify(this.company))
                this.storageService.set(StorageKey.CompanyId, this.company.CompanyId);
            },
            error => { console.log(error); });
    }

    /**
     * Confirm whether is to close or open the store.
     */
    confirmChangeStoreStatus() {
        const message = this.isOpen ? 'fechar' : 'abrir';
        this.modalService.confirm(`Tem certeza que deseja ${message} a loja?`, 'Loja',
            this.changeStoreStatus.bind(this));
    }

    /**
     * Open or close the selected store.
     */
    changeStoreStatus() {
        this.company = JSON.parse(localStorage.getItem('company'));
        this.storeService.updateOpeningStatus(this.company)
            .then((response: any) => {
                if (response.Success) {
                    this.getCompany(this.company.CompanyId);
                } else {
                    const message = ValidationResponse.GetResponse(response.Type);
                    this.modalService.toast(message, 'Ops!', ModalType.Error, ModalPosition.Bottom, true);
                }
            });
    }

    /*
     * Log out the user.
     */
    logout() {
        this.authService.logout();
        this.modalService.toast('Você saiu com sucesso!', 'Logout!');
    }

    collapse() {
        this.menuOpen = !this.menuOpen;
    }

    closeCompanyView() {
        this.socketIoService.leave(this.company.CompanyId);
        this.storeService.change(null);
    }

    close(menu: any) {
        if (!menu.childs) {
            this.menuOpen = !this.menuOpen;
        }
    }

    ngOnDestroy() {
        localStorage.clear();
    }

    private getMenuList() {
        return [
            {
                id: 1, name: 'Dashboard', icon: 'fa fa-tachometer', route: '/dashboard', childs: null
            },
            {
                id: 2, name: 'Gerenciar', icon: 'fa fa-home', route: null, childs: [
                    { id: 1, name: 'Contas', icon: null, route: '/accounts', childs: null },
                    { id: 2, name: 'Empresas', icon: null, route: '/companies', childs: null }
                ]
            }
        ];
    }

    private connectSocketIO(): void {
        this.socketIoService.connect();
    }

    private updateChannel(company: Company) {
        if (!this.isSocketConnected) {
            console.log('Socket is not connected.');
            return;
        }

        this.socketIoService.changeChannel(<SocketIOChannel> {
            Id: company.CompanyId,
            Name: company.TradingName
        });
    }
}
