import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule, PanelModule, SplitButtonModule, TooltipModule, DialogModule, ConfirmDialogModule, TableModule, ConfirmationService } from 'primeng/primeng';
import { DndModule } from 'ng2-dnd';
import { MenuComponent } from './menu.component';
import { MenuService } from "./menu.service";
import { MainProductModule } from './main-product/main-product.module';
import { StoreService } from 'src/providers/store.service';

registerLocaleData(localePt);

@NgModule({
  imports: [
    CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, SharedModule, TableModule,
    DndModule.forRoot(), PanelModule, SplitButtonModule, BrowserAnimationsModule, TooltipModule, DialogModule,
    MainProductModule, ConfirmDialogModule
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers: [MenuService, StoreService, ConfirmationService, { provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class MenuModule { }
