import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule  } from '@angular/platform-browser';
import { DialogModule, ConfirmDialogModule, InputSwitchModule } from 'primeng/primeng';

// Services
import { BoardService } from './board.service';
import { OrderService } from 'src/providers/order.service';
import { ConfirmationService } from 'primeng';

// Component
import { BoardComponent } from './board.component';
import { OrderCardModule } from 'src/ui-components/order-card/order-card.module';
import { ReceiptModule } from '../receipt/receipt.module';
import { PopoutService } from 'src/services/pop-out/pop-out.service';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        DialogModule,
        ConfirmDialogModule,
        OrderCardModule,
        ReceiptModule,
        InputSwitchModule
    ],
    exports: [BoardComponent],
    declarations: [BoardComponent],
    providers: [BoardService, ConfirmationService, OrderService, PopoutService],
    bootstrap: [BoardComponent],
})
export class BoardModule { }
