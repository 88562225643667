import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div class="mb-3">
        <legend *ngIf="to.label">{{ to.label }}</legend>
        <p *ngIf="to.description">{{ to.description }}</p>

        <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
            <formly-validation-message [field]="field"></formly-validation-message>
        </div>

        <div *ngFor="let field of field.fieldGroup;let i = index;" class="row">
            <formly-field class="col-md-11" [field]="field"></formly-field>
            <div class="col-md-1 text-right">
                <button class="btn btn-sm btn-danger" type="button" (click)="remove(i)">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <div class="d-flex flex-row-reverse">
            <button class="btn btn-sm btn-primary" type="button" (click)="add()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {
/*
// <div class="p-2">
    //     <div class="col-md-1 ml-auto">
    //         <button class="btn btn-sm btn-primary" type="button" (click)="add(field.fieldGroup.length)">
    //             <i class="fa fa-plus"></i>
    //         </button>
    //     </div>
    //     <div *ngFor="let field of field.fieldGroup; let i = index;">
    //         <formly-group [field]="field" class="form-inline">
    //             <div class="col-md-1">
    //                 <button class="btn btn-sm btn-danger" type="button" (click)="remove(i)">
    //                     <i class="fa fa-minus"></i>
    //                 </button>
    //             </div>
    //         </formly-group>
    //     </div>
    // </div>
*/
}