import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AddonComponent } from './addon/addon.component';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'main-product',
    templateUrl: './main-product.component.html'
})

export class MainProductComponent implements OnInit {

    @ViewChild('addon') addonComponent: AddonComponent;
    @ViewChild('tab') tab: NgbTabset;

    /**
     * INS, UPD, CNS
     */
    @Input() mode;

    /**
     * Product properties.
     */
    @Input() product;

    /**
     * Set if modal Product is opened or not when complete.
     */
    @Input() isDialogOpen;

    /**
     * Emit when product is changed.
     */
    @Output() productChange = new EventEmitter();

    /**
     * Emit when product is changed.
     */
    @Output() tabChange = new EventEmitter();

    constructor() { }

    ngOnInit() {
        if (this.addonComponent)
            this.addonComponent.clean();
        this.tab.select('tab-product')
    }

    changeProduct(product) {
        this.productChange.emit(product);
    }

    changeTab(tab) {
        this.tabChange.emit(tab);
    }
}