import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISocketIOService } from '@foodra/core';
import { Dashboard } from '@foodra/core/shared/models/dashboard/dashboard';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from 'src/models/company/company.model';
import { ValidationResult } from 'src/models/validations/validation-result.model';
import { ApiService } from 'src/services/api.service';
import { ServiceConfig } from 'src/_config/services.config';

@Injectable()
export class StoreService {
    private url: string = ServiceConfig.API_ENDPOINT;

    public onCompanyChange = new Subject<Company>();

    constructor(private http: HttpClient,
        private apiService: ApiService,
        private socketIoService: ISocketIOService) { }

    /**
     * Get total socket connections.
     */
    get totalConnections(): Observable<number> {
        return this.socketIoService.totalConnections;
    }

    get(): Promise<Company[]> {
        return this.apiService.get<Company[]>('Company/Get');
    }

    getById(id: string): Promise<Company> {
        return this.apiService.get<Company>(`Company/Get/${id}`);
    }

    /**
     * Get a company by id.
     * @param id Company Id
     */
    getStore(id: string): Observable<Company> {
        return this.http.get(this.url + '/Company/Get/' + id).pipe(
            map(res => <Company>res));
    }

    /**
     * Get Company stores.
     */
    getStores(): Observable<Company[]> {
        const clientId = localStorage.getItem('client_id');

        return this.http.get(this.url + '/Company/GetByAccountId/' + clientId).pipe(
            map(res => <Company[]>res));
    }

    /**
     * Adds a new company.
     *
     * @param accountId The account id.
     *
     * @returns The validation result.
     */
    add(accountId: string): Promise<ValidationResult> {
        return this.apiService.post(`Company/Add?accountId=${accountId}`, {});
    }

    /**
     * Update company.
     * @param company
     */
    update(company: Company) {
        return this.apiService.put<Company>('Company/Update', company);
    }

    /**
     * Update Company Store.
     * @param company
     */
    async updateOpeningStatus(company: any) {
        return await this.http.put(this.url + '/Company/UpdateOpeningStatus/' + company.CompanyId, {}).toPromise();
    }

    public async getDashboard(date: Date, companyId?: string): Promise<Dashboard> {
        const params = companyId
            ? `date=${date.toUTCString()}&companyId=${companyId}`
            : `date=${date.toUTCString()}`
        return this.apiService.get<Dashboard>(`Dashboard/Get?${params}`);
    }

    async updatePushNotificationDevice(accountId: string, deviceId: string) {
        return this.http.put(this.url + '/Account/UpdateDevice?accountId=' + accountId + '&deviceId=' + deviceId,
             null).toPromise();
    }

    /**
     * Changes the company context.
     *
     * @param company model.
     */
    change(company: Company): void {
        this.onCompanyChange.next(company);
    }

    /**
     * Emits an event to force all connected companies to check for new orders.
     *
     * @returns True if success, otherwise false.
     */
    forceCheckNewOrders(): boolean {
        if (this.socketIoService.isConnected) {
            this.socketIoService.emit('company-check-orders');
            return true;
        }

        return false;
    }
}
