import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Order } from '@foodra/core';
import { PaymentType } from '@foodra/core/shared/enums/payment-type.enum';
import { OrderProduct } from '@foodra/core/shared/models/order/order-product';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { ServiceConfig } from 'src/_config/services.config';
import { Image } from 'src/core/shared/models/image'
import { IWhatsAppService } from '@foodra/core/services/messaging/whatsapp.service.interface';

@Component({
    selector: 'order-detail-page',
    templateUrl: 'order-detail.component.html',
    styleUrls: ['order-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderDetailComponent implements OnInit {

    private static readonly dictionary: { [key: string]: string } = {
        'Pending': 'Aguardando confirmação',
        'Preparing': 'Preparando',
        'Delivering': 'Saiu para entrega',
        'Completed': 'Concluído',
        'Rejected': 'Recusado',
        'Cancelled': 'Cancelado'
    }

    @Input()
    order: Order;

    subtotal: number;

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private whatsappService: IWhatsAppService) { }

    /**
     * @inheritdoc
     */
    ngOnInit(): void {
        if (this.config.data && this.config.data.order) {
            this.order = this.config.data.order;
        }
        this.addImageUrl();
        this.translateStatus(this.order.Status);
        this.updateSubTotal();
    }

    get address(): string {
        const address = `${this.order.DeliveryPlace.Location.Address.Name}, ${this.order.DeliveryPlace.Location.Address.Number},
            ${this.order.DeliveryPlace.Location.Address.District}, ${this.order.DeliveryPlace.Location.Address.City}
            - ${this.order.DeliveryPlace.Location.Address.State}`;

            if(this.order.DeliveryPlace.Location.Address.Complement) {
                address.concat(`, ${this.order.DeliveryPlace.Location.Address.Complement}`)
            }
        return address;
    }

    get isDelivery(): boolean {
        return this.order.DeliveryPlace.Location && this.order.DeliveryPlace.Location.Address
            ? true
            : false
    }

    get mapsUrl(): string {
        const from: string = `${this.order.Company.Local.Geo.Latitude},${this.order.Company.Local.Geo.Longitude}`
        const to: string = `${this.order.DeliveryPlace.Location.Address.Geo.Latitude},${this.order.DeliveryPlace.Location.Address.Geo.Longitude}`
        return this.isDelivery
            ?`https://www.google.com/maps/dir/${from}/${to}`
            : '';
    }


    /**
     * Adds image URL to show the images to the user.
     */
    private addImageUrl() {
        if (!this.order) {
            return;
        }

        for (const product of this.order.Products) {
            if (product.ImageId) {
                product.Image = <Image> {};
                product.Image.ImageOriginal = ServiceConfig.PRODUCT_IMAGE_ENDPOINT
                    .replace('{company}', this.order.Company.Id)
                    .replace('{image}', product.ImageId);
            }
        }
    }

    private translateStatus(orderStatus): void {
        if (!this.order) {
            return;
        }

        const translations = OrderDetailComponent.dictionary;
        for (const status of orderStatus) {
            for (const key in translations) {
                if (translations.hasOwnProperty(key) && status.Description == key) {
                    status.Description = translations[key];
                }
            }
        }
    }

    private updateSubTotal(): void {
        this.subtotal = 0;
        if (this.order && this.order.Products) {
            for (const product of this.order.Products) {
                this.subtotal += product.Subtotal;
            }
        }
    }

    shouldShowProductSubtotal(product: OrderProduct): boolean {
        return product && (product.Quantity > 1 || product.Subcategories && product.Subcategories.length > 0);
    }

    /**
     * Shares the address on whatsapp.
     */
    shareAddres(): void {
        const destination: string = `${this.order.DeliveryPlace.Location.Address.Geo.Latitude},${this.order.DeliveryPlace.Location.Address.Geo.Longitude}`
        const message = `Endereço: ${this.address} | http://maps.google.com/maps?sensor=true&saddr=&daddr=${destination}`;
        this.whatsappService.send(message);
    }

    /**
     * @returns The payment icon.
     */
    getPaymentTypeIcon(): string {
        if (this.order && !this.order.Payment.Online) {
            return this.order.Payment.Type == PaymentType.Cash
                ? 'fa-money text-success'
                : 'fa-credit-card';
        }

        switch (this.order.Card.Brand) {
            case 'MasterCard':
                return 'fa-cc-mastercard';
            case 'Visa':
                return 'fa-cc-visa';
            case 'Discover':
                return 'fa-cc-discover';
            case 'JCB':
                return 'fa-cc-jcb';
            case 'Diners':
                return 'fa-cc-diners-club';
            case 'AmericanExpress':
                return 'fa-cc-amex';
            default:
                return 'fa-credit-card';
        }
    }
}