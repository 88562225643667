import { Item } from "./item.model";

export class Subcategory {
    
    public SubcategoryId: string;
    public Name: string;
    //public Mandatory: boolean;
    //public MultipleChoise: boolean;
    public Min: number;
    public Max: number;
    public IsActive: boolean;
    public Position: number;
    public Collapse: boolean;    
    public Items: Array<Item>;
}