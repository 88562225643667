import { DynamicDialogRef } from "primeng/primeng";

export abstract class IOrderService {

    /**
     * Gets the company orders.
     */
    abstract get(): Promise<any>;

    /**
     * Gets the order by the transaction id.
     *
     * @param companyId The company id.
     * @param transactionId The transaction id.
     *
     * @returns A promise of orders. TODO: Change parameter type
     */
    abstract getByTid(companyId: string, transactionId: string): Promise<any>;

    /**
     * Opens an order detail in a modal.
     *
     * @param order The order to be shown.
     *
     * @returns The modal dynamic dialog reference.
     */
    abstract open(order: any): DynamicDialogRef;
}
