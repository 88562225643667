import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
    ConfirmationService,
    ConfirmDialogModule,
    DialogModule,
    DialogService,
    DropdownModule,
    VirtualScrollerModule,
} from 'primeng';
import { TableModule } from 'primeng/table';
import { StoreService } from 'src/providers/store.service';
import { SupportTablesService } from 'src/providers/support-tables.service';
import { ApiService } from 'src/services/api.service';
import { DynamicFormsModule } from 'src/ui-components/dynamic-forms/dynamic-forms.module';
import { CompaniesComponent } from './companies/companies.component';

const imports = [
    BrowserModule,
    DynamicFormsModule,
    TableModule,
    DialogModule,
    ConfirmDialogModule,
    VirtualScrollerModule,
    DropdownModule,
    FormsModule,
];

const services = [
    ApiService,
    StoreService,
    SupportTablesService,
    DialogService,
    ConfirmationService,
];

@NgModule({
    imports: [imports],
    exports: [CompaniesComponent],
    declarations: [CompaniesComponent],
    providers: [services],
})
export class CompaniesModule {}
