
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceConfig } from 'src/_config/services.config';
import { Menu } from './shared/menu.model';

@Injectable()
export class MenuService {
    private url: string   = ServiceConfig.API_ENDPOINT;

    /**
     * Constructor method.
     * @param http 
     */
    constructor(private http: HttpClient) { }

    getMenu(): Observable<Menu> {
        const data = localStorage.getItem('company');
        const companyData = JSON.parse(data);

        return this.http.get(this.url + '/Menu/GetByCompany/' + companyData.CompanyId).pipe(
            map(
                res => { return <Menu>res; },
                err => { return err; }
            ));
    }

    addMenu(MenuData: Menu) {
        const data = localStorage.getItem('company');
        const companyData = JSON.parse(data);

        const parm = {
            'CompanyId': companyData.CompanyId,
            'Menu': MenuData
        }
		return this.http.post(this.url + '/Menu/Add', JSON.stringify(parm)).pipe(
			map(res => { return res; }));
    }

    updateMenu(menu: Menu) {
        const data = localStorage.getItem('company');
        const companyData = JSON.parse(data);

        const parm = {
            CompanyId: companyData.CompanyId,
            Menu: menu
        }

        return this.http.put(this.url + '/Menu/Update', JSON.stringify(parm), { reportProgress: true, observe: 'events' })
            .pipe(
                map((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        return {
                            progress: Math.round(100 * event.loaded / event.total),
                            completed: false,
                            body: null
                        };
                    }
                    else if (event.type === HttpEventType.Response) {
                        return {
                            progress: 100,
                            completed: event.ok,
                            body: event.body
                        };
                    }
                },
                error => console.log(error)
            ));
    }
}