import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';

@Component({
    selector: 'order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['order-card.component.scss']
})

export class OrderCardComponent implements OnInit {
    @Input() title: string;
    @Input() orders: any;
    @Input() color: string;
    @Input() status: number;

    @Output() onStatusChange = new EventEmitter();
    @Output() onPrint = new EventEmitter();
    @Output() onShowOrder = new EventEmitter();

    statusEnum = {
        Pending: 1,
        Preparing: 2,
        Delivering: 3,
        Completed: 4
    };

    orderAction;
    private orderActionList = [
        {
            Name: 'Confirmar',
            cssClass: 'btn btn-sm btn-success btn-block mr-1 pulse',
            toggleCssClass: 'btn-outline-success'
        },
        {
            Name: 'Entregar',
            cssClass: 'btn btn-sm btn-warning btn-block mr-1',
            toggleCssClass: 'btn-outline-warning'
        },
        {
            Name: 'Concluir',
            cssClass: 'btn btn-sm btn-info btn-block mr-1',
            toggleCssClass: 'btn-outline-info'
        }
    ];

    collapse: boolean;

    constructor(intl: TimeagoIntl) {
        intl.strings = {
            prefixAgo: null,
            prefixFromNow: '?',
            suffixAgo: '',
            suffixFromNow: '',
            seconds: 'Agora',
            minute: '1 min.',
            minutes: '%d min.',
            hour: '1h',
            hours: '%dhrs',
            day: '1 dia',
            days: '%d dias',
            month: '1 mês',
            months: '%d meses',
            year: '1 ano',
            years: '%d anos',
            wordSeparator: ' ',
        };
        intl.changes.next();
    }

    ngOnInit() {
        this.orderAction = this.orderActionList[this.status - 1];
    }

    /**
     * Remove an object (order) from a given array (of orders).
     * @param objectToRemove Object to Remove
     * @param arrayToRemoveFrom Array where you want to remove the object.
     */
    removeOrders(objectToRemove, arrayToRemoveFrom): void {
        const index = arrayToRemoveFrom.indexOf(objectToRemove, 0);

        if (index > -1) {
            arrayToRemoveFrom.splice(index, 1);
        }
    }

    public changeStatus(event: any, order: any, rejected = false, cancelled = false): void {
        event.stopPropagation();
        this.onStatusChange.emit({ order: order, rejected: rejected, cancelled: cancelled });
    }

    /**
     * Clean finished orders.
     */
     cleanFinishedOrders() {
        event.stopPropagation();
        this.orders.forEach(_ => {
            const len = _.Status.length - 1;

            if (_.Status[len].Code === this.statusEnum.Completed) {
                this.removeOrders(_, this.orders);
            }
        });
    }

    /**
     * Display order.
     * @param order
     */
    showOrder(order: any) {
        event.stopPropagation();
        this.onShowOrder.next({ showOrder: true, order: order });
    }

    getTooltipDescription(order: any) {
        if (this.status == this.statusEnum.Pending && this.paidOrder(order)) {
            return 'Pago';
        }

        if (this.status == this.statusEnum.Preparing) {
            return 'Em preparo';
        }

        if (this.status == this.statusEnum.Delivering) {
            return 'Em entrega';
        }

        if (this.status == this.statusEnum.Completed) {
            return 'Concluído';
        }

        return "Aguardando pagamento";
    }

    paidOrder(order: any) {
        return (order.Payment.Online
            && order.Transaction.Status == 'Paid');
    }

    print(order: any) {
        event.stopPropagation();
        this.onPrint.next(order);
    }

    /**
     * Checks whether an order is delayed
     *
     * @param order The order to be check.
     *
     * @returns True if is delayed, otherwise false.
     */
    isDelayed(order: any): boolean {
        const orderAlertPendingTime = 180; // 3 min.
        const orderAlertPrepareTime = 1200; // 20 min.
        const orderAlertDeliveringTime = 900; // 15 min.

        const orderAlertPrepareTimeTotal = orderAlertPrepareTime + orderAlertPendingTime;
        const orderAlertDeliveringTimeTotal = orderAlertDeliveringTime + orderAlertPrepareTimeTotal;

        const seconds = Math.floor((+new Date() - +new Date(order.OrderDate)) / 1000);

        return this.status == this.statusEnum.Pending && seconds > orderAlertPendingTime ||
            this.status == this.statusEnum.Preparing && seconds > orderAlertPrepareTimeTotal ||
            this.status == this.statusEnum.Delivering && seconds > orderAlertDeliveringTimeTotal;
    }
}
