<div class="image-uploader">
    <p-sidebar
        [(visible)]="showSidebar"
        [fullScreen]="true"
        [autoZIndex]="true"
        styleClass="image-uploader-sidebar"
        (onHide)="cancelCrop()">
        <div class="row">
            <div class="col top10x"><h5>Selecionar foto do produto.</h5></div>
            <div class="col top10x text-danger text-right" *ngIf="imageUploadError">Ops! Parece que você não selecionou uma imagem.</div>
            <div class="col-1 top10x text-right">
                <i class="fa fa-close" class="fa fa-close cursor"(click)="cancelCrop()"></i>
            </div>
        </div>
        <hr class="hrImageUpload">
        <div *ngIf="showStartImagePicker || imageUploadError">
            <div class="row">
                <div class="col text-center" style="padding-top: 10%">
                    <i class="fa fa-image fa-5x" style="color: #aaa; font-size: 100pt;"></i>
                </div>
            </div>
            <div class="row">
                <div class="col text-center" style="padding-top: 20px">
                    <div class="upload-btn-wrapper">
                        <button class="btn-upload">Selecionar uma foto do computador</button>
                        <input type="file" (change)="fileChangeEvent($event)" />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showStartImagePicker && !imageUploadError">
            <div class="row">
                <div class="col"></div>
                <div class="col" style="max-height: 500px; min-width: 600px;">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="maintainAspectRatio"
                        [aspectRatio]="aspectRatio"
                        [resizeToWidth]="resizeToWidth"
                        format="outputFormat"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div>
                <div class="col"></div>
            </div>
            <hr class="hrImageUpload">
            <div class="row">
                <div class="col text-center padd">
                    <div class="upload-btn-wrapper">
                        <button class="btn-upload" (click)="crop()">Próximo</button>
                        <button class="btn-upload" (click)="cancelCrop()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>