import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Image } from 'src/app/menu/shared/image.model';
import { ServiceConfig } from 'src/_config/services.config';

@Component({
  selector: 'image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent extends FieldType {
  // Uploader
  public showUploader = false;
  public companyImage: any = './assets/images/establishment.png';
  public imageCropped: string;

  reset() {
    this.companyImage = './assets/images/establishment.png';
    this.imageCropped = null;
  }

  public open() {
      this.showUploader = true;
  }

  public getImageSource() {
    this.reset();

    if (this.model.Image && this.model.Image.ImageOriginal) {
      return this.model.Image.ImageOriginal;
    }

    if (this.model.ImageId) {
      this.companyImage = ServiceConfig.STATIC_CONTENT_ENDPOINT
        .replace('{company}',this.model.CompanyId)
        .replace('{content}',this.model.ImageId);
    }

    return this.companyImage;
  }

  public onShowSidebarChange(showUploader: boolean) {
      this.showUploader = showUploader;
  }

  public croppedImage(base64image: string) {
      this.imageCropped = base64image;
      console.log(base64image);

      this.model.Image = new Image();
      this.model.Image.ImageOriginal = this.imageCropped;
  }
}
