import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationResponse } from 'src/app/validators/validation-response';
import { Account } from 'src/models/account/account.model';
import { AccountForm } from 'src/models/forms/account-form.model';
import { ValidationResult } from 'src/models/validations/validation-result.model';
import { AccountService } from 'src/providers/account.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountsComponent implements OnInit {

  public accounts: Account[] = [];
  public account: Account = new Account();

  public form = new FormGroup({});
  public columns: any;
  public fieldsConfig: any;

  public display: boolean = false;
  public errorMessage: string;
  showEmailModal: boolean;

  constructor(private service: AccountService) {
    this.fieldsConfig = new AccountForm().model;

    this.columns = [
      { field: 'Email', header: 'E-mail' },
      { field: 'Role', header: 'Papel' },
      { field: 'Active', header: 'Status' }
    ];
  }

  ngOnInit(): void {
    this.getAccounts();
  }

  add() {
    this.account = new Account();
    this.open(this.account);
  }

  open(account: Account) {
    this.display = true;
    this.account = JSON.parse(JSON.stringify(account));
  }

  onCancelled(isCancelled: boolean) {
    this.display = !isCancelled;
    this.errorMessage = '';
  }

  onSubmitted(account: Account) {
    this.errorMessage = '';

    if (account.Id) {
      this.updateAccount(account);
      return;
    }

    this.newAccount(account);
  }

  getCustomValues(field: string, value: any) {
    if (!field && !value) {
      return value;
    }

    if (field && value && field !== 'Active') {
      return value;
    }

    return field === 'Active' && value
      ? 'Ativo'
      : 'Inativo';
  }

  openEmailModal(account: Account) {
    event.stopPropagation();
    this.showEmailModal = true;
    this.account = account;
  }

  confirmCompanyEmailSend() {
    this.service.confirmEmailSend(this.sendCompanyEmail.bind(this));
  }

  confirmAccountEmailSend() {
    this.service.confirmEmailSend(this.sendAccountEmail.bind(this));
  }

  private sendCompanyEmail() {
    this.showEmailModal = false;
    this.service.sendSignUpEmail(this.account.Email);
  }

  private sendAccountEmail() {
    this.showEmailModal = false;
    this.service.sendRecoverPasswordEmail(this.account);
  }

  private getAccounts() {
    this.service.get()
      .then((_: Account[]) => {
        this.accounts = _;
      });
  }

  private newAccount(account: Account) {
    this.service.add(account)
      .then((_: ValidationResult) => {
        this.getResponse(_);
      });
  }

  private updateAccount(account: Account) {
    this.service.update(account)
      .then((_: ValidationResult) => {
        this.getResponse(_);
      });
  }

  private getResponse(_: ValidationResult) {
    if (!_.Success) {
      this.errorMessage = ValidationResponse.GetResponse(_.Type);
      return;
    }

    this.display = false;
    this.getAccounts();
  }
}
