<nav class="order-history">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page"><b>PEDIDOS</b></li>
    </ol>
    <!-- <div class="text-right">
        <button type="button" class="btn btn-secondary btn-sm" (click)="enableFilter = !enableFilter">
            {{ enableFilter ? 'Esconder' : 'Mostrar' }} filtros
        </button>
    </div> -->
    <p-table #dt [columns]="columns" [value]="orders" [paginator]="true" [rows]="20" [responsive]="true" selectionMode="single">
        <!-- <ng-template pTemplate="caption">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </ng-template> -->
        <ng-template pTemplate="header" let-columns>
            <tr class="text-center">
                <th *ngFor="let col of columns | slice:1" [ngSwitch]="col.field" [pSortableColumn]="col.field">
                    <span *ngSwitchCase="'OrderDate'">
                        {{ col.header }}
                    </span>
                    <span *ngSwitchCase="'Customer'">{{ col.header }}</span>
                    <span *ngSwitchCase="'DeliveryPlace'">{{ col.header }}</span>
                    <span *ngSwitchCase="'Status'">{{ col.header }}</span>
                    <span *ngSwitchCase="'TotalPrice'">{{ col.header }}</span>
                    <p-sortIcon [field]="col.field" ariaLabel="Clique para ordenar"
                            ariaLabelDesc="Ordem descendente" ariaLabelAsc="Orderm ascendente"></p-sortIcon>
                </th>
                <th class="action-buttons">
                    <span>Ações</span>
                </th>
            </tr>
            <tr *ngIf="enableFilter">
                <th *ngFor="let col of columns | slice:1" [ngSwitch]="col.field">
                    <p-multiSelect *ngSwitchCase="'Status'" [options]="status" defaultLabel="Todos"
                        [style]="{ width: '100%' }" (onChange)="filterStatus($event.value, dt)"></p-multiSelect>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" (click)="showOrder(rowData)">
                <td
                    class="text-center"
                    *ngFor="let col of columns | slice:1"
                    [ngSwitch]="col.field">
                    <span *ngSwitchCase="'OrderDate'">{{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm' }}</span>
                    <span *ngSwitchCase="'Customer'">{{ rowData[col.field].Name }}</span>
                    <span *ngSwitchCase="'DeliveryPlace'">{{ rowData[col.field]?.Local }} {{ rowData[col.field]?.Locator }}</span>
                    <span *ngSwitchCase="'Status'">
                        <span *ngFor="let stt of rowData[col.field]; let i=last;" >
                            <span *ngIf="i">{{ stt?.Description }}</span>
                        </span>
                    </span>
                    <span *ngSwitchCase="'TotalPrice'">{{ rowData[col.field] | currency: 'BRL' }}</span>
                </td>
                <td class="action-buttons">
                    <button class="btn btn-sm btn-light" (click)="print(rowData)">
                        <i class="fa fa-print"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</nav>