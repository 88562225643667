import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    AfterViewInit,
    NgZone
} from '@angular/core';
import { ValidationResult } from '@foodra/core';
import {
    ConfirmationService,
    MessageService
} from 'primeng';
import { BoardService } from './board.service'
import { Subscription } from 'rxjs/internal/Subscription';
import { take } from 'rxjs/operators';
import { ValidationResponse } from 'src/app/validators/validation-response';
import { OrderService } from 'src/providers/order.service';
import { PopoutService } from 'src/services/pop-out/pop-out.service';
import { PopoutData } from 'src/services/pop-out/pop-out.tokens';
import { ReceiptComponent } from '../receipt/receipt.component';
declare var printJS: any;

@Component({
    templateUrl: 'board.component.html',
    styleUrls: ['board.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BoardComponent implements OnInit, OnDestroy, AfterViewInit {

    private readonly _completed: number  = 4;

    // Modal Dialog Properties.
    showModalCancelOrder = false;
    orderToCancel;

    // Orders
    public orders: any;
    public orderReceipt: any;

    // Socket.IO
    private isAlive;
    private orderIntervalId: NodeJS.Timeout;
    private isSocketConnected: boolean;
    private onNewOrderSubject: Subscription;
    private onOrderUpdatedSubject: Subscription;

    private receiptStyles: string;
    autoPrintEnabled: boolean = false;

    constructor(
        private boardService: BoardService,
        private confirmationService: ConfirmationService,
        private orderService: OrderService,
        private messageService: MessageService,
        private popoutService: PopoutService,
        private zone: NgZone) {
            this.boardService.isConnected.subscribe((isConnected: boolean) => {
                this.isSocketConnected = isConnected;
                clearInterval(this.orderIntervalId);
                this.orderIntervalId = undefined;

                if (isConnected) {
                    this.getOpenOrders();
                } else {
                    this.setIntervalGetOrders();
                }
            });
        }

    ngOnInit() {
        this.getReceiptStyles();
    }

    ngAfterViewInit() {
        this.getOpenOrders();
        this.connect();
    }

    ngOnDestroy() {
        this.onNewOrderSubject.unsubscribe();
        this.onOrderUpdatedSubject.unsubscribe();
        clearInterval(this.isAlive);
        clearInterval(this.orderIntervalId);
        this.orderIntervalId = undefined;
    }

    /**
     * Event that will fire when leaving page.
     */
    canDeactivate() {
        this.ngOnDestroy();
        return true;
    }

    private setIntervalGetOrders() {
        this.orderIntervalId = setInterval(() => {
            this.getOpenOrders();
        }, 30000);
    }

    private getOpenOrders() {
        this.orderService.getOpenOrders()
            .subscribe((orders: any) => {
                this.orders = orders;
            });
    }

    private connect() {
        this.boardService.connect();
        this.onNewOrderSubject = this.boardService.onNewOrder.subscribe(receivedOrder => {
            console.log('new order received.');
            this.onOrderReceived(receivedOrder);
        });

        this.onOrderUpdatedSubject = this.boardService.onOrderUpdate.subscribe((receivedOrder: any) => {
            console.log('updated order received.');
            this.onOrderReceived(receivedOrder);
        });
    }

    /**
     * Receives an update order data to show on the board.
     *
     * @param receivedOrder The received order.
     */
    private onOrderReceived(receivedOrder: any): void {
        const order = JSON.parse(receivedOrder);
        if (this.isDuplicated(order.OrderId)) {
            this.updateOrders(order);
            return;
        }

        this.orders.push(order);
        this.orderService.checkHasOpenOrders(this.orders);
    }

    /**
     * Remove an object (order) from a given array (of orders).
     * @param objectToRemove Object to Remove
     * @param arrayToRemoveFrom Array where you want to remove the object.
     */
    removeOrders(objectToRemove, arrayToRemoveFrom): void {
        let index = arrayToRemoveFrom.indexOf(objectToRemove, 0);

        if (index > -1) {
            arrayToRemoveFrom.splice(index, 1);
        }
    }

    /**
     * Confirm order.
     * @param order
     */
    changeOrderStatus(order): void {
        this.orderService.updateStatus(order.OrderId)
            .then((_: ValidationResult) => {
                if (_.Success) {
                    const index = this.orders.indexOf(order);
                    const updatedOrder = <any>_.Data;
                    this.orders[index] = updatedOrder;
                    this.orderService.checkHasOpenOrders(this.orders);
                } else {
                    const errorMessage: string = ValidationResponse.GetResponse(_.Type, _.Data);
                    this.notify('Ops!', errorMessage, 'warn', 'bottom-center');
                }
            },
            error => console.log(error)
        );
    }

    /**
     * Reject and remove the order from the list of orders.
     * @param order Order to reject
     */
    rejectOrder(order) {
        this.orderService.rejectOrder(order.OrderId)
            .then(res => {
                if (res) {
                    this.removeOrders(order, this.orders);
                    this.orderService.checkHasOpenOrders(this.orders);
                }
            },
            error => console.log(error)
        );
  }

    cancelOrder(refund: boolean = false) {
      this.orderService.cancelOrder(this.orderToCancel.OrderId, refund)
        .then(res => {
            if (res) {
              const order = this.orders.find(_ => _.OrderId == this.orderToCancel.OrderId);
              if (order) {
                this.removeOrders(order, this.orders);
              }
              this.notify('Sucesso!', 'O cancelamento foi realizado com sucesso!', 'success');
              this.closeCancelModal();
            }
          },
          error => console.log(error)
        );
    }

    closeCancelModal() {
      this.orderToCancel = null;
      this.showModalCancelOrder = false;
    }

    /**
     * Clean finished orders.
     */
    cleanFinishedOrders() {
        this.orders.forEach(_ => {
            const len = _.Status.length - 1;

            if (_.Status[len].Code == this._completed) {
                this.removeOrders(_, this.orders);
            }
        });
    }

    /**
     * Display order.
     * @param order
     */
    showOrder(data: any) {
        this.boardService.showOrder(data.order);
    }

    /**
     * Confirm order rejection.
     * @param order Order
     */
    confirm(order) {
        this.confirmationService.confirm({
            message: 'Uma taxa poderá ser cobrada da loja. Tem certeza que deseja rejeitar esse pedido?',
            accept: () => {
                this.rejectOrder(order);
            }
        });
    }

    confirmCancelOrder(order) {
      this.confirmationService.confirm({
        message: 'Tem certeza que deseja cancelar esse pedido? Ao clicar em "Sim", selecione como gostaria de realizar o cancelamento.',
        accept: () => {
          this.showModalCancelOrder = true;
          this.orderToCancel = order;
        }
      });
    }

    print(order: any): void {
        this.orderReceipt = order;

        if (this.autoPrintEnabled) {
            this.zone.onStable
                .pipe(take(1))
                .subscribe(() => {
                    printJS({
                        printable: 'receipt',
                        type: 'html',
                        css: this.getStylesUrls(),
                        style: this.receiptStyles,
                        scanStyles: true
                    });
                });

            return;
        }

        const popoutData = <PopoutData> {
            id: order.OrderId,
            title: 'Receipt',
            param: order
        };

        this.popoutService.open(ReceiptComponent, popoutData, component => component.name === name, true);
    }

      public checkStatusChange(response: any) {
          if (response.rejected) {
            this.confirm(response.order);
            return;
          }

          if (response.cancelled) {
            this.confirmCancelOrder(response.order);
            return;
          }

          this.changeOrderStatus(response.order);
      }

      private isDuplicated(orderId: string): boolean {
          const existingOrders = this.orders.filter(order => order.OrderId == orderId)
          return existingOrders.length > 0;
      }

      private updateOrders(order: any) {
        let orderToUpdate = this.orders.find((currentOrder: any) => currentOrder.OrderId === order.OrderId);
        let index = this.orders.indexOf(orderToUpdate);
        this.orders[index] = order;
    }

      private notify(title: string, message: string, severity: string, key?: string, sticky: boolean = true): void {
        this.messageService.add({
            key: !key ? 'bottom-left' : key,
            severity: severity,
            summary: title,
            detail: message,
            sticky: sticky
          });
      }

    private getStylesUrls(): string[] {
        const stylesUrls: string[] = [];
        document.querySelectorAll('link').forEach(htmlElement => {
            if (htmlElement.rel === 'stylesheet') {
                const absoluteUrl = new URL(htmlElement.href).href;
                stylesUrls.push(absoluteUrl);
            }
        });

        document.querySelectorAll('style').forEach(htmlElement => {
            htmlElement.innerText.search
        });

        console.log(stylesUrls);
        return stylesUrls;
    }

    private getReceiptStyles(): void {
        document.querySelectorAll('style').forEach(htmlElement => {
            if (htmlElement.innerText.includes('.order-receipt')) {
                this.receiptStyles = htmlElement.innerText;
            }
        });
    }
}
