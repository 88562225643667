import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterStatus',
    pure: false
})

export class FilterOrderStatusPipe implements PipeTransform {
    transform(items: any[], status: number): any {
        if (!items || !status)
            return items;

        let newItems = [];
        let len = 0;
        items.forEach(item => {
            len = item.Status.length - 1;

            if (item.Status[len].Code == status)
                newItems.push(item);
        })

        return newItems;
    }
}