import { InjectionToken } from '@angular/core';
import { DomPortalOutlet } from '@angular/cdk/portal';

export interface PopoutData {
    id: string,
    title: string,
    param: any
}

export const POPOUT_MODAL_DATA = new InjectionToken<PopoutData>('POPOUT_MODAL_DATA');

export enum PopoutModalName {
    'employerDetail' = 'EMPLOYER_DETAIL',
    'customerDetail' = 'CUSTOMER_DETAIL'
}

export const POPOUT_MODALS: PopoutModal[] = [];

export interface PopoutModal {
    id: string,
    windowInstance: Window,
    componentInstance: any,
    outlet: DomPortalOutlet
}