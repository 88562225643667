import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [MenuComponent],
    providers: [],
    exports: [MenuComponent],
    imports: [RouterModule]
})

export class MenuModule { }