import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule, TooltipModule } from 'primeng';
import { ImageUploaderComponent } from './image-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, SidebarModule, ImageCropperModule, TooltipModule],
    exports: [ImageUploaderComponent],
    declarations: [ImageUploaderComponent],
    providers: [],
})
export class ImageUploaderModule { }
