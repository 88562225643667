import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';

// App
import { AppComponent } from './app.component';

// Main
import { MainModule  } from '../main/main.module';

import { LoginModule } from './login/login.module';

// Authentication
import { AuthService  } from './auth/auth.service';
import { AuthGuard    } from './auth/auth-guard.service';
import { LoginRouting } from './login/login.routing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppHttpInterceptor } from '../interceptor/AppHttpInterceptor';
import { RefreshTokenService } from '../services/refresh-token.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { LocalPushNotificationsService } from '../services/local-push-notification.service';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { CoreModule } from '@foodra/core';
import { servicesProviders } from '@foodra/core/providers';
import { MessageService } from 'primeng';

@NgModule({
  declarations: [
      AppComponent
  ],
  imports: [
      CoreModule,
      FormsModule,
      HttpClientModule,
      NgbModule,
      RouterModule,
      //JsonpModule,

      // Main
      MainModule,

      // Pages
      LoginModule,

      // Routes
      RouterModule.forRoot(LoginRouting),
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireMessagingModule,
      ReactiveFormsModule,
      FormlyModule.forRoot(),
      FormlyBootstrapModule
  ],
  providers: [
      AuthService,
      RefreshTokenService,
      AuthGuard,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AppHttpInterceptor,
        multi: true
      },
      LocalPushNotificationsService,
      MessageService,
      servicesProviders
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
