import { NgModule } from '@angular/core';
import { ContentComponent } from './content.component';
import { RouterModule } from '@angular/router';

// Pages.
import { DashboardModule } from '../../app/dashboard/dashboard.module';
import { PageNotFoundModule } from '../../not-found.module';

// UI-Components
import { OrderHistoryModule } from '../../app/order/order-history/order-history.module';
import { BoardModule } from '../../app/order/board/board.module';
import { MenuModule }     from '../../app/menu/menu.module';

// Pages Routing.
import { MainRouting } from '../../main/main.routing';
import { CompaniesModule } from 'src/pages/companies/companies.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule, ToastModule } from 'primeng';

const modules = [
    RouterModule.forChild(MainRouting),
    DashboardModule,
    CompaniesModule,
    BoardModule,
    OrderHistoryModule,
    MenuModule,
    PageNotFoundModule,
    ConfirmDialogModule,
    SharedModule,
    ToastModule
];

@NgModule({
    imports: [modules],
    exports: [ContentComponent],
    declarations: [ContentComponent]
})

export class ContentModule { }