<div class="board">
    <nav
        aria-label="breadcrumb"
        role="navigation">
        <ol class="breadcrumb">
            <li
                class="breadcrumb-item active"
                aria-current="page">
                <b>CONFIRMAR PEDIDOS</b>
            </li>
        </ol>
    </nav>

    <!-- <div class="text-right d-none d-lg-block">
        <div class="row">
            <div class="col-10 col-lg-11">
                <label>Imprimir automáticamente</label>
            </div>
            <div class="col-1">
                <p-inputSwitch [(ngModel)]="autoPrintEnabled"></p-inputSwitch>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-4 col-lg p-0">
            <order-card
                title="Novos"
                status="1"
                color="primary"
                [orders]="orders"
                (onStatusChange)="checkStatusChange($event)"
                (onPrint)="print($event)"
                (onShowOrder)="showOrder($event)">
            </order-card>
        </div>
        <div class="col-md-4 col-lg p-0">
            <order-card
                title="Em andamento"
                status="2"
                color="success"
                [orders]="orders"
                (onStatusChange)="checkStatusChange($event)"
                (onPrint)="print($event)"
                (onShowOrder)="showOrder($event)">
            </order-card>
        </div>
        <div class="col-md-4 col-lg p-0">
            <order-card
                title="Entregando"
                status="3"
                color="warning"
                [orders]="orders"
                (onStatusChange)="checkStatusChange($event)"
                (onPrint)="print($event)"
                (onShowOrder)="showOrder($event)">
            </order-card>
        </div>
        <div class="col-md-4 col-lg p-0">
            <order-card
                title="Concluídos"
                status="4"
                color="info"
                [orders]="orders">
            </order-card>
        </div>
    </div>

    <p-dialog
        header="Cancelar Pedido"
        modal="true"
        positionTop="70"
        [style]="{ 'width': '50vw' }"
        [(visible)]="showModalCancelOrder"
        [closable]='true'
        [dismissableMask]='true'
        [responsive]="true">
        <div>
            Gostaria realmente de cancelar o pedido de número {{ orderToCancel?.Transaction?.Tid }}?
        </div>
        <p-footer>
            <button
                class="btn btn-sm btn-primary"
                (click)="cancelOrder()">
                Sem Reembolso
            </button>
            <button
                *ngIf="orderToCancel?.Payment?.Online"
                class="btn btn-sm btn-primary"
                (click)="cancelOrder(true)">
                Com Reembolso
            </button>
            <button
                class="btn btn-sm btn-primary"
                (click)="closeCancelModal()">
                Cancelar Ação
            </button>
        </p-footer>
    </p-dialog>

    <div style="display: none">
        <order-receipt
            id="receipt"
            [(order)]="orderReceipt">
        </order-receipt>
    </div>
</div>
