import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MainComponent } from './main.component'

//// Main
import { ContentModule } from '../main-components/content/content.module';
import { MenuModule }     from '../main-components/menu/menu.module';
import { TopModule }     from '../main-components/top/top.module';
import { CanDeactivateGuard } from '../app/guard/candeactivate-guard.service';
import { AccountsModule } from 'src/pages/account/accounts.module';

const imports = [
  CommonModule,
  RouterModule,
  ContentModule,
  MenuModule,
  TopModule,
  AccountsModule
];

@NgModule({
  imports: [imports],
  declarations: [MainComponent],
  exports: [MainComponent],
  providers: [CanDeactivateGuard]
})
export class MainModule { }
