<div class="payable">
    <div>
        <ol class="breadcrumb">
            <li
                class="breadcrumb-item active"
                aria-current="page">
                <b>RECEBIMENTOS</b>
            </li>
        </ol>
    </div>

    <div *ngIf="!showLoader">
        <div class="text-center mb-3">
            Selecione o ano:
            <dropdown
                [items]="dropdownItems"
                [(selected)]="yearSelected"
                (onSelected)="onYearSelected($event)">
            </dropdown>
        </div>

        <div *ngIf="payables.length > 0">
            <div class="payable-notes mb-3">
                <div *ngFor="let note of notes">
                    <i class="fa fa-info-circle pr-2"></i>
                    <small>{{ note }}</small>
                </div>
            </div>

            <div class="payable-panel text-center">
                <p-panel
                    *ngFor="let payable of payables"
                    [toggleable]="true"
                    [collapsed]="true"
                    toggler="header"
                    (onAfterToggle)="toggle(payable, $event.collapsed)">
                    <p-header>
                        <div class="row">
                            <div class="col-1 text-left">
                                <i
                                    class="fa"
                                    [class.fa-caret-down]="!payable.collapsed"
                                    [class.fa-caret-right]="payable.collapsed"
                                    style="padding: 0px 12px 0px 5px;">
                                </i>
                            </div>
                            <div class="col-4 text-left">
                                <span class="ui-panel-title">
                                    Pedidos de {{ months[payable.Month-1] }}
                                </span>
                            </div>
                            <div class="col-3">
                                <b>Saldo: {{ payable.Total | currency: 'BRL' }}</b>
                            </div>
                            <div class="col-3">
                                <b>Status: </b>
                                <b
                                    [class.text-warning]="payable.Status == 0"
                                    [class.text-success]="payable.Status == 1">
                                    {{ status[payable.Status] }}
                                </b>
                            </div>
                        </div>
                    </p-header>

                    <div>
                        <p-table
                            [columns]="cols"
                            [value]="payable.Payables"
                            dataKey="PaymentDate">
                            <ng-template
                                pTemplate="header"
                                let-columns>
                                <tr>
                                    <th style="width: 3em"></th>
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="body"
                                let-rowData
                                let-expanded="expanded"
                                let-columns="columns">
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            [pRowToggler]="rowData">
                                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                        </a>
                                    </td>
                                    <td
                                        *ngFor="let col of columns"
                                        [ngSwitch]="col.field">
                                        <span *ngSwitchCase="'PaymentDate'">{{ rowData[col.field] | date: 'dd MMM (EEE)' }}</span>
                                        <span *ngSwitchCase="'Description'">{{ rowData[col.field] }}</span>
                                        <span *ngSwitchCase="'Total'">
                                            {{ rowData[col.field] | currency: 'BRL' }}
                                            <i
                                                class="fa fa-info-circle"
                                                tooltipPosition="top"
                                                pTooltip="Valor sem desconto da taxa de transferência bancária R$3,67.">
                                            </i>
                                        </span>
                                        <span
                                            *ngSwitchCase="'Status'"
                                            [class.text-warning]="rowData[col.field] == 0"
                                            [class.text-success]="rowData[col.field] == 1">
                                            {{ status[rowData[col.field]] }}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="rowexpansion"
                                let-rowData
                                let-columns="columns">
                                <tr>
                                    <td
                                        class="p-0"
                                        [attr.colspan]="columns.length + 1">
                                        <div class="row payable-transactions">
                                            <div class="col">
                                                <p-table
                                                    [columns]="subcols"
                                                    [value]="rowData['Transactions']"
                                                    dataKey="Tid">
                                                    <ng-template
                                                        pTemplate="header"
                                                        let-columns>
                                                        <tr>
                                                            <th *ngFor="let col of columns">
                                                                {{col.header}}
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template
                                                        pTemplate="body"
                                                        let-rowData
                                                        let-columns="columns">
                                                        <tr>
                                                            <td
                                                                *ngFor="let col of columns"
                                                                [ngSwitch]="col.field">
                                                                <span
                                                                    *ngSwitchCase="'Tid'"
                                                                    class="payable-link"
                                                                    (click)="open(rowData['Tid'])">
                                                                    {{ rowData[col.field] }}
                                                                </span>
                                                                <span *ngSwitchCase="'TransactionDate'">{{ rowData[col.field] | date: 'dd MMM' }}</span>
                                                                <span *ngSwitchCase="'GrossAmount'">{{ rowData[col.field] | currency: 'BRL' }}</span>
                                                                <span *ngSwitchCase="'NetAmount'">
                                                                    {{ rowData[col.field] | currency: 'BRL' }}
                                                                    <i
                                                                        *ngIf="rowData[col.field] < 0"
                                                                        class="fa fa-info-circle"
                                                                        tooltipPosition="top"
                                                                        pTooltip="Valores negativos podem significar estorno em data diferente da data do pedido.">
                                                                    </i>
                                                                </span>
                                                                <!-- <span *ngSwitchCase="'Status'">{{ status[rowData[col.field]] }}</span> -->
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-panel>
            </div>
        </div>
    </div>

    <div
        class="text-center mt-5"
        *ngIf="showLoader">
        <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </div>
    <div
        class="text-center mt-5"
        *ngIf="showBackground">
        <div class="p-4">
            <strong>Você ainda não possui pedidos para receber... =´(</strong>
        </div>
        <img src="../../../../assets/images/icon_sad_money.png" style="width: 18em;">
    </div>
</div>