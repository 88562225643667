<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-info nav-bar-width">
    <div class="row">
        <div class="col-sm">
            <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="collapse()">☰</button>
            <a class="navbar-brand" routerLink="/" routerLinkActive="active">
                <img src="../../assets/images/logo/white.png" alt="Foodra" class="company-logo" />
            </a>
        </div>
    </div>

    <div class="collapse navbar-collapse" id="navbar-collapse" [ngbCollapse]="!menuOpen">
        <ul class="nav navbar-nav mx-auto">
            <div ngbDropdown class="d-inline-block" *ngFor="let menu of menuList; let i=index" (click)="close(menu)">
                <li class="nav-item item-button">
                    <span class="nav-link cursor-pointer" [id]="i" *ngIf="!menu.childs" [routerLink]="menu.route">
                        <i [class]="menu.icon"></i>
                        {{ menu.name }}
                    </span>
                    <span class="nav-link cursor-pointer" [id]="i" ngbDropdownToggle *ngIf="menu.childs" [routerLink]="menu.route">
                        <i [class]="menu.icon"></i>
                        {{ menu.name }}
                    </span>
                </li>
                <li class="nav-item">
                    <div ngbDropdownMenu aria-labelledby="i" *ngIf="menu.childs" class="menu-open">
                        <button class="dropdown-item item-button" *ngFor="let menuChild of menu.childs" [routerLink]="menuChild.route" (click)="close(menuChild)">
                            {{ menuChild.name }}
                        </button>
                    </div>
                </li>
            </div>
        </ul>
    </div>

    <div *ngIf="company" ngbDropdown class="d-inline-block cursor-pointer pr-1 ml-auto" display="dynamic">
        <ul class="nav navbar-nav mr-auto">
            <li class="nav-item">
                <div class="btn btn-sm" id="dropdownOpening" ngbDropdownToggle
                    [class.btn-success]="isOpen"
                    [class.btn-danger]="!isOpen">
                    {{ company?.TradingName?.substring(0, 11) }}
                    <span class="badge badge-pill badge-top"
                        [class.badge-success]="isOpen"
                        [class.badge-danger]="!isOpen">{{ company.IsOpen ? 'Aberto' : 'Fechado' }}</span>
                </div>

                <div ngbDropdownMenu aria-labelledby="dropdownOpening">
                    <button class="dropdown-item" (click)="confirmChangeStoreStatus()">
                        {{ company.IsOpen ? 'Fechar' : 'Abrir' }} loja
                    </button>
                </div>
            </li>
        </ul>
    </div>

    <div ngbDropdown class="d-inline-block cursor-pointer" display="dynamic">
        <ul class="nav navbar-nav mr-auto">
            <li class="nav-item">
                <div class="btn btn-sm btn-outline-light" id="dropdownCompany" ngbDropdownToggle>
                    <i class="fa fa-gear"></i>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownCompany">
                    <div *ngIf="company">
                        <button ngbDropdownItem (click)="closeCompanyView()">
                            <i class="fa fa-close text-dark"></i>
                            Fechar visualização
                        </button>
                        <div class="dropdown-divider"></div>
                    </div>
                    <button ngbDropdownItem (click)="logout()">
                        <i class="fa fa-sign-out text-dark"></i>
                        Sair
                    </button>
                </div>
            </li>
        </ul>
    </div>
</nav>