// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAV1XqZ126ZCsNOIr15YGzS9Echwwjj_n0',
    authDomain: 'foodra-92aac.firebaseapp.com',
    databaseURL: 'https://foodra-92aac.firebaseio.com',
    projectId: 'foodra-92aac',
    storageBucket: 'foodra-92aac.appspot.com',
    messagingSenderId: '326312613833',
    appId: '1:326312613833:web:1ce978f6f683cb62'
  },
  endpoint: {
    api: 'http://localhost:52332',
    authentication: 'http://localhost:52332/Auth',
    socket_io: 'http://localhost:5000',
    static_content: 'http://fooderia.s3-sa-east-1.amazonaws.com/{company}/{content}',
    product_images: 'http://fooderia.s3-sa-east-1.amazonaws.com/{company}/product/{image}',
    address: 'https://viacep.com.br/ws/{cep}/json/'
  },
  key: {
    recaptcha: '6Le_Y4YUAAAAAM3B5mTNn8X1trDzVmLmXdf7JYDb'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
