export class Type {
    /// <summary>
    /// Get or set Type Code.
    /// </summary>
    public Code: string;

    /// <summary>
    /// Get or set Type Name.
    /// </summary>
    public Name: string;

    /// <summary>
    /// Get or set Type Icon.
    /// </summary>
    public Icon: string;
}