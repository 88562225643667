import { Injectable } from '@angular/core';
import {
    Company,
    IOrderService,
    ISocketIOService,
    SocketIOChannel
} from '@foodra/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class BoardService {

    private onNewOrderSubject = new Subject<any>();

    constructor(private socketIoService: ISocketIOService,
        private orderService: IOrderService) {
    }

    /**
     * Gets the socket.io company channel.
     */
    private get channel(): SocketIOChannel {
        const company: Company = <Company> JSON.parse(localStorage.getItem('company'));

        return <SocketIOChannel> {
            Id: company.CompanyId,
            Name: company.CompanyName
        };
    }

    /**
     * Gets when a new order is received.
     */
    get onNewOrder(): Observable<any> {
        return this.onNewOrderSubject;
    }

    /**
     * Gets when an order was updated.
     */
    get onOrderUpdate(): Observable<any> {
        return this.socketIoService.on<any>('order-updated');
    }

    /**
     * Gets whether socket.io is connected to the server.
     */
    get isConnected(): Observable<boolean> {
        return this.socketIoService.isConnected;
    }

    /**
     * Connects to the socket.io server.
     */
    connect(): void {
        this.socketIoService.connect(this.channel);
    }

    /**
     * Workaround to receive new orders on the board.
     *
     * @param order The order to update the board.
     */
    addNewOrder(order: any): void {
        this.onNewOrderSubject.next(order);
    }

    /**
     * Shows order detail in a modal.
     *
     * @param order The order to be shown.
     */
    showOrder(order: any) {
        this.orderService.open(order);
    }
}