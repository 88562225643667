import { Component, Input, Output, ViewEncapsulation, EventEmitter, OnDestroy } from '@angular/core';
import { Subcategory } from '../../shared/subcategory.model';
import { Product } from '../../shared/product.model';
import { ConfirmationService } from 'primeng/primeng';
import { Item } from '../../shared/item.model';
import { Guid } from '../../../utils/guid';

@Component({
    selector: 'addon',
    templateUrl: './addon.component.html',
    styleUrls: ['./addon.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AddonComponent implements OnDestroy {

    @Input() product: Product;
    @Input() mode: string;
    @Input() tab: any;
    @Input() isDialogOpen: boolean;
    @Output() productChange = new EventEmitter();
    @Output() tabChange = new EventEmitter();

    showSubcategoryOrder : boolean = false;
    showAddSubcategory : boolean = false;
    isRequiredSubcategory: boolean = false;
    showAddItem : boolean = false;
    subcategory : Subcategory = new Subcategory();
    item : Item = new Item();
    addonMode: string;

    private scIndex: number;
    private itemIndex: number;

    error = false;
    msgError = '';

    infoMin = 'Ex.: Zero ou sem valor, não obriga usuário do app a escolher um item.'
    infoMax = 'Máx. de itens que um usuário do app poderá selecionar para essa categoria. Vazio = ilimitado'
    infoCategoryName = 'Nome do grupo de complementos. Ex.: Selecione o molho';

    lastCategoryIndex: number = 0;
    shouldAddNewSubcategory: boolean;

    dropdownOptions = [
        { label: 'Desativar', icon: 'fa fa-toggle-on', command: () => { this.toggleSubcategoryStatus() } },
        { label: 'Duplicar', icon: 'fa fa-clone', command: () => { this.clone() } },
        { label: 'Excluir', icon: 'fa fa-trash', command: () => { this.confirmDelete(this.subcategory, 'subcategory') } },
    ];

    /**
     * Whether product has subcategories.
     */
    get hasSubcategories(): boolean {
        return this.product.Subcategories && this.product.Subcategories.length > 0;
    }

    /**
     * Whether to show the header buttons.
     */
    get showHeader(): boolean {
        return !this.showSubcategoryOrder &&
            !this.showAddSubcategory &&
            this.hasSubcategories &&
            !this.showAddItem;
    }

    /**
     * Whether to show the footer buttons.
     */
    get showFooter(): boolean {
        return this.hasSubcategories && !this.showAddItem;
    }

    /**
     * Whether to show the panel.
     */
    get showPanel(): boolean {
        return !this.showSubcategoryOrder &&
            !this.showAddSubcategory &&
            !this.showAddItem;
    }

    constructor(private confirmationService: ConfirmationService) { }

    ngOnDestroy(): void {
        this.tab.select('tab-product');
    }

    toggleSubcategorySort(): void {
        this.showSubcategoryOrder = !this.showSubcategoryOrder;
    }

    /**
     * Toggle panel component.
     *
     * @param event Tab event
     */
    onTabToggle(event: any): void {
        const tabIndex = event.index;
        const subcategory = this.product.Subcategories[tabIndex];
        subcategory.Collapse = !subcategory.Collapse;
    }

    public getSubcategory(mode: string, scIndex?: number, subcategory?: Subcategory): void {
        this.addonMode = mode;
        this.showAddSubcategory = true;

        if (scIndex != null)
            this.scIndex = scIndex;

        if (mode == 'INS') {
            this.subcategory = new Subcategory();
            this.subcategory.IsActive = true;
            this.isRequiredSubcategory = false;
        }

        if (subcategory) {
            this.subcategory = JSON.parse(JSON.stringify(subcategory));
            this.isMinMaxValid(this.subcategory);
        }
    }

    changeSubcategory(): void {
        if (this.addonMode == 'INS') {
            this.addSubcategory();
        } else {
            this.updateSubcategory();
        }
    }

    public addSubcategory(): void {
        if (this.product.Subcategories == null)
            this.product.Subcategories = new Array<Subcategory>();

        this.subcategory.SubcategoryId = Guid.newGuid();
        this.subcategory.Collapse = false;
        this.product.Subcategories.push(this.subcategory);
        this.showAddSubcategory = !this.showAddSubcategory;
        this.addonMode = '';
    }

    private updateSubcategory(): void {
        this.product.Subcategories[this.scIndex] = this.subcategory;
        this.showAddSubcategory = !this.showAddSubcategory;
        this.addonMode = '';
    }

    cancelChange(): void {
        this.subcategory = new Subcategory();
        this.showAddSubcategory = !this.showAddSubcategory;
        this.addonMode = '';
    }

    /**
     * Confirm whether a subcategory or item should be deleted.
     *
     * @param obj Subcategory or Item
     * @param type Type: Item / Subcategory
     * @param sIndex Subcategory Index
     */
    confirmDelete(obj, type, sIndex?): void {
        event.stopPropagation();

        this.confirmationService.confirm({
            message: 'Você tem certeza que desejar excluir?',
            header: 'Confirmação',
            icon: 'fa fa-question-circle',
            accept: () => {
                if (type == 'subcategory')
                    this.deleteSubcategory(obj);

                if (type == 'item')
                    this.deleteItem(obj, sIndex);
            },
            reject: () => { }
        });
    }

    /**
     * Delete a subcategory.
     * @param subcategory 
     */
    private deleteSubcategory(subcategory): void {
        let index = this.product.Subcategories.indexOf(subcategory, 0);

        // Remove category.
        if (index > -1) {
            this.product.Subcategories.splice(index, 1);
        }
    }

    /**
     * Fill Max Value with same value as Min Value is greater then Max.
     * @param subcategory 
     */
    fillMax(subcategory): void {
        if(subcategory.Min > 0 && subcategory.Max < subcategory.Min) {
            subcategory.Max = subcategory.Min;
        }

        this.isMinMaxValid(subcategory);
    }

    /**
     * Check if Min value is greater than Max Value.
     * @param subcategory
     */
    isMinMaxValid(subcategory): void {
        this.isRequiredSubcategory = true;

        if (!subcategory.Min || subcategory.Min <= 0) {
            subcategory.Min = 0;
            this.isRequiredSubcategory = false;
        }
    }

    /* ---- Items ----*/

    /**
     * Add a new Item to subcategory (addon).
     */
    private addItem(): void {
        const item = this.item;
        const subcategory = this.product.Subcategories[this.scIndex];

        item.ItemId = Guid.newGuid();
        item.IsActive = true;

        if (subcategory.Items == null) {
            subcategory.Items = new Array<Item>();
        }

        // Create new instance of object.
        const newItem = JSON.parse(JSON.stringify(item));
        subcategory.Items.push(newItem);

        // Close and clean object.
        this.cancelAddItem();
    }

    /**
     * Updates an item.
     */
    private updateItem(): void {
        this.product.Subcategories[this.scIndex].Items[this.itemIndex] = this.item;
        this.cancelAddItem();
    }

    /**
     * Delete item from subcategory.
     *
     * @param item Item to be deleted.
     * @param sIndex Subcategory Index.
     */
    private deleteItem(item, sIndex): void {
        let index = this.product.Subcategories[sIndex].Items.indexOf(item, 0);

        // Remove category.
        if (index > -1) {
            this.product.Subcategories[sIndex].Items.splice(index, 1);
        }
    }

    addOrUpdateItem() {
        if (this.itemIndex != null) {
            this.updateItem();
            return;
        }

        this.addItem();
    }

    /**
     * Displays the add item section.
     *
     * @param subcategoryIndex Subcategory index.
     * @param itemIndex Item index.
     */
    displayAddItem(subcategoryIndex: number, itemIndex?: number): void {
        this.showAddItem = true;
        this.scIndex = subcategoryIndex;
        this.product.Subcategories[subcategoryIndex].Collapse = false;

        if (itemIndex != null) {
            this.itemIndex = itemIndex;

            const item = this.product.Subcategories[subcategoryIndex].Items[itemIndex];
            this.item = JSON.parse(JSON.stringify(item));
        }
    }

    /**
     * Close "Add Item" and clean objects.
     */
    cancelAddItem(): void {
        this.showAddItem = false;
        this.scIndex = null;
        this.itemIndex = null;
        this.item = new Item();
    }

    /* ---- Complete Button ----*/
    /**
     * Complete product insertion.
     */
    complete(): void {
        // Save, close modal and return to tabindex 0.
        if(this.mode == 'INS') {
            // Create a new instance of object.
            this.product = JSON.parse(JSON.stringify(this.product));
        }

        this.productChange.emit(this.product);
        this.tabChange.emit(false);
        this.tab.select('tab-product');
    }

    checkRequiredSubcategory() {
        this.subcategory.Min = 0;

        if (this.isRequiredSubcategory) {
            this.subcategory.Min = 1;
        }

        this.isMinMaxValid(this.subcategory);
    }

    hasSubcategoryItems(subcategory: any): boolean {
        return subcategory.Items && subcategory.Items.length > 0;
    }

    hasRequiredSubcategory(subcategory: Subcategory): boolean {
        return subcategory && subcategory.Min > 0;
    }

    saveOrAddNewSubcategory(): void {
        if (this.shouldAddNewSubcategory) {
            this.getSubcategory('INS');
            this.shouldAddNewSubcategory = null;
            return;
        }

        this.complete();
        this.shouldAddNewSubcategory = null;
    }

    public clean(): void {
        this.showSubcategoryOrder = false;
        this.showAddSubcategory = false;
        this.isRequiredSubcategory = false;
        this.showAddItem = false;
        this.subcategory = new Subcategory();
        this.item = new Item();
        this.addonMode = ''
        this.scIndex = 0;
        this.error = false;
        this.msgError = '';
    }

    onItemDragStart(cIndex: number, iIndex: number, item: any) {

    }

    onItemDropSuccess(cIndex: number, iIndex: number, item: any) {

    }

    handleDropdown(subcategory: Subcategory, btn) {
        event.stopPropagation();

        this.subcategory = subcategory;

        btn.model[0].label = this.subcategory.IsActive ? 'Desativar' : 'Ativar';
        btn.model[0].icon = this.subcategory.IsActive ? 'fa fa-toggle-off' : 'fa fa-toggle-on';
    }

    clone(): void {
        event.stopPropagation();
        let category = <Subcategory> JSON.parse(JSON.stringify(this.subcategory));

        category.SubcategoryId = Guid.newGuid();

        category.Items.map(_ => {
            _.ItemId = Guid.newGuid();
        });

        this.product.Subcategories.push(category);
    }

    toggleSubcategoryStatus(): void {
        event.stopPropagation();
        this.subcategory.IsActive = !this.subcategory.IsActive;
    }

    /**
     * Format currency for inline editor.
     * @param num
     */
    private formatCurrency(num) {
        let x = 0;
        let cents;

        if (typeof(num) == 'string') {
            num = parseFloat(num.replace(',','.'))
        }
        if (num > 0 && num < 1 ) {
            return num.toFixed(2).replace('.',',');
        }
        if(num < 0) {
            num = Math.abs(num);
            //x = 1; removed negative values.
        }
        if(isNaN(num)) num = '0';
            cents = Math.floor((num*100+0.5)%100);

        num = Math.floor((num*100+0.5)/100).toString();

        if(cents < 10) cents = '0' + cents;
            for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
                num = num.substring(0,num.length-(4*i+3))+'.'
                    + num.substring(num.length-(4*i+3));

        let ret = num + ',' + cents;
        if (x == 1) ret = ' - ' + ret;
        return ret;
    }
}
