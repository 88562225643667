import { Type } from "@angular/core";
import { ModalPosition, ModalType } from "@foodra/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/primeng";


/**
 * This service is responsible for modal notifications on the application.
 */
export abstract class IModalService {
    /**
     * Shows toast message for the user.
     *
     * @param message The message to be displayed.
     * @param title The title to be displayed.
     * @param type The type of the message.
     * @param position The position of the message on the screen.
     * @param sticky True to sticky it on the screen, otherwise false.
     * @param clearMessages True to clear existing messages on the screen, otherwise false.
     * @param time Sets the toast display time.
     */
    abstract toast(message: string, title?: string, type?: ModalType, position?: ModalPosition,
        sticky?: boolean, clearMessages?: boolean, time?: number): void;

    /**
     * Shows a dynamic dialog.
     *
     * @param component The component with the content.
     * @param config The dialog configurations to display the content.
     *
     * @returns The object reference when dialog is closed.
     */
    abstract dialog(component: Type<any>, config?: DynamicDialogConfig): DynamicDialogRef

    /**
     * Shows a confirm dialog with accept and reject buttons.
     *
     * @param message The message to be displayed.
     * @param acceptFunction The function to be executed after accepting.
     * @param params The params to be sent.
     * @param rejectFunction The function to be executed after rejecting.
     * @param acceptLabel The accept label text.
     * @param rejectLabel The reject label text.
     */
    abstract confirm(message: string, title: string, acceptFunction: (data?: any) => void, params?: any,
        rejectFunction?: () => void, acceptLabel?: string, rejectLabel?: string): void;
}