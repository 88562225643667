export class State {
    /// <summary>
    /// Get or set State Code.
    /// </summary>
    public Code: string;

    /// <summary>
    /// Get or set State Name.
    /// </summary>
    public Name: string;
}