import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CommonModule } from '@angular/common';
import { TooltipModule, FieldsetModule } from 'primeng/primeng';
import { CoreModule } from '@foodra/core';

@NgModule({
    imports: [CommonModule, CardModule, ChartModule, TooltipModule, FieldsetModule, CoreModule],
    declarations: [DashboardComponent],
    providers: [],
    exports: [DashboardComponent],
})

export class DashboardModule { }