import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class RefreshTokenService {
  public refreshTokenSubject: Subject<any> = new Subject<any>();

  public publish(value: any) {
    this.refreshTokenSubject.next(value);
  }
}