import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { FilterOrderStatusPipe } from 'src/app/order/board/filter-order-status.pipe';
import { ServiceConfig } from '../_config/services.config';

@Injectable()
export class OrderService {

    private url: string = ServiceConfig.API_ENDPOINT;
    private hasOpenOrdersSubject = new Subject<boolean>();
    private filterOrderStatusPipe: FilterOrderStatusPipe;

    constructor(private http: HttpClient) {
        this.filterOrderStatusPipe = new FilterOrderStatusPipe();
    }

    get hasOpenOrders(): Observable<boolean> {
        return this.hasOpenOrdersSubject;
    }

    getOrders() {
        const company = JSON.parse(localStorage.getItem('company'));

        return this.http.get(this.url + '/Order/Get/' + company.CompanyId).pipe(
            map(res => { return res; }));
    }

    getOpenOrders(companyId?: string) {
        if (!companyId) {
            const company = JSON.parse(localStorage.getItem('company'));
            companyId = company.CompanyId;
        }

        return this.http.get(this.url + '/Order/GetOpenOrders/' + companyId).pipe(
            map((orders: any[]) => {
                this.checkHasOpenOrders(orders);
                return orders;
            }));
    }

    updateStatus(orderId: string) {
        return this.http.put(this.url + '/Order/UpdateStatus?orderId=' + orderId, {}).toPromise();
    }

    rejectOrder(orderId: string) {
        return this.http.put(this.url + '/Order/RejectOrder?orderId=' + orderId, {}).toPromise();
    }

    cancelOrder(orderId: string, refund: boolean) {
      return this.http.put(`${this.url}/Order/Cancel?orderId=${orderId}&refund=${refund}`, {}).toPromise();
    }

    /**
     * If has open orders to be confirmed, it emits a boolean to the listener
     * of the subject, where it manages the company notification alerts.
     *
     * @param orders The list of orders to be checked.
     */
    checkHasOpenOrders(orders: any): void {
        const ordersTest = JSON.parse(JSON.stringify(orders));
        const pendingStatus: number = 1;
        const openOrders: any[] = this.filterOrderStatusPipe.transform(ordersTest, pendingStatus);
        const hasOpenOrders = openOrders && openOrders.length > 0
            ? true
            : false;

        console.log('has open orders', openOrders);
        this.hasOpenOrdersSubject.next(hasOpenOrders);
    }
}
