import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Dashboard } from '@foodra/core/shared/models/dashboard/dashboard';
import { StoreService } from 'src/providers/store.service';

@Component({
    selector: 'app-dashboard-component',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation : ViewEncapsulation.None
})

export class DashboardComponent implements OnInit {
    public dashboard = <Dashboard> { };
    companyId: string = null;
    totalConnections: number = 0;
    showLoader = true;

    constructor(private storeService: StoreService) {
        this.storeService.totalConnections.subscribe((total: number) => {
            this.totalConnections = total;
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.getDashboard();
        }, 700);
    }

    getDashboard(date?: Date) {
        if (!date) {
            date = new Date();
        }

        if (localStorage.getItem('company')) {
            this.companyId = JSON.parse(localStorage.getItem('company')).CompanyId;
        }

        this.storeService.getDashboard(date, this.companyId)
            .then((dashboard: Dashboard) => {
                if (dashboard) {
                    this.dashboard = dashboard;
                    this.showLoader = false;
                }
            });
    }
}
