
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { ApiService } from 'src/services/api.service';

export interface SupportTable {
    value: any;
    label: string;
}

@Injectable()
export class SupportTablesService {
    constructor(private apiService: ApiService) { }

    getObject<T>(endpoint: string) {
        return from(this.apiService.get<T[]>(endpoint));
    }

    get<T>(endpoint: string, label?: string, value?: string, filterExpression?: (value: any, index?: number, Array?: any[]) => boolean) {
        return from(this.apiService.get<T[]>(endpoint)
            .then((_: T[]) => {
                const supportTable: SupportTable[] = [];
                let object = _;

                if (filterExpression) {
                    object = object.filter(filterExpression);
                }

                if (label) {
                    object.forEach(__ => {
                        supportTable.push({
                            label: __[label],
                            value: __[value]
                        });
                    });

                    return supportTable;
                }

                object.forEach((__: any) => {
                    supportTable.push({
                        label: __.Name ? __.Name : __.Description,
                        value: __
                    });
                });

                return supportTable;
            }));
    }
}