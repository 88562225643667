<nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page"><b>DASHBOARD</b></li>
    </ol>
</nav>

<div class="content">
    <div
        class="text-center mt-5"
        *ngIf="showLoader">
        <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </div>

    <div
        class="dashboard"
        *ngIf="!showLoader">
        <div class="text-center">
            <div>
                <label>Selecione o período:</label>
            </div>
            <div>
                <date-picker (onSelected)="getDashboard($event)"></date-picker>
            </div>
        </div>
        <p-fieldset
            *ngIf="!companyId"
            legend="Geral Foodra">
            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card header="Total Empresas" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Companies.Total }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Ativas" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Companies.Active }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Inativas" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Companies.Inactive }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Abertas" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Companies.Opened }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card header="Fechadas" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Companies.Closed }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Conectadas" styleClass="text-center">
                        <div>
                            <h3>{{ totalConnections }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Total Clientes" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Customers.Total }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>
        </p-fieldset>
        <p-fieldset legend="Clientes no mês">
            <div class="row">
                <div class="col-md-4 pb-2">
                    <p-card header="Clientes" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Customers.TotalInMonth }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-4">
                    <p-card header="Ticket médio" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Customers.AverageTicket | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>
        </p-fieldset>

        <p-fieldset legend="Pedidos no mês">
            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card header="Pedidos" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Total }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Concluídos" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Completed }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Recusados" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Rejected }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Cancelados" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Cancelled }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card header="Em andamento" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.InProgress }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Pagamento Online" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Online }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3">
                    <p-card header="Pagamento Offline" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Transactions.Offline }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>
        </p-fieldset>

        <p-fieldset legend="Financeiro no mês">
            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card header="Total Transacionado" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.TransactionTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card>
                        <div class="row">
                            <div class="col ui-card-title text-center">
                                Receita Bruta
                                <i
                                    class="fa fa-info-circle"
                                    tooltipPosition="top"
                                    pTooltip="Pedidos Concluídos">
                                </i>
                            </div>
                        </div>
                        <div class="text-center">
                            <h3>{{ dashboard.Financial.GrossTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card>
                        <div class="row">
                            <div class="col ui-card-title text-center">
                                Receita Líquida
                                <i
                                    class="fa fa-info-circle"
                                    tooltipPosition="top"
                                    pTooltip="Pedidos Concluídos">
                                </i>
                            </div>
                        </div>
                        <div class="text-center">
                            <h3>{{ dashboard.Financial.NetTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Receita Foodra" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.AppGrossFee | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 pb-2">
                    <p-card>
                        <div class="row">
                            <div class="col ui-card-title text-center">
                                Descontos do App
                                <i class="fa fa-info-circle" tooltipPosition="top"
                                    pTooltip="Descontos dado pelo app serão reembolsados descontando a comissão do app.
                                        Mais informações no Guia de Uso."></i>
                            </div>
                        </div>
                        <div class="text-center">
                            <h3>{{ dashboard.Financial.AppDiscounts | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Tot. Em Andamento" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.InProgressTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Total Cancelados" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.CancelledTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Total Recusados" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.RejectedTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card
                        header="Total Online"
                        styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.OnlineTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3 pb-2">
                    <p-card header="Total Offline" styleClass="text-center">
                        <div>
                            <h3>{{ dashboard.Financial.OfflineTotal | currency: 'BRL' }}</h3>
                        </div>
                    </p-card>
                </div>
            </div>
        </p-fieldset>
    </div>
</div>
