export class Account {
    public Id: string;

    public Email: string;

    public Role: string;

    public LoginAttempt: number;

    public Active: boolean;
}