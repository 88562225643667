<nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page"><b>CARDÁPIO</b></li>
        <i class="fa fa-info-circle p-1" tooltipPosition="top"
          pTooltip="Clique e arraste as abas de categorias e/ou produtos para alterar a ordem."></i>
    </ol>
</nav>

<div
    class="text-center mt-5"
    *ngIf="showLoader">
    <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
</div>

<div
    *ngIf="!showLoader"
    class="ui-grid ui-grid-pad ui-grid-responsive">
    <div class="row pull-right btnAddCategoria">
        <button
            class="btn btn-primary"
            [class.pulse]="!hasCategories"
            type="button"
            (click)="showDialogCategoria(null, null)">
            <i class="fa fa-plus"></i> <span>  Nova Categoria</span>
        </button>
    </div>

    <div
        class="text-center mt-5"
        *ngIf="!hasCategories && !showLoader">
        <div class="p-5">
            <strong>Ah não! Você não possui produtos cadastrado no cardápio... =´(</strong>
        </div>
        <img src="../../assets/icon_sad.png" style="width: 10em;">
    </div>

    <div class="ui-grid-row">
        <div class="col-md-2" dnd-sortable-container [sortableData]="menu?.Categories" [dropZones]="['container-dropZone']">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let category of menu?.Categories; let i = index"
                dnd-sortable [sortableIndex]="i" (click)="toggle($event, category, i)"
                pTooltip="Clique e arraste para organizar." tooltipPosition="right" (onDragSuccess)="onCategoryDragSuccess()">
                <div class="row">
                    <div class="col-9 order-panel">
                        <span class="ui-panel-title">{{i + 1}} - {{ category.Name }}</span>
                    </div>
                    <div class="col-2">
                        <i class="fa fa-arrows" aria-hidden="true"></i>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="col-md">
      <p-panel [toggleable]="false" [collapsed]="category.Collapse" *ngFor="let category of menu?.Categories; let cIndex = index">
        <p-header>
            <div class="row" draggable="true">
                <div class="col-8 col-sm" (click)="toggle($event, category, cIndex)" pTooltip="Clique para expandir" tooltipPosition="top" style="white-space: nowrap">
                    <i class="fa" style="padding: 0px 12px 0px 5px;"
                    [class.fa-caret-down]="!category.Collapse" [class.fa-caret-right]="category.Collapse"></i>
                    <i class="fa fa-circle success" *ngIf="category.IsActive" pTooltip="Ativo" tooltipPosition="left"></i>
                    <i class="fa fa-circle danger" *ngIf="!category.IsActive" pTooltip="Inativo" tooltipPosition="left"></i>
                    <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px; padding-left: 10px">
                        {{ category.Name }}
                    </span>
                </div>
                <div class="col-4 col-md-3">
                    <div class="pull-right">
                        <p-splitButton icon="fa fa-pencil" [model]="cOptions" [menuStyleClass]="'align-split-button-menu'" #btn (onClick)="showDialogCategoria(category, cIndex)"
                        (onDropdownClick)="getItem(cIndex, null, btn)"></p-splitButton>
                    </div>
                </div>
            </div>
        </p-header>

        <div>
          <table class="table table-hover">
            <thead class="thead-inverse">
              <tr>
                <th width="1em"></th>
                <th width="1em"></th>
                <th>Nome</th>
                <th width="15%">Preço</th>
                <th width="10%">Status</th>
                <!--<th>Color</th>-->
                <th width="12%">Ações</th>
              </tr>
            </thead>
            <tbody dnd-sortable-container [sortableData]="category.Products">
              <tr *ngIf="!hasCategoryProducts(category)">
                <td colspan="6">Crie novos produtos ou arraste e solte produtos existentes aqui.</td>
              </tr>
              <tr *ngFor="let product of category.Products; let i = index"
                dnd-sortable [sortableIndex]="i" draggable="true" [dragData]="product"
                dnd-droppable (onDropSuccess)="onProductDragSuccess(cIndex)" [dropZones]="['products']"
                (click)="showDialogProduto(product, i, cIndex, 'UPD')">
                <td class="text-center">
                    <i
                        class="fa fa-arrows"
                        pTooltip="Clique e arraste para ordenar."
                        tooltipPosition="top">
                    </i>
                </td>
                <td class="text-center">
                    <i
                        *ngIf="product?.ImageId"
                        class="fa fa-camera"
                        pTooltip="Esse produto possui foto."
                        tooltipPosition="top">
                    </i>
                    <i
                        *ngIf="hasSubcategories(product)"
                        class="fa fa-list-ul"
                        [class.text-danger]="hasRequiredSubcategories(product)"
                        [pTooltip]="hasRequiredSubcategories(product)
                            ? 'Esse produto possui complementos obrigatórios.'
                            : 'Esse produto possui complementos.'"
                        tooltipPosition="top">
                    </i>
                </td>
                <td>
                  <div>{{ product.Name }}</div>
                  <div style="font-size:xx-small; color:gray; word-break: break-all;">{{product.Description}}</div>
                </td>
                <td><span *ngIf="product.Price > 0">{{ product.Price | currency: 'BRL' }}</span></td>
                <td>
                  <i class="fa fa-circle success" *ngIf="product.IsActive" pTooltip="Ativo" tooltipPosition="left"></i>
                  <i class="fa fa-circle danger" *ngIf="!product.IsActive" pTooltip="Inativo" tooltipPosition="left"></i>
                </td>
                <!--<td>{{ t.color }}</td>-->
                <td>
                  <div class="pull-right align-split-buttons">
                    <p-splitButton icon="fa fa-pencil" [model]="pOptions" [menuStyleClass]="'align-split-button-menu'" pTooltip="Editar" tooltipPosition="left" #btn (onClick)="showDialogProduto(product, i, cIndex, 'UPD')"
                      (onDropdownClick)="getItem(cIndex, i, btn)"></p-splitButton>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6">
                  <div class="pull-right">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        [class.pulse]="!hasCategoryProducts(category)"
                        (click)="showDialogProduto(null, null, cIndex, 'INS')">
                      <i class="fa fa-plus"></i><span>  Novo Produto</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </p-panel>
    </div>
  </div>

    <p-dialog
        header="Categoria dos Produtos"
        modal="true"
        [(visible)]="displayCategoria"
        [style]="{ 'min-width': '45vw' }"
        [contentStyle]="{'overflow':'hidden'}">
        <form
            #categoriaForm="ngForm"
            (ngSubmit)="categoriaForm.value">
            <div class="row">
                <div class="col">
                    <label>Adicione uma categoria para seus produtos.</label>
                </div>
            </div>
            <div class="row">
                <div
                    class="form-group col-md-10 col-sm-12"
                    [ngClass]="{
                        'has-success': categoriaForm.controls.Categoria?.valid,
                        'has-danger': !categoriaForm.controls.Categoria?.valid && categoriaForm.controls.Categoria?.touched
                    }">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Ex.: Pizzas, Massas, Refrigerantes"
                        [(ngModel)]="categoria"
                        name="Categoria"
                        maxlength="30"
                        required />
                    <div
                        class="form-control-feedback"
                        [hidden]="categoriaForm.controls.Categoria?.valid || !categoriaForm.controls.Categoria?.touched">
                        Por favor, digite uma categoria.
                    </div>
                </div>
                <div class="col-md-2 col-sm-12">
                    <button
                        class="btn btn-primary"
                        type="submit"
                        (click)="addCategory()"
                        [disabled]="!categoriaForm.controls.Categoria?.valid"> OK
                    </button>
                </div>
            </div>
        </form>
    </p-dialog>

  <!-- Product Modal -->
    <p-dialog
        [header]="product?.Name ? product?.Name : 'Produto'"
        positionTop="50"
        responsive="true"
        [style]="{ 'min-width': '60vw', heigh: '80vh' }"
        [(visible)]="displayProduto" modal="true"
        [contentStyle]="{'overflow-x':'hidden'}"
        (onShow)="onShowProduct()">
        <main-product
            #productModal
            [mode]="mode"
            [(product)]="product"
            [(isDialogOpen)]="displayProduto"
            (productChange)="saveProduct($event)"
            (tabChange)="closeProductModal($event)"
            (tabSelect)="saveProduct($event)">
        </main-product>
    </p-dialog>
</div>