import { Address } from "../local/address.model";
import { City } from "../local/city.model";
import { Country } from "../local/country.model";
import { Geo } from "../local/geo.model";
import { Spot } from "../local/spot.model";
import { State } from "../local/state.model";
import { Type } from "../local/type.model";

export class Localization {
    public Type: Type = new Type();

    public Spot: Spot = new Spot();

    public Address: Address = new Address();

    public City: City = new City();

    public State: State = new State();

    public Country: Country = new Country();

    public Geo: Geo = new Geo();
}