import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { AuthService } from '../auth/auth.service';
import { RegexValidation } from '../utils/regex-validation';
import { ValidationResponse } from '../validators/validation-response';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {
    public error: any;
    public loading: boolean;
    public isAuth: boolean = true;
    private siteKey = '6Le_Y4YUAAAAAM3B5mTNn8X1trDzVmLmXdf7JYDb';

    form = new FormBuilder().group({
        username: new FormControl('',  [Validators.required, Validators.pattern(RegexValidation.Email)]),
        password: new FormControl('', Validators.required),
    });
    recoverPasswordSucceed: boolean;

    constructor(private authService: AuthService,
        private reCaptchaV3Service: ReCaptchaV3Service) { }

    login() {
        this.loading = true;
        const username = this.form.controls['username'].value;
        const password = this.form.controls['password'].value;

        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
                this.authService.login(username, password, token)
                    .subscribe(() => {
                        this.loading = false;
                    }, (err) => {
                        if (err.status == 0) {
                            this.error = ValidationResponse.GetResponse('SERVER_UNAVAILABLE');
                            this.loading = false;
                            return;
                        }

                        this.error = err.error.error;
                        this.loading = false;
                    });
            }, {
                useGlobalDomain: false // optional
            });
    }

    toggleAuth() {
        this.isAuth = !this.isAuth;
        this.error = '';
    }

    recoverPassword() {
        this.loading = true;
        const email = this.form.controls['username'].value;

        this.authService.recoverPassword(email)
            .then((response: any) => {
                if (response.Success) {
                    this.recoverPasswordSucceed = true;
                    this.error = '';
                } else {
                    this.recoverPasswordSucceed = false;
                    this.error = ValidationResponse.GetResponse(response.Type);
                }
                this.loading = false;
            });
    }
}
