import { Type } from "@angular/core";
import {
    Confirmation,
    ConfirmationService,
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
    MessageService
} from "primeng/primeng";
import { IModalService } from "./modal.service.interface";
import { ModalPosition } from "src/core/shared/enums/modal-position.enum";
import { ModalType } from "src/core/shared/enums/modal-type.enum";

/**
 * @inheritdoc
 */
export class ModalService extends IModalService {

    constructor(private messageService: MessageService,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService) {
        super();
    }

    /**
     * @inheritdoc
     */
    toast(message: string, title: string = null, type: ModalType = ModalType.Success,
        position: ModalPosition = ModalPosition.Bottom, sticky: boolean = false,
        clearMessages: boolean = true, time: number = null): void {

        if (clearMessages) {
            this.messageService.clear();
        }

        this.messageService.add({
            key: position,
            severity: type,
            summary: title,
            detail: message,
            sticky: sticky,
            life: time
        });
    }

    /**
     * @inheritdoc
     */
    dialog(component: Type<any>, config: DynamicDialogConfig = null): DynamicDialogRef {
        if (!config) {
            config = <DynamicDialogConfig> {
                width: '70%'
            };
        }
        return this.dialogService.open(component, config);
    }

    /**
     * @inheritdoc
     */
    confirm(message: string, title: string, acceptFunction: (data?: any) => void, params: any = null,
        rejectFunction?: () => void, acceptLabel: string = 'Sim', rejectLabel: string = 'Não'): void {
        const confirmation: Confirmation = {
            message: message,
            header: title,
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            accept: () => acceptFunction(params)
        };

        if (rejectFunction) {
            confirmation.reject = rejectFunction;
        }

        this.confirmationService.confirm(confirmation);
    }
}