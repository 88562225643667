import { Injectable } from '@angular/core';
import { IModalService, ModalType, ValidationResult } from '@foodra/core';
import { Account } from 'src/models/account/account.model';
import { ApiService } from 'src/services/api.service';

@Injectable()
export class AccountService {
    constructor(private apiService: ApiService, private modalService: IModalService) { }

    get(): Promise<Account[]> {
        return this.apiService.get<Account[]>('Account/Get');
    }

    getById(accountId: string): Promise<Account> {
        return this.apiService.get<Account>(`Account/Get/${accountId}`);
    }

    add(account: Account): Promise<any> {
        return this.apiService.post<Account>(`Account/Add`, account);
    }

    update(account: Account): Promise<any> {
        return this.apiService.put<Account>(`Account/Update`, account);
    }

    async sendSignUpEmail(email: string): Promise<void> {
        try {
            const response = await this.apiService.post<ValidationResult>(`Account/sendSignUpEmail?email=${email}`, {});

            if (!response.Success) {
                this.modalService.toast('E-mail não enviado!', 'Ops!', ModalType.Error);
                return;
            }

            this.modalService.toast('E-mail enviado com sucesso!');
        } catch (error) {
            this.modalService.toast('Ocorreu um erro ao tentar enviar o e-mail!', 'Ops!', ModalType.Error);
        }
    }

    sendRecoverPasswordEmail(account: Account): Promise<any> {
        const endpoint = account.Role == 'Admin'
            ? 'ForgetAdminPassword'
            : account.Role == 'Company'
                ? 'ForgetCompanyPassword'
                : 'ForgetPassword';

        return this.apiService.post<string>(`Account/${endpoint}?email=${account.Email}`, {});
    }

    confirmEmailSend(acceptFunction: () => void): void {
        this.modalService.confirm('Tem certeza que desejar enviar e-mail?', '', acceptFunction);
    }
}