<ngb-tabset #tab="ngbTabset" [activeId]="'tab-product'">
    <ngb-tab title="Produto" id="tab-product">
        <ng-template ngbTabContent>
            <product [product]="product" [mode]="mode" [tab]="tab"></product>
        </ng-template>
    </ngb-tab>
    <ngb-tab [disabled]="product.Name?.length < 1" id="tab-addon" *ngIf="product.Name">
        <ng-template ngbTabTitle>Complementos</ng-template>
        <ng-template ngbTabContent>
            <addon #addon [product]="product" [mode]="mode" [tab]="tab"
                (tabChange)="changeTab($event)" (productChange)="changeProduct($event)">
            </addon>
        </ng-template>
    </ngb-tab>
</ngb-tabset>