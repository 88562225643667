import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    SharedModule,
    PanelModule,
    TooltipModule,
    CheckboxModule,
    InputTextModule,
    ConfirmDialogModule,
    ConfirmationService,
    AccordionModule,
    SplitButtonModule,
    InputSwitchModule
} from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddonComponent } from './addon.component';
import { DndModule } from 'ng2-dnd';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CommonModule } from '@angular/common';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        PanelModule,
        TooltipModule,
        DndModule.forRoot(),
        CheckboxModule,
        InputTextModule,
        ConfirmDialogModule,
        CurrencyMaskModule,
        NgbButtonsModule,
        AccordionModule,
        SplitButtonModule,
        InputSwitchModule
    ],
    exports: [AddonComponent],
    declarations: [AddonComponent],
    providers: [ConfirmationService],
})
export class AddonModule { }
