import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface TabType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'dynamic-forms',
  templateUrl: './dynamic-forms.component.html',
  styleUrls: ['./dynamic-forms.component.scss']
})
export class DynamicFormsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() model: any;
  @Input() fieldsConfig: FormlyFieldConfig[] = null;
  @Input() tabsConfig: TabType[] = [];
  @Input() errorMessage: string = '';

  @Output() onCancelled = new EventEmitter<boolean>();
  @Output() onSubmitted = new EventEmitter<any>();

  public activeTab: number = 0

  constructor() { }

  ngOnInit(): void {
    console.log('init');
    console.log(this.model);

    // if (this.model) {
    //   this.fieldsConfig = [];
    //   for (let [key, value] of Object.entries(this.model)) {
    //     console.log(`${key} : ${value} - type: ${typeof(value)}`);
    //     if (typeof(value) !== 'object') {
    //       this.fieldsConfig.push({
    //         key: key,
    //         type: 'input'
    //       });
    //     }
    //   };
    // }
  }

  onSubmit(model: any) {
    if (this.form.valid) {
      this.onSubmitted.emit(model);
    }
  }

  close() {
    if (this.fieldsConfig) {
      this.fieldsConfig[0].options.resetModel();
    }

    this.onCancelled.emit(true);
  }
}
