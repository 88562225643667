import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoutService } from 'src/services/pop-out/pop-out.service';
import { POPOUT_MODAL_DATA } from 'src/services/pop-out/pop-out.tokens';
import { ReceiptComponent } from './receipt.component';

@NgModule({
    imports: [CommonModule],
    exports: [ReceiptComponent],
    declarations: [ReceiptComponent],
    providers: [{
        provide: POPOUT_MODAL_DATA,  // That's the token we defined previously
        useClass: PopoutService,  // That's the actual service itself
      }],
    entryComponents:[ReceiptComponent]
})
export class ReceiptModule { }
