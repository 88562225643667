import { Routes } from '@angular/router';
import { PayableComponent } from '@foodra/core/components/financial/payable/payable.component';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { CanDeactivateGuard } from 'src/app/guard/candeactivate-guard.service';
import { MenuComponent } from 'src/app/menu/menu.component';
import { BoardComponent } from 'src/app/order/board/board.component';
import { OrderHistoryComponent } from 'src/app/order/order-history/order-history.component';
import { AccountsComponent } from 'src/pages/account/accounts/accounts.component';
import { CompaniesComponent } from 'src/pages/companies/companies/companies.component';
import { AuthGuard } from '../app/auth/auth-guard.service';
import { MainComponent } from './main.component';

export const MainRouting: Routes = [
    {
        path: '', component: MainComponent, canActivate: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'payable', component: PayableComponent },
            { path: 'companies', component: CompaniesComponent },
            { path: 'accounts', component: AccountsComponent },
			{ path: 'order-history', component: OrderHistoryComponent },
			{ path: 'board', component: BoardComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'menu', component: MenuComponent }
        ]
    }
]