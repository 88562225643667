export class ValidationResponse {
    public static GetResponse(validationType: string, data: any = null): string {
        switch (validationType) {
            case 'USER_ALREADY_EXISTS':
            return 'O usuário já possui uma conta cadastrada.';

            case 'USER_CPF_INVALID':
            return 'Por favor, insira um CPF válido.';

            case 'USER_DOES_NOT_EXIST':
            return 'Usuário não encontrado.';

            case 'EMAIL_ALREADY_EXISTS':
            return 'E-mail já utilizado. Por favor, utilize um outro.';

            case 'USER_CPF_INVALID':
            return 'CPF inválido.';

            case 'TRANSACTION_REFUSED':
            return 'Transação recusada, verifique se os dados de seu cartão de crédito estão corretos.';

            case 'STORE_CLOSED':
            return 'A loja está fechada no momento. Verifique o horário de funcionamento.';

            case 'MENU_HAS_NO_PRODUCTS':
            return 'O cardápio não possui produtos. Favor, adicione um produto.';

            case 'MENU_HAS_PRODUCT_WITH_NO_PRICE':
            return 'O cardápio possui produtos sem valor. Favor, adicione um valor aos produtos.';

            case 'INTERNAL_ERROR':
            return 'Um erro ocorreu ao realizar a operação.';

            case 'WH_INVALID_MINUTES':
            return 'Os minutos precisam ser múltiplos de 5.';

            case 'INVALID_CLOSING_TIME':
            return 'Para abrir a loja após a hora de fechamento, é necessário alterar o horário de fechamento para mais tarde.';

            case 'SERVER_UNAVAILABLE':
            return 'Não foi possível se conectar ao servidor.';

            case 'IMAGE_REQUIRED':
            return `É necessário ter uma imagem cadastrada.`;

            case 'COMPANY_IS_NOT_ACTIVE':
            return `No momento a loja encontra-se em análise. Qualquer dúvida entre em contato conosco.`;

            case 'WH_INVALID_RANGE':
            return `Já existe outro horário cadastrado para esse intervalo.`;

            case 'RECORD_NOT_FOUND':
            return `Dado não encontrado.`;

            case 'ORDER_CANCELED_OR_REJECTED':
            return `O pedido foi cancelado ou rejeitado. Favor, verifique no histórico de pedidos.`;

            case 'ORDER_STATUS_CHANGE_DELAY':
            return `É necessário aguardar ${data}s antes de alterar o status novamente.`;

            default:
            return 'Ocorreu um erro.';
          }
    }
}