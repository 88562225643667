import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IModalService, ModalType } from '@foodra/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private modalService: IModalService) { }

    canActivate() {
        if (localStorage.getItem('access_token')) {
            // WARNING: Dot not put a router here, or the browser will crash.
            return true;
        }

        // Not signed in so redirects to signin page.
        this.authService.logout();
        this.router.navigate(['/login']);
        if (this.router.url != '/') {
            this.modalService.toast('Você não possui permissão para acessar essa página!',
                'Sem permissão!', ModalType.Error);
        }
        return false;
    }
}