import { DynamicDialogRef } from "primeng/primeng";
import { PayableReport } from "src/core/shared/models/reports/payable/payable-report";

export abstract class IPayableService {
    /**
     * Gets the payable report.
     *
     * @param companyId The company id.
     * @param year The year to query.
     *
     * @returns The payable report promise.
     */
    abstract get(companyId?: string, year?: number): Promise<PayableReport[]>;

    /**
     * Opens an order transaction.
     *
     * @param transactionId The transaction id.
     *
     * @returns A dynamic dialog reference.
     */
    abstract open(transactionId: string): Promise<DynamicDialogRef>;
}