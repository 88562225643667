import { Component, Inject, Input } from '@angular/core';
import { OrderProduct } from '@foodra/core/shared/models/order/order-product';
import { PopoutData, POPOUT_MODAL_DATA } from 'src/services/pop-out/pop-out.tokens';

@Component({
    selector: 'order-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent {

    @Input() order: any;

    get location(): any {
        return this.order && this.order.DeliveryPlace.Location
            ? this.order.DeliveryPlace.Location
            : null;
    }

    get address(): string {
        return this.location && this.location.Address
            ? `${this.location.Address.Name}, ${this.location.Address.Number}, ${this.location.Address.District},
            ${this.convertPostalCode(this.location.Address.PostalCode)}, ${this.location.Address.City},
            ${this.location.Address.State}`
            : '';
    }

    get deliveryPlace(): any {
        return this.order.DeliveryPlace;
    }

    get subtotal(): number {
        let sum = 0;
        if (this.order) {
            for (const product of this.order.Products) {
                sum += product.Subtotal;
            }
        }
        return sum;
    }

    get totalWithoutDiscount() {
        return this.subtotal + this.deliveryPlace.Price;
    }

    constructor(@Inject(POPOUT_MODAL_DATA) data: PopoutData) {
        this.order = data.param;
    }

    private convertPostalCode(postalCode: number): string {
        let convertedPostalCode = this.padLeft(postalCode, 8);

        const firstPart = convertedPostalCode.substring(0, 5);
        const secondPart = convertedPostalCode.substring(5, convertedPostalCode.length);
        const separator = '-';

        return firstPart.concat(separator, secondPart);
    }

    shouldShowProductSubtotal(product: OrderProduct): boolean {
        return product && (product.Quantity > 1 || product.Subcategories && product.Subcategories.length > 0);
    }

    private padLeft(num: number, size: number): string {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
}
