import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Image } from '../../shared/image.model';

@Component({
    selector: 'product',
    templateUrl: 'product.component.html',
    styles: [`
        /* Popup Rows */
        .padd { padding: 10px; }

        /* Image Mouse Cursor */
        img { cursor: pointer; }
        .img { text-align: center; }

        .text { font-size: 14px; }

        .imgCircle {
            border-radius: 5%;
            height: 75px;
            width: 95px;
        }

        .tab-content {
            max-height: 75vh;
            min-width: 75vw;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        .tab-content-size { margin: 0px; }

        .ui-sidebar-mask { z-index: 0 !important; }
        .ui-sidebar-full { overflow: scroll; }
        .top10x { top: 10px; }
    `],
    encapsulation: ViewEncapsulation.None
})

export class ProductComponent implements OnInit {
    @Input() product;
    @Input() mode;
    @Input() tab;

    // Uploader
    public showSidebar = false;
    public productImage = "";

    readonly imgThumbUrl = 'https://image.freepik.com/free-icon/restaurant-cutlery-circular-symbol-of-a-spoon-and-a-fork-in-a-circle_318-61086.jpg';
    constructor() { }

    ngOnInit() { }

    /**
     * Change tab.
     */
    change(): void {
        this.tab.select('tab-addon');
    }

    public openImageUploader() {
        this.showSidebar = true;
    }

    public onShowSidebarChange(showSidebar) {
        this.showSidebar = showSidebar;
    }

    public croppedImage(image: any) {
        this.productImage = image;
        this.product.Image = new Image();
        this.product.Image.ImageOriginal = this.productImage;
    }

    removeImage(): void {
        this.product.ImageId = null;
        this.product.Image = null;
    }
}