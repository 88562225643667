<div class="container">
    <p-toast key="top-right" position="top-right" baseZIndex="10000"></p-toast>
    <p-toast key="top-left" position="top-left" baseZIndex="10000"></p-toast>
    <p-toast key="top-center" position="top-center" baseZIndex="10000"></p-toast>
    <p-toast key="middle-right" position="middle-right" baseZIndex="10000"></p-toast>
    <p-toast key="middle-left" position="middle-left" baseZIndex="10000"></p-toast>
    <p-toast key="middle-center" position="middle-center" baseZIndex="10000"></p-toast>
    <p-toast key="bottom-right" position="bottom-right" baseZIndex="10000"></p-toast>
    <p-toast key="bottom-left" position="bottom-left" baseZIndex="10000"></p-toast>
    <p-toast key="bottom-center" position="bottom-center" baseZIndex="10000"></p-toast>
    <router-outlet></router-outlet>
    <p-confirmDialog></p-confirmDialog>
</div>