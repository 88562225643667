export class Item {
    
    public ItemId: string;
    public Name: string;
    public Description: string;
    public Price: number;
    public IsActive: boolean;
    
    public error: boolean;
    public errorMessage: string;
}