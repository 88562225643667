import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@foodra/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../app/auth/auth.service';
import { StoreService } from '../../providers/store.service';
import { TopComponent } from './top.component';

@NgModule({
    imports: [BrowserModule, CoreModule, NgbModule, RouterModule, FormsModule],
    exports: [TopComponent],
    declarations: [TopComponent],
    providers: [AuthService, StoreService]
})

export class TopModule { }