import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { MainProductComponent } from './main-product.component';
import { ProductModule } from './product/product.module';
import { AddonModule } from "./addon/addon.module";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, ProductModule, AddonModule],
    exports: [MainProductComponent],
    declarations: [MainProductComponent],
    providers: [],
})
export class MainProductModule { }
