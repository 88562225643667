import { Injectable } from '@angular/core';
import {
    IApiService,
    IOrderService,
    IStorageService,
    PayableReport,
    StorageKey
} from '@foodra/core';
import { DynamicDialogRef } from 'primeng/primeng';
import { IPayableService } from './payable.service.interface';

@Injectable()
export class PayableService implements IPayableService {

    constructor(private apiService: IApiService,
        private orderService: IOrderService,
        private storage: IStorageService) { }

    /**
     * @inheritdoc
     */
    async get(companyId?: string, year?: number): Promise<PayableReport[]> {
        companyId = companyId || await this.storage.get(StorageKey.CompanyId);
        return this.apiService.get<PayableReport[]>(`payablereport/get?companyId=${companyId}&year=${year}`);
    }

    /**
     * @inheritdoc
     */
    async open(transactionId: string): Promise<DynamicDialogRef> {
        const companyId = await this.storage.get(StorageKey.CompanyId);
        const order: any = await this.orderService.getByTid(companyId, transactionId);

        return this.orderService.open(order);
    }
}
