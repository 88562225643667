import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { ReCaptchaV3Service, ScriptService } from 'ngx-captcha';

@NgModule({
  imports: [
      CommonModule, RouterModule, FormsModule, ReactiveFormsModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [ReCaptchaV3Service, ScriptService]
})
export class LoginModule { }
