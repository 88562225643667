<div class="addon p-3">
    <!-- Header Buttons -->
    <div
        *ngIf="showHeader"
        class="row mb-2">
        <div class="col-md-12 text-right">
            <div>
                <button class="btn btn-primary btn-sm" *ngIf="hasSubcategories"
                        (click)="toggleSubcategorySort()">
                    <i class="fa fa-arrows"></i> Ordenar
                </button>
                <button
                    class="btn btn-sm btn-primary ml-2"
                    (click)="getSubcategory('INS')">
                    <i class="fa fa-plus"></i> Nova categoria
                </button>
            </div>
        </div>
    </div>

    <div class="text-center pb-3" *ngIf="!hasSubcategories">
        <div class="row pb-3">
            <div class="col">
                <b>Esse produto possui complementos?</b>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="subcategory-required"
                    [(ngModel)]="shouldAddNewSubcategory" (change)="saveOrAddNewSubcategory()">
                    <label ngbButtonLabel class="btn-primary">
                        <input ngbButton type="radio" [value]="true"> Sim, adicionar
                        <i class="fa fa-plus"></i>
                    </label>
                    <label ngbButtonLabel class="btn-success">
                        <input ngbButton type="radio" [value]="false"> Não, concluir
                        <i class="fa fa-floppy-o"></i>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <!-- New Category -->
    <div *ngIf="showAddSubcategory">
        <form #subcategoryForm="ngForm" (ngSubmit)="addSubcategory()">
            <div>
                <div class="row pb-3">
                    <div class="col">
                        <b>Informe se essa categoria é obrigatória para realizar o pedido</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-center">
                        <div class="btn-group btn-group-toggle" ngbRadioGroup name="subcategory-required"
                            [(ngModel)]="isRequiredSubcategory" (change)="checkRequiredSubcategory()">
                            <label ngbButtonLabel class="btn-primary">
                                <input ngbButton type="radio" [value]="false"> Opcional
                                <i class="fa fa-check" *ngIf="!isRequiredSubcategory"></i>
                            </label>
                            <label ngbButtonLabel class="btn-primary">
                                <input ngbButton type="radio" [value]="true"> Obrigatório
                                <i class="fa fa-check" *ngIf="isRequiredSubcategory"></i>
                            </label>
                        </div>
                    </div>
                    <div class="col">
                        <div>
                            Opcional - O cliente pode ou não selecionar os itens.
                        </div>
                        <div>
                            Obrigatório - O usuário deverá selecionar um ou mais itens para adicionar o produto ao carrinho.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row add">
                <div class="col-1">
                    <div>
                        <label>Ativo</label>
                    </div>
                    <div>
                        <p-checkbox [pTooltip]="subcategory.IsActive ? 'Ativo':'Inativo'" tooltipPosition="top"
                            binary="true" [(ngModel)]="subcategory.IsActive" name="IsActive"
                            #let active="subcategory.IsActive ? 'Ativo':'Inativo'"></p-checkbox>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Mín.
                            <i class="fa fa-info-circle" tooltipPosition="top" [pTooltip]="infoMin"></i>
                        </label>
                        <input type="number" class="form-control" placeholder="0" maxlength="2" pInputText
                            [(ngModel)]="subcategory.Min" name="min" (change)="isMinMaxValid(subcategory)"
                            [readonly]="!isRequiredSubcategory" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Máx.
                            <i class="fa fa-info-circle" tooltipPosition="top"
                                [pTooltip]="infoMax"></i>
                        </label>
                        <input
                            class="form-control"
                            type="number"
                            placeholder="Max."
                            min="0"
                            maxlength="2"
                            name="Max"
                            pInputText
                            [(ngModel)]="subcategory.Max" />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="form-group">
                        <label>
                            Nome da categoria
                            <i class="fa fa-info-circle" tooltipPosition="top" [pTooltip]="infoCategoryName"></i>
                        </label>
                        <input type="text" class="form-control" placeholder="Categoria do Complemento*" maxlength="30" pInputText
                            [ngClass]="{ 'cpl-success': subcategoryForm.controls.Name?.valid, 'cpl-danger': !subcategoryForm.controls.Name?.valid && subcategoryForm.controls.Name?.touched }"
                            [(ngModel)]="subcategory.Name" name="Name" ngModel required />
                        <div [hidden]="subcategoryForm.controls.Name?.valid || !subcategoryForm.controls.Name?.touched" class="form-control-feedback"
                            [ngClass]="{ 'text-success': subcategoryForm.controls.Name?.valid, 'text-danger': !subcategoryForm.controls.Name?.valid }">
                            Por favor, digite o nome do complemento.
                        </div>
                    </div>
                </div>
                <div class="col text-right circle-button-fit">
                    <label>Ações</label>
                    <div class="form-group">
                        <button class="btn btn-link btn-sm addIcon" pTooltip="Confirmar" tooltipPosition="left" type="submit"
                                [disabled]="!subcategoryForm.valid" (click)="changeSubcategory()">
                            <i class="fa fa-check-circle-o fa-lg"></i>
                        </button>
                        <button class="btn btn-link btn-sm cancelIcon"(click)="cancelChange()">
                            <i class="fa fa-times-circle-o fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Panel -->
    <div
        *ngIf="showPanel"
        class="row">
        <div class="col">
            <p-accordion
                [multiple]="true"
                expandIcon="fa fa-caret-right"
                collapseIcon="fa fa-caret-down"
                (onOpen)="onTabToggle($event)"
                (onClose)="onTabToggle($event)">
                <p-accordionTab
                    *ngFor="let subcategory of product.Subcategories; let cIndex = index"
                    [selected]="!subcategory.Collapse">
                    <p-header>
                        <div class="row" >
                            <div class="col-lg-7 according-icon" pTooltip="Clique para expandir" tooltipPosition="top">
                                <i class="fa fa-circle text-success" *ngIf="subcategory.IsActive" pTooltip="Ativo" tooltipPosition="top"></i>
                                <i class="fa fa-circle text-danger" *ngIf="!subcategory.IsActive" pTooltip="Inativo" tooltipPosition="top"></i>
                                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px; padding-left: 10px">
                                    {{ subcategory.Name }}
                                </span>
                            </div>
                            <div class="col">
                                <span>Min: {{ subcategory.Min }}</span>
                            </div>
                            <div class="col">
                                <span>Máx: {{ subcategory.Max }}</span>
                            </div>
                            <div class="col">
                                <span
                                    class="badge badge-pill badge-top"
                                    [class.badge-danger]="hasRequiredSubcategory(subcategory)"
                                    [class.badge-primary]="!hasRequiredSubcategory(subcategory)">
                                    {{ hasRequiredSubcategory(subcategory) ? 'Obrigatório' : 'Opcional'}}
                                </span>
                            </div>
                            <div class="col pull-right">
                                <div class="pull-right">
                                    <p-splitButton
                                        #btn
                                        icon="fa fa-pencil"
                                        [model]="dropdownOptions"
                                        [menuStyleClass]="'align-split-button-menu'"
                                        (onClick)="getSubcategory('UPD', cIndex, subcategory)"
                                        (onDropdownClick)="handleDropdown(subcategory, btn)">
                                    </p-splitButton>
                                </div>
                            </div>
                        </div>
                    </p-header>

                    <div>
                        <table class="table table-sm table-hover">
                            <thead>
                                <th class="th-icon"></th>
                                <th class="th-status">Status</th>
                                <th>Nome</th>
                                <th>Descrição</th>
                                <th class="th-price">Preço</th>
                                <th class="th-action">Ação</th>
                            </thead>
                            <tbody dnd-sortable-container [sortableData]="subcategory.Items">
                                <tr *ngIf="!hasSubcategoryItems(subcategory)">
                                    <td colspan="5">Crie novos items ou arraste e solte items existentes aqui.</td>
                                </tr>
                                <tr
                                    *ngFor="let item of subcategory.Items; let iIndex = index"
                                    dnd-sortable
                                    draggable="true"
                                    [sortableIndex]="iIndex"
                                    [dragData]="item"
                                    dnd-droppable
                                    [dropZones]="['items']">
                                    <td class="text-center">
                                        <i
                                            class="fa fa-arrows"
                                            pTooltip="Clique e arraste para ordenar."
                                            tooltipPosition="top">
                                        </i>
                                    </td>
                                    <td>
                                        <p-inputSwitch [(ngModel)]="item.IsActive"></p-inputSwitch>
                                    </td>
                                    <td>{{ item.Name }}</td>
                                    <td>{{ item.Description }}</td>
                                    <td>{{ item.Price | currency: 'BRL' }}</td>
                                    <td class="text-right">
                                        <button
                                            class="btn btn-primary btn-sm"
                                            (click)="displayAddItem(cIndex, iIndex)">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                            class="btn btn-danger btn-sm"
                                            (click)="confirmDelete(item, 'item', cIndex)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="6">
                                        <div>
                                            <div class="col ml-auto button-add-item">
                                                <button
                                                    class="btn btn-primary btn-sm"
                                                    [class.pulse]="!hasSubcategoryItems(subcategory)"
                                                    (click)="displayAddItem(cIndex)">
                                                    <i class="fa fa-plus"></i> Adicionar Item
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

    <!-- Category Orders -->
    <div *ngIf="showSubcategoryOrder">
        <div class="text-center">
            <h5>Ordenar subcategorias</h5>
            <span>Clique e arraste para ordenar.</span><br />
            <span>No aplicativo as subcategorias aparecerão conforme estão aqui.</span>
        </div>
        <div class="col" dnd-sortable-container [sortableData]="product.Subcategories" [dropZones]="['container-dropZone']">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let subcategory of product.Subcategories; let i = index"
                dnd-sortable [sortableIndex]="i"
                pTooltip="Clique e arraste para organizar." tooltipPosition="top">
                <div class="row">
                    <div class="col-10">
                        <span class="ui-panel-title">{{i + 1}} - {{ subcategory.Name }}</span>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <i aria-hidden="true" class="fa fa-arrows"></i>
                    </div>
                </div>
            </li>
        </ul>
        </div>
    </div>

    <!-- New Item -->
    <div *ngIf="showAddItem">
        <form
            #itemForm="ngForm"
            (ngSubmit)="addOrUpdateItem()">
            <div class="row add">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            Nome do item/complemento <i class="required">*</i>
                        </label>
                        <input
                            class="form-control"
                            id="itemName"
                            type="text"
                            placeholder="Item do complemento*"
                            maxlength="20"
                            pInputText
                            name="Name"
                            [ngClass]="{
                                'cpl-success': itemForm.controls.Name?.valid,
                                'cpl-danger': !itemForm.controls.Name?.valid && itemForm.controls.Name?.touched
                            }"
                            [(ngModel)]="item.Name"
                            ngModel
                            required />
                        <div
                            class="form-control-feedback"
                            [hidden]="itemForm.controls.Name?.valid || !itemForm.controls.Name?.touched"
                            [ngClass]="{
                                'text-success': itemForm.controls.Name?.valid,
                                'text-danger': !itemForm.controls.Name?.valid
                            }">
                            Por favor, digite o nome do item.
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label>Descrição</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Descrição"
                        maxlength="70"
                        pInputText
                        name="Description"
                        [(ngModel)]="item.Description" />
                </div>
                <div class="col-md-2">
                    <label>Valor</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Valor"
                        maxlength="10"
                        pInputText
                        name="Price"
                        [(ngModel)]="item.Price"
                        currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                </div>
                <div class="col-md-1 text-right circle-button-fit">
                    <label>Ações</label>
                    <div class="form-group">
                        <button
                            class="btn btn-link btn-sm addIcon"
                            pTooltip="Confirmar"
                            tooltipPosition="left"
                            type="submit"
                            [disabled]="!itemForm.valid"
                            (click)="addOrUpdateItem()">
                            <i class="fa fa-check-circle-o fa-lg"></i>
                        </button>
                        <button
                            class="btn btn-link btn-sm cancelIcon"
                            pTooltip="Cancelar"
                            tooltipPosition="right">
                            <i
                                class="fa fa-times-circle-o fa-lg"
                                (click)="cancelAddItem()">
                            </i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Footer buttons -->
    <div
        *ngIf="showFooter"
        class="row">
        <div class="col-md-12 pt-2 text-right">
            <button
                *ngIf="showSubcategoryOrder"
                class="btn btn-warning btn-sm"
                (click)="toggleSubcategorySort()">
                <i class="fa fa-arrow-circle-left"></i>
                Retornar
            </button>

            <button
                *ngIf="showHeader"
                class="btn btn-primary btn-sm"
                type="button"
                [hidden]="showSubcategoryOrder"
                (click)="complete()">
                <i class="fa fa-floppy-o"></i>
                Salvar
            </button>
        </div>
    </div>
</div>