import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { StorageKey } from 'src/core/shared/enums/storage-key.enum';
import { IStorageService } from './storage.service.interface';

@Injectable({
    providedIn: 'root'
})
export class StorageService implements IStorageService {

    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
        this.init();
    }

    /**
     * @inheritdoc
     */
    async init() {
        if (this._storage != null) {
            return;
        }

        const storage = await this.storage.create();
        this._storage = storage;
    }

    /**
     * @inheritdoc
     */
    public async get(key: StorageKey): Promise<any> {
        await this.init();
        return await this._storage.get(key);
    }

    /**
     * @inheritdoc
     */
    public async set(key: StorageKey, value: any): Promise<any> {
        await this.init();
        return await this._storage.set(key, value);
    }

    /**
     * @inheritdoc
     */
    public async clear(): Promise<any> {
        return await this._storage.clear();
    }
}