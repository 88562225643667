export class Address {
    /// <summary>
    /// Get or set Postal Code. [CEP]
    /// </summary>
    public ZipCode: number;

    /// <summary>
    /// Get or set Address Name.
    /// </summary>
    public Name: string;

    /// <summary>
    /// Get or set Number.
    /// </summary>
    public Number: number;

    /// <summary>
    /// Get or set district. [Bairro]
    /// </summary>
    public District: string;

    /// <summary>
    /// Get or set Complement.
    /// </summary>
    public Complement: string;
}