import {map} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MenuService } from './menu.service';
import { Menu } from './shared/menu.model';
import { Product } from './shared/product.model';
import { Category } from './shared/category.model';
import { Image } from './shared/image.model';
import { Guid } from '../utils/guid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MainProductComponent } from './main-product/main-product.component';
import { StoreService } from 'src/providers/store.service';
import { ServiceConfig } from 'src/_config/services.config';

@Component({
    templateUrl: './menu.component.html',
    styles: [`p-header, .imgCar, .btn { cursor: pointer; }
            body .ui-panel .ui-panel-titlebar { padding: .3em .75em }
            .list-group-item { padding: 0.50rem 0.80rem; }
            .table td, .table th { padding: .2rem }
            .success { color: green; }
            .danger { color: red; }
            .btnAddCategoria { padding: 5px 23px; }
            .ui-dialog.ui-widget .ui-dialog-titlebar { padding: 0.5rem 1rem; }
            .form-control-feedback { font-size: 14px; }
            .ui-sidebar { margin-top: 56px; }
            .align-split-button-menu { right: 0; left: auto !important; }
            .align-split-buttons { width: 61px; }
            .order-panel { overflow: hidden; white-space: nowrap; }
            `],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

    private company;
    public menu: Menu = new Menu();

    /**
     * SplitButton variables.
     */
    cOptions: any;
    pOptions: any;

    /**
     * Panel
     */
    private isToggle = true;

    /**
     * Modal
     */
    public mode: string;

    showLoader: boolean = true;

    get hasCategories(): boolean {
        return this.menu.Categories && this.menu.Categories.length > 0;
    }

    @ViewChild('productModal') mainProductComponent: MainProductComponent;

    constructor(private menuService: MenuService, private storeService: StoreService,
        private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        let company = localStorage.getItem('company');
        this.company = JSON.parse(company);

        this.menuService.getMenu()
            .subscribe(
                data => {
                    if (typeof(data) != 'undefined' && data != null) {
                        this.menu = data;
                    }
                    this.showLoader = false;
                },
                err => {
                    console.log(err);
                    this.showLoader = false;
                }
            );

        // Split-button options.
        this.cOptions = [
            { label: 'Desativar', icon: 'fa-toggle-on', command: () => { this.confirmStatus('category'); } },
            { label: 'Duplicar', icon: 'fa fa-clone', command: () => { this.cloneCategory() } },
            { label: 'Excluir', icon: 'fa fa-trash', command: () => { this.confirmOnDelete('category'); } },
        ];

        this.pOptions = [
            { label: 'Desativar', icon: 'fa-toggle-on', command: () => { this.confirmStatus('product'); } },
            { label: 'Duplicar', icon: 'fa fa-clone', command: () => { this.cloneProduct() } },
            { label: 'Excluir', icon: 'fa fa-trash', command: () => { this.confirmOnDelete('product'); } },
        ];
    }

    /**
     * Toggle panel component.
     * @param e
     * @param company
     * @param i
     */
    toggle(e, category, i) {
        this.isToggle = !this.isToggle;
        category.Collapse = !category.Collapse;

        this.menuService.updateMenu(this.menu).subscribe();
    }

    /**
     * Evento onDragSuccess.
     */
    onProductDragSuccess(cIndex) {
        console.log('dragged!');

        let i = 0;
        this.menu.Categories[cIndex].Products.forEach(x => {
            x.Position = i;
            i++;
        });
        this.updateMenu();
    }

    /**
     * Evento onDragSuccess.
     */
    onCategoryDragSuccess() {
        let i = 0;
        this.menu.Categories.forEach(x => {
            x.Position = i;
            i++;
        });

        this.updateMenu();
    }

    /**
     * Dialog Modal.
     */
    private categoriaIndex: number = 0;
    private productIndex: number = 0;
    public product: Product = new Product();
    public displayProduto: boolean = false;

    /**
     * Product dialog.
     * @param product Product
     * @param pIndex  Product Index
     * @param cIndex  Category Index
     * @param mode    Mode
     */
    showDialogProduto(product, pIndex, cIndex, mode) {
        this.displayProduto = true;
        this.categoriaIndex = cIndex;
        this.productIndex = pIndex;
        this.mode = mode;
        this.product = new Product();
        this.product.IsActive = true;
        // this.product.Image = new Image();
        // this.product.Image.ImageOriginal = this.imgThumbUrl;

        if (this.mode == 'UPD') {
            // Create a new instance of object.
            this.product = JSON.parse(JSON.stringify(product));

            if (!this.product.ImageId) {
                // this.product.Image = new Image();
                // this.product.Image.ImageOriginal = this.imgThumbUrl;
            } else
                // this.storeService.getImage(this.product.ImageId)
                //     .subscribe(_ => this.product.Image = _);
                this.product.Image = new Image();
                this.product.Image.Url = ServiceConfig.PRODUCT_IMAGE_ENDPOINT
                    .replace('{company}',this.company.CompanyId)
                    .replace('{image}',this.product.ImageId);
        }
    }

    private category: Category;
    public categoria: string;
    public displayCategoria: boolean = false;

    showDialogCategoria(categoria, index) {
        this.categoria = '';
        this.displayCategoria = true;
        this.categoriaIndex = index;
        if (index != null)
            this.categoria = categoria.Name;
    }

    /**
     *  Modal Dialog Events.
     */
    addCategory() {
        this.displayCategoria = false;
        let category = { Name: this.categoria, CategoryId: Guid.newGuid(), Collapse: false, IsActive: true, Position: 0, Products: [] } as Category;

        // Update existing Category.
        if (this.categoriaIndex != null) {
            this.menu.Categories[this.categoriaIndex].Name = this.categoria;
            this.updateMenu();

            this.categoria = '';
            return;
        }

        // New Category.
        if (this.menu.Categories == null)
            this.menu.Categories = new Array<Category>()

        this.menu.Categories.push(category);
 
        if (this.menu.MenuId) {
            // Update existing ID.
            this.updateMenu();
        }
        else {
            // Insert new Menu.
            this.menuService.addMenu(this.menu).pipe(
                map((res: any) => {this.menu = res.Menu}))
                .subscribe();
        }

        this.categoria = '';
        this.displayCategoria = false;
    }

    saveProduct(product) {
        if (this.mode == 'UPD')
            this.menu.Categories[this.categoriaIndex].Products[this.productIndex] = product;
        else {
            // Create a new instance of object.
            let newProduct = JSON.parse(JSON.stringify(product));
            newProduct.ProductId = Guid.newGuid();

            this.menu.Categories[this.categoriaIndex].Products.push(newProduct);
        }

        this.updateMenu()
    }

    closeProductModal(isOpen) {
        this.displayProduto = isOpen;
    }

    /**
     * Get index values from p-split button.
     * @param cIndex Category Index.
     * @param pIndex Product Index.
     */
    getItem(cIndex, pIndex, btn) {
        event.stopPropagation();

        this.categoriaIndex = cIndex;
        this.productIndex = pIndex;

        // Change icon and label.
        let item;
        if (pIndex == null)
            item = this.menu.Categories[this.categoriaIndex];
        else
            item = this.menu.Categories[this.categoriaIndex].Products[this.productIndex];

        btn.model[0].label = item.IsActive ? 'Desativar' : 'Ativar';
        btn.model[0].icon = item.IsActive ? 'fa fa-toggle-off' : 'fa fa-toggle-on';
    }

    /**
     * Update Category Status.
     */
    updateCategoryStatus() {
        let category = this.menu.Categories[this.categoriaIndex];
        category.IsActive = !category.IsActive;
        this.updateMenu();
    }

    /**
     * Delete Category.
     */
    deleteCategory() {
        const category = this.menu.Categories[this.categoriaIndex];
        var index = this.menu.Categories.indexOf(category, 0);

        // Remove category.
        if (index > -1) 
            this.menu.Categories.splice(index, 1);

        this.updateMenu();
    }

    /**
     * Update Product Status.
     */
    updateProductStatus() {
        const product = this.menu.Categories[this.categoriaIndex].Products[this.productIndex];
        product.IsActive = !product.IsActive;

        this.updateMenu();
    }

    /**
     * Delete Product.
     */
    deleteProduct() {
        const product = this.menu.Categories[this.categoriaIndex].Products[this.productIndex];
        var index = this.menu.Categories[this.categoriaIndex].Products.indexOf(product, 0);

        // Remove category.
        if (index > -1) {
            this.menu.Categories[this.categoriaIndex].Products.splice(index, 1);
        }

        this.updateMenu();
    }

    confirmOnDelete(tipo) {
        event.stopPropagation();

        const msg = tipo === 'category' ? 'essa categoria?' : 'esse produto?';

        this.confirmationService.confirm({
            message: 'Você tem certeza que deseja excluir ' + msg,
            header: 'Confirmação',
            icon: 'fa fa-question-circle',
            accept: () => {
                if (tipo == 'category')
                    this.deleteCategory();

                if (tipo == 'product')
                    this.deleteProduct();
            },
            reject: () => { }
        });
    }

    confirmStatus(tipo) {
        event.stopPropagation();

        let obj;

        if (tipo == 'category')
            obj = this.menu.Categories[this.categoriaIndex];
        else
            obj = this.menu.Categories[this.categoriaIndex].Products[this.productIndex];


        const status = obj.IsActive ? 'desativar' : 'ativar';

        const msg = tipo === 'category' ? status + ' essa categoria?' : status + ' esse produto?'

        this.confirmationService.confirm({
            message: 'Você tem certeza que deseja ' + msg,
            header: 'Confirmação',
            icon: 'fa fa-question-circle',
            accept: () => {
                if (tipo == 'category')
                    this.updateCategoryStatus();

                if (tipo == 'product')
                    this.updateProductStatus();
            },
            reject: () => { }
        });
    }

    private updateMenu() {
        this.menuService.updateMenu(this.menu)
            .subscribe(_ => {
                if (_) {
                    if (_.completed) {
                        this.menu = <Menu> _.body;
                        this.showMessage('Atualizado!');
                    } else {
                        this.showMessage(`Atualizando (${_.progress}%)...`, 'warn');
                    }
                }
            },
            (error: any) => {
                this.showMessage('Não foi possível atualizar. Tente novamente!', 'error');
            });
    }

    public onShowProduct() {
        this.mainProductComponent.ngOnInit();
    }

    cloneCategory(): void {
        const categoryToClone = this.menu.Categories[this.categoriaIndex];
        const category = <Category> JSON.parse(JSON.stringify(categoryToClone));

        category.CategoryId = Guid.newGuid();
        category.IsActive = false;

        if (category.Products) {
            category.Products.map(_ => {
                _.ProductId = Guid.newGuid();
                _.ImageId = '';

                if (_.Subcategories) {
                    _.Subcategories.map(subcategory => {
                        subcategory.SubcategoryId = Guid.newGuid();
                        subcategory.Items.map(item => {
                            if (item) {
                                item.ItemId = Guid.newGuid()
                            }
                        });
                    });
                }
            });
        }

        this.menu.Categories.push(category);
        this.updateMenu();
    }

    cloneProduct(): void {
        event.stopPropagation();

        const productToClone = this.menu.Categories[this.categoriaIndex].Products[this.productIndex];
        const product = <Product> JSON.parse(JSON.stringify(productToClone));

        product.ProductId = Guid.newGuid();
        product.IsActive = false;
        product.ImageId = '';

        if (product.Subcategories) {
            product.Subcategories.map(_ => {
                _.SubcategoryId = Guid.newGuid();
                _.Items.map(item => {
                    if (item) {
                        item.ItemId = Guid.newGuid()
                    }
                });
            });
        }

        this.menu.Categories[this.categoriaIndex].Products.push(product);
        this.updateMenu();
    }

    hasCategoryProducts(category: Category): boolean {
        return category.Products && category.Products.length > 0;
    }

    hasSubcategories(product: Product): boolean {
        return product.Subcategories && product.Subcategories.length > 0;
    }

    hasRequiredSubcategories(product: Product): boolean {
        if (!product.Subcategories && product.Subcategories.length == 0) {
            return false;
        }

        return product.Subcategories.filter(_ => _.Min > 0).length > 0;
    }

    private showMessage(description: string, type: string = 'success', title?: string) {
        this.messageService.clear();
        this.messageService.add({
            key: 'bottom-center',
            severity: type,
            summary: title,
            detail: description
        });
    }
}
