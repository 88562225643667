<div class="dynamic-form p-1">
    <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
        <div *ngIf="fieldsConfig">
            <formly-form [form]="form" [fields]="fieldsConfig" [model]="model"></formly-form>
        </div>

        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
                <li [ngbNavItem]="index" *ngFor="let tab of tabsConfig; let index = index">
                    <a ngbNavLink>{{ tab.label }}</a>
                    <ng-template ngbNavContent>
                        <formly-form [form]="form" [fields]="tab.fields" [model]="model"></formly-form>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>

        <div class="text-center text-danger"
            *ngIf="errorMessage">
            {{ errorMessage }}
        </div>

        <div class="row">
            <div class="col text-right">
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Salvar</button>
                <button type="button" class="btn btn-default" (click)="close()">Cancelar</button>
            </div>
        </div>
    </form>
</div>