import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    OnDestroy,
    Type
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { PopoutData, PopoutModal, POPOUT_MODALS, POPOUT_MODAL_DATA } from '../pop-out/pop-out.tokens';

@Injectable()
export class PopoutService implements OnDestroy {

    private readonly templatePath = 'assets/modal/popout.html';

    constructor(private injector: Injector,
        private comoponentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef) { }

    ngOnDestroy() { }

    open<T>(component: Type<T>, popout: PopoutData, isSame?: (component: any) => boolean, print: boolean = false): void {
        if (!this.isPopoutWindowOpen(popout.id)) {
            this.openPopoutModal(component, popout, print);
            return;
        }

        console.log(`Using existing modal for ${popout.id}`);

        const existingModal = this.getModal(popout.id);
        const shouldReload = isSame ? !isSame(existingModal.componentInstance) : true;

        if (shouldReload) {
            existingModal.outlet.detach();
            const injector = this.createInjector(popout);
            const componentInstance = this.attachContainer(component, existingModal.outlet, injector);
            existingModal.componentInstance = componentInstance;
        }

        this.focusPopoutWindow(popout.id);
    }

    closeAll(): void {
        POPOUT_MODALS.forEach(modal => {
            if (modal.windowInstance) {
                modal.windowInstance.close();
            }
        });
        POPOUT_MODALS.splice(0, POPOUT_MODALS.length);
    }

    close(id: string): void {
        const popout = POPOUT_MODALS.find(_ => _.id == id);

        if (!popout){
            return
        };

        const popoutIndex = POPOUT_MODALS.indexOf(popout[0]);
        POPOUT_MODALS.splice(popoutIndex, 1);
    }

    private openPopoutModal<T>(component: Type<T>, popoutData: PopoutData, print: boolean): void {
        console.log(`Opening new modal for ${popoutData.id}.`);
        const windowInstance = this.openOnce(this.templatePath, popoutData.id);

        // Wait for window instance to be created
        setTimeout(() => {
            this.createCDKPortal(component, popoutData, windowInstance);

            if (print) {
                setTimeout(() => {
                    windowInstance.print();
                }, 2000);
            }
        }, 1000);
    }

    private getModal(id: string): PopoutModal {
        return POPOUT_MODALS.find(modal => modal.id === id);
    }

    private isModalCreated(id: string): boolean {
        return POPOUT_MODALS.some(modal => modal.id === id);
    }

    private createInjector(data: PopoutData): PortalInjector {
        const injectionTokens = new WeakMap();
        injectionTokens.set(POPOUT_MODAL_DATA, data);
        return new PortalInjector(this.injector, injectionTokens);
    }

    private attachContainer<T>(component: Type<T>, outlet: DomPortalOutlet, injector: PortalInjector): T {
        const containerPortal = new ComponentPortal(component, null, injector);
        const containerRef: ComponentRef<T> = outlet.attach(containerPortal);
        return containerRef.instance;
    }

    private isPopoutWindowOpen(id: string): boolean {
        const modal = this.getModal(id);
        return modal && modal.windowInstance && !modal.windowInstance.closed;
    }

    private focusPopoutWindow(id: string): void {
        this.getModal(id).windowInstance.focus();
    }

    private openOnce(url: string, target: string): Window {
        // Open a blank "target" windowInstance
        // or get the reference to the existing "target" window.
        const winRef = window.open('', target, '');

        // if the "target" window was just opened, change its url
        if (winRef.location.href === 'about:blank') {
            winRef.location.href = url;
        }

        return winRef;
    }

    private createCDKPortal<T>(component: Type<T>, data: PopoutData, windowInstance: Window): void {
        console.log(`Creating portal for ${data.id}`);
        if (windowInstance) {
            windowInstance.document.body.innerText = '';
            // Create a portal outlet with the body of the new window document
            const outlet = new DomPortalOutlet(windowInstance.document.body, this.comoponentFactoryResolver, this.applicationRef, this.injector);

            // Copy styles from parent window
            document.querySelectorAll('style').forEach(htmlElement => {
                windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
            });

            // Clear popout modal content
            windowInstance.document.body.innerText = '';

            // Create an injector with modal data
            const injector = this.createInjector(data);

            // Attach the Portal
            windowInstance.document.title = data.title;
            const componentInstance = this.attachContainer(component, outlet, injector);

            if (this.isModalCreated(data.id)) {
                const modal = this.getModal(data.id);
                modal.windowInstance = windowInstance;
                modal.componentInstance = componentInstance;
                modal.outlet = outlet;
            } else {
                POPOUT_MODALS.push({
                    id: data.id,
                    windowInstance: windowInstance,
                    componentInstance: componentInstance,
                    outlet: outlet
                });
            }
        }
    }
}