
    <div class="mb-3">
        <legend *ngIf="to.label">{{ to.label }}</legend>
        <p *ngIf="to.description">{{ to.description }}</p>

        <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
            <formly-validation-message [field]="field"></formly-validation-message>
        </div>

        <div *ngFor="let field of field.fieldGroup;let i = index;" class="row">
            <formly-field class="col-md-11" [field]="field"></formly-field>
            <div class="col-md-1 text-right">
                <button class="btn btn-sm btn-danger" type="button" (click)="remove(i)">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <div class="d-flex flex-row-reverse">
            <button class="btn btn-sm btn-primary" type="button" (click)="add()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
    </div>
  