import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterOrderStatusPipe } from 'src/app/order/board/filter-order-status.pipe';
import { SharedModule, SplitButtonModule, TooltipModule } from 'primeng/primeng';
import { OrderCardComponent } from './order-card.component';
import { TimeagoModule, TimeagoFormatter, TimeagoCustomFormatter, TimeagoIntl } from 'ngx-timeago';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TooltipModule,
        NgbDropdownModule,
        TimeagoModule.forRoot({
            formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter }
        }),
        SplitButtonModule
    ],
    exports: [OrderCardComponent],
    declarations: [OrderCardComponent, FilterOrderStatusPipe],
    providers: [TimeagoIntl],
})
export class OrderCardModule { }
