import { ISocketIOService } from './socket-io.service.interface';
import { SocketIOChannel } from '@foodra/core';
import {
    Observable,
    Subject,
    Subscriber
} from 'rxjs';

import * as io from 'socket.io-client';
import { ServiceConfig } from 'src/_config/services.config';

/**
 * @inheritdoc
 */
export class SocketIOService extends ISocketIOService {
    private url: string = ServiceConfig.SOCKET_IO_ENDPOINT;
    private socket: any;
    private onConnectedSubject = new Subject<boolean>();
    private reconnectionAttemptReachSubject = new Subject<boolean>();
    private reconnectionAttempt: number = 0;
    private maxReconnectionAttempt: number = 10;

    constructor() {
        super();
    }

    /**
     * Gets whether the socket is connected or not.
     */
    private get isSocketConnected(): boolean {
        return this.socket && this.socket.connected;
    };

    /**
     * @inheritdoc
     */
    get isConnected(): Observable<boolean> {
        return this.onConnectedSubject;
    }

    /**
     * @inheritdoc
     */
    get reconnectionAttemptReach(): Observable<boolean> {
        return this.reconnectionAttemptReachSubject;
    }

    /**
     * @inheritdoc
     */
    get totalConnections(): Observable<number> {
        const observable: Observable<number> = new Observable<number>((observer: Subscriber<number>) => {
            this.onConnectedSubject.subscribe((isConnected: boolean) => {
                if (isConnected) {
                    this.socket.on('total-connections', (totalConnections: number) => {
                        observer.next(totalConnections);
                    });
                }
            });
        });

        return observable;
    }

    /**
     * @inheritdoc
     */
    connect(socketChannel?: SocketIOChannel): void {
        if (this.socket && this.socket.connected) {
            return;
        }
        console.log(this.reconnectionAttempt);

        this.socket = io(this.url, { forceNew: false, reconnectionAttempts: this.maxReconnectionAttempt }).connect();

        this.socket.on('connect', () => {
            this.authenticate();
            console.log('Connected!');
        });

        this.socket.on('disconnect', () => {
            this.onConnectedSubject.next(this.isSocketConnected);
            console.log('Disconnected!');
        });

        this.socket.on('reconnect_attempt', (currentReconnectionAttempt) => {
            console.log('reconnect_attempt', currentReconnectionAttempt);
            this.reconnectionAttempt = currentReconnectionAttempt;
        });

        this.socket.on('reconnect_failed', () => {
            console.log('reconnect_attempt failed');
            if (this.reconnectionAttempt === this.maxReconnectionAttempt) {
                this.reconnectionAttempt = 0;
                this.reconnectionAttemptReachSubject.next(true);
            }
        });

        this.socket.on('reconnect', () => {
            console.log('Reconnected!');
        });

        this.socket.on('authenticated', () => {
            if (socketChannel) {
                this.changeChannel(socketChannel);
            }
            this.reconnectionAttempt = 0;
            this.reconnectionAttemptReachSubject.next(false);
            this.onConnectedSubject.next(this.isSocketConnected);

            console.log("Authenticated!");
        });

        this.socket.on('unauthorized', (err: any) => {
            console.log("There was an error with the authentication:", err.message);
        });

        this.socket.on('connect_error', () => {
            console.log('connect_error!');
            this.checkReconnectionAttempt();
        });

        this.socket.on('error', (err: any) => {
            console.log(`Connection error:`, err.message);
        });

        this.checkReconnectionAttempt();
    }

    /**
     * @inheritdoc
     */
    reconnect(): void {
        this.reconnectionAttempt = 0;
        this.socket.open();
    }

    /**
     * @inheritdoc
     */
    disconnect(): void {
        if (!this.isSocketConnected) {
            return;
        }

        this.socket.disconnect();
    }

    /**
     * @inheritdoc
     */
    on<T>(event: string): Observable<T> {
        const observable: Observable<T> = new Observable<T>((observer: Subscriber<T>) => {
            this.onConnectedSubject.subscribe((isConnected: boolean) => {
                if (isConnected) {
                    this.socket.on(event, (data: T) => {
                        observer.next(data);
                    });
                }
            });
        });

        return observable;
    }

    /**
     * @inheritdoc
     */
    changeChannel(socketChannel: SocketIOChannel): void {
        this.socket.emit('company-connect', JSON.stringify(socketChannel));
    }

    /**
     * @inheritdoc
     */
    leave(socketRoom: string): void {
        this.socket.emit('leave-room', socketRoom);
    }

    /**
     * @inheritdoc
     */
    emit(eventName: string, socketRoom: string): void {
        this.socket.emit(eventName, socketRoom);
    }

    /**
     * TODO: Refactor this method.
     */
    private authenticate(): void {
        const authToken = localStorage.getItem('access_token');
        this.socket.emit('authentication', { token: authToken });
    }

    /**
     * Checks whether reconnection has reached max number of tries.
     */
    private checkReconnectionAttempt(): void {
        this.reconnectionAttempt++;
        if (this.reconnectionAttempt > this.maxReconnectionAttempt) {
            this.reconnectionAttemptReachSubject.next(true);
        }
    }
}
