import { SocketIOChannel } from '@foodra/core';
import { Observable } from 'rxjs';

/**
 * This service is responsible for the real time communication.
 */
export abstract class ISocketIOService {

    /**
     * Checks whether the socket is connected.
     *
     * @returns An observable with true if connected, otherwise false.
     */
    abstract get isConnected(): Observable<boolean>;

    /**
     * Checks whether the maximum reconnection attempt has been reach.
     *
     * @returns An observable with true if max reconnection attempt has reached, otherwise false.
     */
    abstract get reconnectionAttemptReach(): Observable<boolean>;

    /**
     * Counts the total of connections on the socket-io server.
     */
    abstract get totalConnections(): Observable<number>;

    /**
     * Connects to the socket.io server.
     */
    abstract connect(socketChannel?: SocketIOChannel): void;

    /**
     * Reconnects the socket.io connection when it was already been opened.
     */
    abstract reconnect(): void;

    /**
     * Disconnects socket from the server.
     */
    abstract disconnect(): void;

    /**
     * Listens to an specific event name.
     *
     * @param eventName The event name to listen to.
     *
     * @returns An observable of the desired type.
     */
    abstract on<T>(eventName: string): Observable<T>;

    /**
     * Changes the socket-io channel.
     *
     * @param socketChannel Socket IO Channel.
     */
    abstract changeChannel(socketChannel: SocketIOChannel): void;

    /**
     * Leaves a socket-io room.
     *
     * @param socketRoom Socket IO Room.
     */
    abstract leave(socketRoom: string): void;

    /**
     * Emits an event to a romm on the server.
     *
     * @param eventName The event name.
     * @param socketRoom The socket room.
     */
    abstract emit(eventName: string, socketRoom?: string): void;
}