import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DropdownModule, DynamicDialogModule, PanelModule, TooltipModule } from 'primeng';
import { TableModule } from 'primeng/table';
import { DatePickerComponent } from './components/date/date-picker/date-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PayableComponent } from './components/financial/payable/payable.component';
import { OrderDetailComponent } from './components/order/order-detail/order-detail.component';
import { Ng2BRPipesModule } from 'ng2-brpipes';

const primeNgModules = [
    CalendarModule,
    DropdownModule,
    PanelModule,
    TableModule,
    TooltipModule,
    DynamicDialogModule
];

const ngbModules = [
    NgbModule
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        IonicStorageModule.forRoot(),
        Ng2BRPipesModule,
        primeNgModules,
        ngbModules
    ],
    exports: [
        DatePickerComponent,
        DropdownComponent,
        PayableComponent,
        OrderDetailComponent
        // MyDirective
        // TranslatePipe
    ],
    declarations: [
        DatePickerComponent,
        DropdownComponent,
        PayableComponent,
        OrderDetailComponent
        // MyDirective
        // TranslatePipe
    ],
    entryComponents: [
        OrderDetailComponent
    ]
})
export class CoreModule { }