import { HttpHeaders } from '@angular/common/http';

export abstract class IApiService {

    /**
     * Requests a GET verb from the server.
     *
     * @param endpoint The server endpoint.
     * @param reportProgress True if requires to report progress, otherwise false.
     * @param headers The headers to be sent to the server.
     */
    abstract get<T>(endpoint: string, reportProgress?: boolean, headers?: HttpHeaders): Promise<T>;

    /**
     * Sends a POST request to the server.
     *
     * @param endpoint The server endpoint.
     * @param body The body message with parameters.
     * @param reportProgress True if requires to report progress, otherwise false.
     * @param headers The headers to be sent to the server.
     */
    abstract post<T>(endpoint: string, body: any, reportProgress?: boolean, headers?: HttpHeaders): Promise<T>;

    /**
     * Sends a PUT request to the server.
     *
     * @param endpoint The server endpoint.
     * @param body The body message with parameters.
     * @param reportProgress True if requires to report progress, otherwise false.
     * @param headers The headers to be sent to the server.
     */
    abstract put<T>(endpoint: string, body: any, reportProgress?: boolean, headers?: HttpHeaders): Promise<T>;

    /**
     * Sends a DELETE to the server.
     *
     * @param endpoint The server endpoint.
     * @param reportProgress True if requires to report progress, otherwise false.
     * @param headers The headers to be sent to the server.
     */
    abstract delete<T>(endpoint: string, reportProgress?: boolean, headers?: HttpHeaders): Promise<T>;
}