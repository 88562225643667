<div class="login-content text-light">
    <div></div>
    <div class="col text-center container-center">
        <img src="../../assets/images/logo/white-slogan.png" alt="Foodra" class="company-logo">
    </div>
    <div class="col-md-4 col-sm-5 col-md-offset-3 container-center container-bg mt-3">
        <h2>Login</h2>
        <form [formGroup]="form" novalidate class="p-2">
            <div class="form-group">
                <label for="username">E-mail</label>
                <input type="email" class="form-control" name="username" formControlName="username" required placeholder="Seu e-mail" />
                <div *ngIf="form.controls.username.dirty && !form.controls.username.valid" class="alert alert-danger" role="alert">Insira um e-mail.</div>
            </div>
            <div class="auth" *ngIf="isAuth">
                <div class="form-group">
                    <label for="password">Senha</label>
                    <input type="password" class="form-control" name="password" formControlName="password" required placeholder="Sua senha" />
                    <div *ngIf="form.controls.password.dirty && !form.controls.password.valid" class="alert alert-danger" role="alert">Insira sua senha.</div>
                    <span class="recaptcha">Esse aplicativo é protegito por reCAPTCHA e as
                        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Políticas de Privacidade</a> e
                        <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Termos de Serviço</a> do Google se aplicam.
                    </span>
                </div>
                <div class="row form-group">
                    <div class="col-6 pr-0">
                        <button class="btn btn-lg btn-primary" type="submit" [disabled]="!form.valid || loading" data-badge="inline"
                            (click)="login()">
                            Login
                            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
                        </button>
                    </div>
                    <div class="col text-right p-0">
                        <span class="recover-password cursor" (click)="toggleAuth()">Esqueceu a senha?</span>
                    </div>
                </div>
            </div>

            <div class="forget-password" *ngIf="!isAuth">
                <div class="row form-group">
                    <div class="col-2 text-center cursor" (click)="toggleAuth()">
                        <i class="fa fa-arrow-left return-icon"></i>
                    </div>
                    <div class="col text-center">
                        <button class="btn btn-lg btn-primary" type="button" *ngIf="!recoverPasswordSucceed"
                            [disabled]="!form.controls.username.valid || loading" data-badge="inline"
                            (click)="recoverPassword()">
                            Recuperar senha
                            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
                        </button>
                        <span *ngIf="recoverPasswordSucceed">Enviamos o procedimento para alteração de senha para seu e-mail.</span>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="error">{{ error }}</div>
        </form>
    </div>
</div>