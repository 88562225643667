export class Image {
    // Get or set Image Id.
    public Id: string;

    // Get or set the reference of the image.
    public ExternalId: string;

    // Get or set the Original Image to display. 
    public ImageOriginal: string;

    public Url: string;
}