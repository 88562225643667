<div id="order-receipt" class="order-receipt">
    <div class="receipt">
        <div class="text-center">
            <img src="../../../assets/images/logo/black-slogan.png">
        </div>
        <div class="text-center m-2">
            <b>#{{ order?.Transaction?.Tid }}</b>
        </div>
        <div class="text-center">
            <div class="row">
                <div class="col-6">
                    <div>Cliente:</div>
                </div>
                <div class="col-6">
                    <div>Recebido em</div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div><b>{{ order?.Customer?.Name }}</b></div>
                </div>
                <div class="col-6">
                    <div>{{ order?.OrderDate | date: 'dd/MM/yy HH:mm' }}</div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <h4 class="m-3">
                {{ order?.DeliveryPlace?.Local | uppercase }} {{ order?.DeliveryPlace?.Locator }}
            </h4>

            <div
                class="divider-item mb-2"
                *ngIf="address">
                <div>{{ address }}</div>
                <div *ngIf="order?.DeliveryPlace?.Location?.Address?.Complement">
                    {{ order?.DeliveryPlace?.Location?.Address?.Complement }}
                </div>
            </div>
        </div>

        <hr
            class="divider-summary"
            *ngIf="!location" />

        <!-- Order -->
        <div>
            <div
                class="row"
                *ngFor="let product of order?.Products; let last = last">
                <div class="col">
                    <div class="row">
                        <div class="col-9">
                            <span class="badge badge-light">{{ product?.Quantity }}x</span> {{ product?.Name }}
                        </div>
                        <div
                            class="col-3 text-right"
                            *ngIf="product.Price > 0">
                            {{ product?.Price | currency:'BRL' }}
                        </div>
                        <div class="col-12">
                            <div class="row" *ngFor="let subcategory of product.Subcategories">
                                <div class="col-12">
                                    <div class="row item-line" *ngFor="let item of subcategory.Items">
                                        <div class="col offset-3">
                                            <small>{{ item.Quantity }}x {{ item.Name }}</small>
                                        </div>
                                        <div class="col-3 text-center" *ngIf="item.Price > 0">
                                            <small class="no-wrap">+{{ item.Price | currency:'BRL' }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Product Note -->
                        <div
                            class="col-12"
                            *ngIf="product.Note">
                            <div class="row">
                                <div class="col-2"><small><b>OBS.: </b></small></div>
                                <div class="col-10">
                                    <small>{{ product.Note | uppercase }}</small>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12"
                            *ngIf="shouldShowProductSubtotal(product)">
                            <div class="row text-right">
                                <div class="col-3 offset-9">
                                    <small><b>{{ product.Subtotal | currency:'BRL' }}</b></small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr
                                class="divider-item"
                                [class.divider-summary]="last" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Total Order -->
        <div>
            <div class="row text-end">
                <div class="col-7"><b>Subtotal:</b></div>
                <div class="col-5">
                    <b>{{ subtotal | currency:'BRL' }}</b>
                </div>
            </div>
            <div
                class="row text-end"
                *ngIf="order?.DeliveryPlace?.Price">
                <div class="col-7">Taxa de entrega:</div>
                <div class="col-5">{{ order?.DeliveryPlace?.Price | currency:'BRL' }}</div>
            </div>
            <div *ngIf="order?.Coupons && order?.Coupons.length > 0">
                <div class="row text-end">
                    <div class="col-8">Promoção:</div>
                    <div class="col-4">
                        <div *ngFor="let coupon of order?.Coupons">
                            <span>-{{ coupon.DiscountAmount | currency:'BRL' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2 text-end">
                <div class="col-7"><b>Total:</b></div>
                <div class="col-5">
                    <div *ngIf="order?.Coupons && order?.Coupons.length > 0">
                        <span class="price-discount">{{ totalWithoutDiscount | currency:'BRL' }}</span>
                    </div>
                    <span><b>{{ order?.TotalPrice | currency:'BRL' }}</b></span>
                </div>
            </div>

            <div class="divider-item">
                <div class="rowmb-2 text-center">
                    <div class="col"><b>Forma de Pagamento:</b></div>
                </div>
                <div
                    *ngIf="order?.Payment?.Online"
                    class="row text-center">
                    <div class="col">
                        <div>
                            <span>Pago online pelo app.</span>
                        </div>
                        <div>
                            <span><b>Não realizar cobrança na entrega.</b></span>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="!order?.Payment?.Online"
                    class="row text-center">
                    <div class="col">
                        <div>
                            <span>
                                <b>{{ order?.Payment?.Name }}</b>
                                <b *ngIf="order?.Payment?.Type == 1"> - (Maquininha)</b>
                            </span>
                        </div>
                        <div>
                            <span>Realizar cobrança na entrega.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="text-center">
            Obrigado por pedir em {{ order?.Company?.Name }}.
        </div>
        <div class="text-center">
            Bom apetite! 😋
        </div>
        <div class="text-center" style="text-decoration: underline">
            https://foodra.app
        </div>
    <div>
</div>