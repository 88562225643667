export class Spot {
    /// <summary>
    /// Get or set Spot Code.
    /// </summary>
    public Code: string;

    /// <summary>
    /// Get or set Spot Name.
    /// </summary>
    public Name: string;
}