export class Geo {
    /// <summary>
    /// Get or set Latitude
    /// </summary>
    public Latitude: number;

    /// <summary>
    /// Get or set Longitude
    /// </summary>
    public Longitude: number;
}